export const data_presupuestos = [
  "CONCEPTO DE PRESUPUESTO: -	Por una parte es un documento jurídico que autoriza y vincula a las Administraciones para la realización de los gastos y los ingresos con los que cumplir con las funciones decididas por la sociedad, y que se pueden concretar en las tradicionalmente atribuidas al sector público (intervención en la vida económica; prestación de bienes públicos; asignación y redistribución de la riqueza). ",
  "CONCEPTO DE PRESUPUESTO: -	Por otra, el presupuesto constituye la técnica de gestión más potente de la que disponen las Administraciones, toda vez que proporciona una información muy valiosa para la mayor eficiencia y eficacia en el cumplimiento de esas funciones. ",
  "CONCEPTO DE PRESUPUESTO: -	Finalmente, constituye una garantía para los administrados, por cuanto la actividad de la Administración se dirige hacia finalidades concretas y conocidas, y está sometida a control. ",
  "CONCEPTO DE PRESUPUESTO: -	Fuentes Quintana: considera al presupuesto como la expresión contable del plan económico de la Hacienda Pública para un período determinado de tiempo. ",
  "CONCEPTO DE PRESUPUESTO: -	Neumark: define al presupuesto como el resumen sistemático, confeccionado en períodos regulares, de las previsiones, de los gastos proyectados y de las estimaciones de los ingresos necesarios para cubrir dichos gastos. De esta definición se extraen las siguientes notas: ",
  "CONCEPTO DE PRESUPUESTO: -	Neumark: define al presupuesto como el resumen sistemático, confeccionado en períodos regulares, de las previsiones, de los gastos proyectados y de las estimaciones de los ingresos necesarios para cubrir dichos gastos. De esta definición se extraen las siguientes notas: a)	El presupuesto es un acto de Previsión en la doble vertiente de los gastos y de los ingresos precisos para financiarlos durante un determinado período temporal ",
  "CONCEPTO DE PRESUPUESTO: -	Neumark: define al presupuesto como el resumen sistemático, confeccionado en períodos regulares, de las previsiones, de los gastos proyectados y de las estimaciones de los ingresos necesarios para cubrir dichos gastos. De esta definición se extraen las siguientes notas: b)	El presupuesto es una previsión normativa, en la medida que obliga al Gobierno y a la Administración a su cumplimiento desde una doble perspectiva: política y jurídica. Sin embargo, es necesario precisar que, desde la perspectiva del gasto, el presupuesto limita la naturaleza, cuantía y ámbito temporal en que el grupo político puede realizar sus gastos, mientras que las estimaciones de los ingresos, para un sistema tributario dado, están condicionadas al desenvolvimiento de las magnitudes económicas del país dado. ",
  "CONCEPTO DE PRESUPUESTO: -	Neumark: define al presupuesto como el resumen sistemático, confeccionado en períodos regulares, de las previsiones, de los gastos proyectados y de las estimaciones de los ingresos necesarios para cubrir dichos gastos. De esta definición se extraen las siguientes notas: c)	El presupuesto tiene forma contable, porque en su preparación y ejecución sigue las normas del Plan General de Contabilidad Pública ",
  "CONCEPTO DE PRESUPUESTO: -	Neumark: define al presupuesto como el resumen sistemático, confeccionado en períodos regulares, de las previsiones, de los gastos proyectados y de las estimaciones de los ingresos necesarios para cubrir dichos gastos. De esta definición se extraen las siguientes notas: d)	El presupuesto está formalmente equilibrado, es decir, junto a las partidas de gastos se incluyen necesariamente las de los ingresos necesarios para financiarlos. Pero es preciso advertir que el equilibrio formal es una consecuencia del equilibrio contable, pero que en ningún modo supone un equilibrio económico, según el cual una categoría de gastos (los ordinarios) deben financiarse con una categoría de ingresos (también los ordinarios), equilibrio que, aun defendido por determinadas doctrinas económicas no tiene necesariamente que producirse. ",
  "CONCEPTO DE PRESUPUESTO: -	Neumark: define al presupuesto como el resumen sistemático, confeccionado en períodos regulares, de las previsiones, de los gastos proyectados y de las estimaciones de los ingresos necesarios para cubrir dichos gastos. De esta definición se extraen las siguientes notas: e)	Finalmente, el presupuesto implica la idea de reiteración en su elaboración. De nada sirve la preparación esporádica del presupuesto. Es necesaria su elaboración durante períodos iguales y sucesivos. ",
  "CONCEPTO DE PRESUPUESTO: (Neumark) Por tanto, según este autor, puede entenderse el presupuesto como un plan racional que ordena la actividad financiera de un determinado sujeto económico para un período determinado, estableciendo los ingresos y gastos a realizar en un determinado período. ",
  "CONCEPTO DE PRESUPUESTO: De una manera amplia, el presupuesto puede definirse como un documento financiero en el que se refleja el conjunto de gastos que se pretenden realizar durante un período de tiempo determinado y el detalle de los ingresos que se prevén obtener para su financiación. ",
  "CONCEPTO DE PRESUPUESTO: Su uso en el ámbito público apareció en el S. XVIII y se generalizó en el XIX con la implantación del Estado de Derecho y el principio de división de poderes, estando por tanto estrechamente ligado al sistema democrático liberal. De este modo, el presupuesto se configura como un instrumento propio de la Hacienda Pública y en él aparecen reflejados los bienes y servicios que ésta adquiere, así como sus transferencias al sector público y sus ingresos fiscales. ",
  "CONCEPTO DE PRESUPUESTO: Partiendo del concepto de presupuesto, antes señalado, se han diferenciado tradicionalmente tres perspectivas o conceptos posibles, que responden a diferentes efectos del mismo:  -	Económico: el presupuesto es un instrumento de racionalización y organización de la actividad financiera y, en general, de la actuación económica del sector público. Asimismo, refleja el plan económico del grupo político dominante y se convierte en el principal instrumento para la planificación de la Política económica. ",
  "CONCEPTO DE PRESUPUESTO: Partiendo del concepto de presupuesto, antes señalado, se han diferenciado tradicionalmente tres perspectivas o conceptos posibles, que responden a diferentes efectos del mismo:  -	Político: el presupuesto cumple una importantísima función política, pues es un modo de asignar recursos financieros a través de decisiones políticas. El modelo estará condicionado por la organización política y administrativa de cada país. Se erige así el presupuesto como una decisión política adoptada por el órgano competente en la materia; es “el programa de actuación gubernamental para un año”. ",
  "CONCEPTO DE PRESUPUESTO: Partiendo del concepto de presupuesto, antes señalado, se han diferenciado tradicionalmente tres perspectivas o conceptos posibles, que responden a diferentes efectos del mismo:  -	Jurídico: el presupuesto tiene en el caso de los entes públicos, una importante significación jurídica, al ser el presupuesto un acto derivado del poder legislativo, adquiere forma de ley; se torna pues en un acto legislativo mediante el cual se autoriza el montante máximo de los gastos que puede realizar el ejecutivo, y las vías de financiación de los mismos, durante un periodo de tiempo determinado. Ahora bien, respecto a los ingresos los mismos no tienen carácter limitativo sino que se autoriza una estimación de lo que se prevé recaudar para financiar los gastos. ",
  "CONCEPTO DE PRESUPUESTO: La definición legal del presupuesto es recogida en el art. 31 TRLGHP, y que coincide con el art. 32 de la Ley 47/2003, de 26 de noviembre, General Presupuestaria. ",
  "CONCEPTO DE PRESUPUESTO: En el art. 31 TRLGHP se determina que: “De conformidad con lo previsto en el Estatuto de Autonomía, el Presupuesto de la Junta de Andalucía constituye la expresión cifrada, conjunta y sistemática de las obligaciones que, como máximo, pueden reconocer la Junta de Andalucía, sus agencias administrativas y de régimen especial, sus instituciones, consorcios y agencias públicas empresariales que conforme a sus Estatutos sean de las previstas en el artículo 68.1.b) de la Ley 9/2007, de 22 de octubre, y de los derechos que prevean liquidar durante el correspondiente ejercicio, así como las estimaciones de gastos e ingresos a realizar por el resto de las agencias públicas empresariales, por las sociedades mercantiles del sector público andaluz, por las fundaciones y las demás entidades previstas en el artículo 5.3 de la presente Ley, y por la dotación para operaciones financieras de fondos regulados en el artículo 5.5.”",
  "CARACTERES a)	Es expresión cifrada, es decir, se expresa en cifras. Se consignan los créditos mediante cantidades o guarismos expresados en moneda de curso legal: el euro. ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 1.	El Presupuesto será único e incluirá la totalidad de los gastos e ingresos de la Junta de Andalucía y demás entidades referidas en el artículo 31.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  a)	Los estados de gastos de la Junta de Andalucía y de sus agencias administrativas y de régimen especial, en los que se incluirán con la debida especificación los créditos necesarios para atender el cumplimiento de las obligaciones.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  b)	Los estados de ingresos de la Junta de Andalucía y de sus agencias administrativas y de régimen especial, en los que figuren las estimaciones de los distintos derechos económicos que se prevean liquidar durante el correspondiente ejercicio.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  c)	Los estados de gastos e ingresos de las agencias públicas empresariales que conforme a sus Estatutos sean de las previstas en el artículo 68.1.b) de la Ley 9/2007, de 22 de octubre.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  d)	Los estados de gastos e ingresos de sus instituciones.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  e)	Los estados de gastos e ingresos de los consorcios adscritos a la Administración de la Junta de Andalucía.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  f)	Los presupuestos de explotación y de capital de las restantes agencias públicas empresariales y de las sociedades mercantiles del sector público andaluz. Las restantes agencias públicas empresariales y las sociedades mercantiles del sector público andaluz que deban presentar cuentas anuales consolidadas presentarán los presupuestos de explotación y capital de forma individual y de forma consolidada.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  g)	Los presupuestos de explotación y de capital de las fundaciones y demás entidades referidas en el artículo 5.3.  ",
  "CARACTERES b)	Es expresión conjunta, ya que de conformidad con lo establecido en el art. 33 TRLGHP: 2.	El Presupuesto contendrá:  h)	Los presupuestos de los fondos carentes de personalidad jurídica definidos en el artículo 5.5, determinándose expresamente las operaciones financieras. ",
  "CARACTERES c)	Es expresión sistemática, en cuanto implica que tanto los ingresos como los gastos se ordenen conforme a una estructura determinada. ",
  "CARACTERES d)	Tiene carácter anual, lo que se corrobora por el art. 32 TRLGHP: El ejercicio presupuestario coincidirá con el año natural y a él se imputarán: -	Los derechos liquidados durante el mismo, cualquiera que sea el período de que deriven. ",
  "CARACTERES d)	Tiene carácter anual, lo que se corrobora por el art. 32 TRLGHP: El ejercicio presupuestario coincidirá con el año natural y a él se imputarán: -	Las obligaciones reconocidas hasta el día 31 de diciembre, con cargo a los referidos créditos. ",
  "CARACTERES e)	En el Presupuesto de la Comunidad Autónoma se integra todo el sector público de Andalucía. En concreto, el art. 1 de la ley del Presupuestos de la CCAA de Andalucía vigente, Ley 6/2019, de 19 de diciembre, del Presupuesto de la Comunidad Autónoma de Andalucía para el año 2020. señala que:  ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  a) Los estados de gastos e ingresos de la Junta de Andalucía y sus instituciones. ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  b) Los estados de gastos e ingresos de las agencias administrativas. ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  c) Los estados de gastos e ingresos de las agencias de régimen especial. ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  d) Los estados de gastos e ingresos de los consorcios adscritos a la Administración de la Junta de Andalucía. ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  e) Los presupuestos de explotación y de capital de las agencias públicas empresariales y de las sociedades mercantiles del sector público andaluz, participadas mayoritariamentede forma directa o indirecta por la Administración de la Junta de Andalucía, sus agencias y demás entidades de Derecho Público. ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  f) Los presupuestos de explotación y de capital de las fundaciones y demás entidades referidas en el apartado 3 del artículo 5 del texto refundido de la Ley General de la Hacienda Pública de la Junta de Andalucía, aprobado por Decreto Legislativo 1/2010, de 2 de marzo. ",
  "CARACTERES f)	El Presupuesto de la Comunidad Autónoma de Andalucía para el ejercicio 2020 está integrado por:  g) Los presupuestos de los fondos a que se refiere el apartado 5 del artículo 5 del texto refundido de la Ley General de la Hacienda Pública de la Junta de Andalucía. ",
  "CLASES Desde el punto de vista de las Adm. Públicas, la clasificación más conocida es la que distingue entre: -	Ordinario: Recoge las previsiones generales de ingresos y gastos a realizar en el período normal de duración del presupuesto. ",
  "CLASES Desde el punto de vista de las Adm. Públicas, la clasificación más conocida es la que distingue entre: -	Extraordinario: Se consignarán exclusivamente los gastos, que por la razón que fuere, no han podido recogerse en el presupuesto ordinario. ",
  "CLASES En cuanto al momento de su elaboración, en relación al período al que se refieren, los presupuestos pueden ser: -	A Priori: aquellos que ese elaboran antes del inicio del período presupuestado. En España se denominan, en materia presupuestaria, Presupuestos Generales del Estado. Este tipo de presupuesto constituye un acto de previsión. ",
  "CLASES En cuanto al momento de su elaboración, en relación al período al que se refieren, los presupuestos pueden ser: -	A Posteriori: aquellos que se elaboran una vez que ha finalizado el período de presupuestación. En ellos se recogen los ingresos y gastos reales. En España se denominan, en materia presupuestaria, Cuenta General del Estado. ",
  "CLASES Sin embargo, en vez de hablar de presupuesto a posteriori, habría que hablarse de balance de ejecución del presupuesto, ya que el término “presupuesto”, en sentido estricto, se refiere únicamente a un acto de previsión",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS Competencia: Este principio, también llamado “de legalidad” o de “reserva de ley”, viene recogido en los art. 66.2 y 134.1 CE y complementariamente en su art. 75.3. Este principio responde originalmente a las exigencias del principio de separación de poderes, pero si este último ha servido para algo ha sido para ser utilizado como vehículo de reserva de la iniciativa financiera al Ejecutivo, en detrimento de las cámaras legislativas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS De esta forma, la evolución política ha llevado del conflicto a la cooperación entre ambos poderes, en el marco de un predominio del Gobierno (el ejecutivo) sobre el legislativo. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS El principio de competencia tiene una serie de excepciones: ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS El principio de competencia tiene una serie de excepciones: -	Los anticipos de tesorería. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS El principio de competencia tiene una serie de excepciones: -	Las Transferencias de crédito. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS El principio de competencia tiene una serie de excepciones: -	Los créditos ampliables. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS El principio de competencia tiene una serie de excepciones: -	La generación de créditos por afectación de ingresos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS El principio de competencia tiene una serie de excepciones: -	La incorporación de remanentes. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS A)	Unidad: Exige que la totalidad de los ingresos y gastos públicos figuren en un solo documento, para facilitar su examen, discusión y aprobación, tratando así de asegurar el control de toda la actividad financiera pública y permitiendo al tiempo que se la considere de forma global. Por ello este principio está íntimamente al de universalidad, que veremos a continuación. Este principio tiene su corolario contable en el de unidad de caja. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad: Si el principio de unidad hace referencia a la forma, el principio de universalidad se refiere al contenido de los Presupuestos, ordenando que se incluyan en él todos los ingresos y gastos públicos y por sus importes íntegros, sin que quepa su minoración, siendo por ello su correlato contable el principio de presupuesto bruto, recogido en el art. 27.4 LGP. Se proyecta también en la regla contable de no afectación entre ingresos y gastos públicos (art. 27.3 LGP). ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:Este principio se recoge en el art. 134.2 CE. Se trata de que los Presupuestos sean fiel expresión del grado de solidaridad alcanzado, haciendo posible el control parlamentario y convirtiendo en ilegítima cualquier actividad al margen de él, “reflejando en su globalidad la contribución al sostenimiento del gasto público y los criterios en que se ha basado, sin que determinados ingresos puedan entenderse circunscritos a financiar un gasto determinado, pues es el conjunto de aquellos el que debe cubrir el conjunto de los gastos. Desde la óptica más estrictamente financiera, se evita asimismo que los déficit y superávit de los distintos entes públicos puedan dificultar el conocimiento por el legislador de la verdadera situación de la Hacienda Pública.” (Martín Queralt). ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:Son excepciones al principio de universalidad: ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:Son excepciones al principio de universalidad: -	Los créditos extraordinarios y los suplementos de crédito, que ya han sido estudiados, pues no están en el Presupuesto originalmente aprobado. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:Son excepciones al principio de universalidad: -	Los créditos ampliables, por el mismo motivo. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:Son excepciones al principio de universalidad: -	La existencia de documentos presupuestarios propios para los Organismos Autónomos y la Seguridad Social, las entidades públicas empresariales, las sociedades mercantiles y las fundaciones. Bien es cierto que no es posible integrar en un solo documento los presupuestos del sector público administrativo con los del sector público empresarial, aunque si es posible consolidar las operaciones de todos ellos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:Son excepciones al principio de universalidad: -	El que ciertos créditos presupuestarios puedan sustraerse a la exigencia genérica de no afectación entre ingresos y gastos. La regla de no afectación “indica la necesidad de que todos los ingresos se destinen a financiar indistinta y globalmente los gastos públicos, sin que se reconozca la prioridad en un determinado gasto respecto de una fuente concreta de financiación.” (Martínez Lago). ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad: Siguiendo a Pablo Taboada, el principio de no afectación tendría mayor alcance y sería distinto del de universalidad, puesto que a su juicio puede afectarse un ingreso a un gasto y no sufrir lesión el principio de universalidad, mientras que lo contrario no sería cierto. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS B)	Universalidad:La Ley General Presupuestaria admite en el art. 27.3 la afectación, si se produce por ley. Además, el art. 53 admite y regula la generación de créditos, incluyendo la reposición de créditos por reintegro de los pagos indebidos realizados con cargo a los mismos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: Concebido como exigencia de que el gasto público se oriente al destino aprobado por la Ley de Presupuestos, tiene una triple proyección: ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: a) 	Cuantitativa: que indica el volumen máximo que se destina a dicha finalidad. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: a) 	Cuantitativa: que indica el volumen máximo que se destina a dicha finalidad. Bajo el título de “Limitación de  los compromisos de gasto”, la especialidad cuantitativa se recoge en el art. 46 de la Ley General Presupuestaria: “Los créditos para gastos son limitativos. No podrán adquirirse compromisos de gasto ni adquirirse obligaciones por cuantía superior al importe de los créditos autorizados en los estados de gastos, siendo nulos de pleno derecho los actos administrativos y las disposiciones generales con rango inferior a ley que incumplan esta limitación, sin perjuicio de las responsabilidades reguladas en el título VII de esta ley”. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: a) 	Cuantitativa: que indica el volumen máximo que se destina a dicha finalidad. Hay que recodar la distinción entre nacimiento de la obligación y su exigibilidad. A la primera se refiere el art. 20 de la Ley General Presupuestaria y a la segunda el art. 21, de forma que, si no existe crédito presupuestario la obligación será válida, pero no exigible. Pero hay supuestos específicos en los que este principio atenúa su rigor, al reconocerse la posibilidad de realizar determinados gastos que exceden de la cuantía inicialmente aprobada: ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: a) 	Cuantitativa: que indica el volumen máximo que se destina a dicha finalidad. - La generación, reposición e incorporación de créditos o Los anticipos de tesorería.",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: a) 	Cuantitativa: que indica el volumen máximo que se destina a dicha finalidad. - Los créditos ampliables. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: b) 	Cualitativa: que es la que manifiesta la concreta finalidad que se quiere perseguir con el crédito. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: b) 	Cualitativa: Se recoge en el art. 42.1 que prevé la vinculación de los créditos a la “finalidad específica para la que hayan sido autorizados”, si bien reconoce a continuación la posibilidad de modificaciones aprobadas en la misma Ley General Presupuestaria. Por cierto que este artículo reproduce prácticamente el contenido del art. 27.2, aunque éste tiene una redacción más ajustada: “Los créditos presupuestarios de la Adm. General del Estado, sus organismos autónomos y de las entidades integrantes del sector público estatal con presupuesto limitativo se destinarán exclusivamente a la finalidad específica para la que hayan sido autorizados por la Ley de Presupuestos Generales del Estado o por las modificaciones realizadas conforme a esta ley. El carácter limitativo y vinculante de dichos créditos será el correspondiente al nivel de especificación con que aparezcan en aquellos”. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: b) 	Cualitativa: Art. 42. Especialidad de los créditos: “Los créditos para gastos se destinarán exclusivamente a la finalidad específica para la que hayan sido autorizados por la Ley de Presupuestos o a la que resulte de las modificaciones aprobadas conforme a esta ley.” ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: b) 	Cualitativa: Las dos excepciones a este principio son: -	El Fondo de Contingencia. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: b) 	Cualitativa: Las dos excepciones a este principio son: -	Las transferencias de crédito. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS C) Especialidad: c) Temporal: que limita el tiempo en el que puede gastarse esa cantidad para esa finalidad. Establece que los gastos deben ser contraídos, asignados y pagados sólo dentro del periodo para el cual se han autorizado. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Viene recogido en el art. 134.2 de la Constitución, al establecer que los ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Presupuestos tienen carácter anual, si bien el art. 49 LGP se refiere al ejercicio presupuestario, teniendo que ir al art. 34 LGP para ver que el ejercicio se hace coincidir con el año natural. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: El principio está justificado tanto desde el punto de vista jurídico (que el control político y de legalidad sea frecuente), como desde el punto de vista económico (hacer posibles las previsiones y que sean fiables). En palabras de Gonzalo: “La temporalidad presupuestaria supone que los créditos para gastos que, al término del ejercicio correspondiente, no hayan sido aplicados a su finalidad queden anulados”. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Sin embargo el principio sufre excepciones importantes: La prevista en el art. 49 de la Ley General Presupuestaria, cuando admite las salvedades reguladas en los art. 34.2 y 34.3, por las que se pueden imputar al presupuesto corriente las órdenes de pago derivadas de obligaciones de ejercicios anteriores, como la liquidación de atrasos retributivos, resoluciones judiciales y compromisos de gasto adquiridos en ejercicios anteriores si hay una norma con rango de ley que la autorice. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Sin embargo el principio sufre excepciones importantes: En sentido inverso, el art. 48 LGP permite diferir a ejercicios futuros los pagos por compra de bienes inmuebles cuyo importe exceda de 6 millones de euros y por contratos de obras con abono total del precio. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Sin embargo el principio sufre excepciones importantes: Los créditos plurianuales, regulados en el art. 47 LGP, concebidos para actividades, sobre todo de inversión, con proyección sobre varios ejercicios a los que pueden aplicarse los gastos. La novedad es que estos créditos deben especificarse en los escenarios presupuestarios plurianuales, que deben ajustarse a su vez al objetivo de estabilidad presupuestaria. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Sin embargo el principio sufre excepciones importantes: La incorporación de remanentes, ya estudiada, lógicamente también supone una excepción a la especialidad temporal. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Sin embargo el principio sufre excepciones importantes: Por último, son excepciones a la especialidad temporal los créditos extraordinarios, los suplementos de crédito y los anticipos de tesorería, todos ellos ya estudiados. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: En la práctica, el principio de anualidad se entendió de manera estricta, lo que llevó a que muchos de los preceptos incluidos en la Ley de Presupuestos perdían su vigencia el 31 de diciembre, por lo que se reproducían en las sucesivas leyes. Sin embargo, a partir de 1986 se empieza a abandonar la extensión del principio de anualidad al articulado, utilizando el ardid de llevar a las Disposiciones Adicionales aquellas normas a las que se quería dar vigencia indefinida, reservando para el articulado las que sólo tendrían vigencia anual. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 1ª) El sistema presupuestario escogido para la imputación de ingresos y gastos: se refiere a la elección de un criterio de imputación a un ejercicio de los ingresos y gastos, existiendo dos posibilidades o sistemas: ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 1ª) El sistema presupuestario escogido para la imputación de ingresos y gastos: se refiere a la elección de un criterio de imputación a un ejercicio de los ingresos y gastos, existiendo dos posibilidades o sistemas: -	El presupuesto de ejercicio o de competencia, que sigue un criterio jurídico o formal, según el cual los gastos se imputan al ejercicio en el cual se hayan reconocido las obligaciones y los ingresos se imputan al ejercicio en que se hayan liquidado los derechos. Es un sistema que se basa pues en el criterio del devengo y es el que se utiliza en la Contabilidad Nacional. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 1ª) El sistema presupuestario escogido para la imputación de ingresos y gastos: se refiere a la elección de un criterio de imputación a un ejercicio de los ingresos y gastos, existiendo dos posibilidades o sistemas: -	El presupuesto de caja o de gestión, sigue un criterio económico o monetario, según el cual los gastos (y los ingresos) se imputan al ejercicio en que se hayan cobrado. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 1ª) El sistema presupuestario escogido para la imputación de ingresos y gastos: se refiere a la elección de un criterio de imputación a un ejercicio de los ingresos y gastos, existiendo dos posibilidades o sistemas: La Ley General Presupuestaria ha optado por un criterio de devengo o presupuesto de ejercicio puro (art. 34.1), aunque esto no ha sido siempre así, pues la Ley General Presupuestaria de 1977 utilizó el criterio de devengo, pero con un período de ampliación para los gastos hasta abril del año siguiente, en que se cerraba el ejercicio), este criterio se suprimió en 1984 y se reestableció por la ley 11/1996, de 27 de diciembre, si bien el período de ampliación se redujo al mes de enero. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 2ª) La prórroga de la ley de Presupuestos: la segunda cuestión es saber qué ocurre cuando el 1 de enero de un año concreto no han sido aprobados los Presupuestos para el ejercicio. La Constitución, en su art. 134.4, establece la prórroga automática de los Presupuestos del ejercicio anterior hasta la aprobación de los nuevos, pero la redacción es tan parca que necesita una aclaración, contenida en el art. 38 de la Ley General Presupuestaria: -	Se considerarán prorrogados los presupuestos iniciales del ejercicio anterior, por tanto, sin  las modificaciones que se hayan producido a lo largo del ejercicio (art. 38.1). ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 2ª) La prórroga de la ley de Presupuestos: la segunda cuestión es saber qué ocurre cuando el 1 de enero de un año concreto no han sido aprobados los Presupuestos para el ejercicio. La Constitución, en su art. 134.4, establece la prórroga automática de los Presupuestos del ejercicio anterior hasta la aprobación de los nuevos, pero la redacción es tan parca que necesita una aclaración, contenida en el art. 38 de la Ley General Presupuestaria: -	La prórroga no afectará a los créditos correspondientes a programas o actuaciones que terminen o se extingan en el ejercicio cuyos presupuestos se prorrogan (art. 38.2). ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS D)	Anualidad: Hay otras dos cuestiones de gran relevancia que procede estudiar al hilo del principio de estabilidad temporal: 2ª) La prórroga de la ley de Presupuestos: la segunda cuestión es saber qué ocurre cuando el 1 de enero de un año concreto no han sido aprobados los Presupuestos para el ejercicio. La Constitución, en su art. 134.4, establece la prórroga automática de los Presupuestos del ejercicio anterior hasta la aprobación de los nuevos, pero la redacción es tan parca que necesita una aclaración, contenida en el art. 38 de la Ley General Presupuestaria: -	No afecta a los ingresos, pues sabemos que el estado de ingresos es una mera previsión. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS POLÍTICOS E) Publicidad: Es una consecuencia del principio de competencia, puesto que si los Presupuestos tienen que ser aprobados por ley, ésta sólo produce efectos jurídicos una vez que haya sido publicada en el BOE y entra en vigor. Hay que tener en cuenta que el art. 9.3 CE garantiza la publicidad de las normas jurídicas.",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS CONTABLES La contabilidad pública tenía para los clásicos, un carácter de instrumento de control presupuestario, comprobando que los recursos son gestionados conforme a los principios políticos y económicos. Los principios contables informadores del presupuesto clásico eran los siguientes: A)	Presupuesto bruto: La contabilidad ha de llevarse por un sistema administrativo y no especulativo y como consecuencia del principio de universalidad, se exige que todas las partidas se consignen en el presupuesto, sin aumentos o minoraciones. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS CONTABLES La contabilidad pública tenía para los clásicos, un carácter de instrumento de control presupuestario, comprobando que los recursos son gestionados conforme a los principios políticos y económicos. Los principios contables informadores del presupuesto clásico eran los siguientes: B)	Unidad de caja: Derivado del principio político de unidad, establece que todos los ingresos y gastos de la Hacienda Pública deben realizarse a través de la misma caja. Persigue coordinar la tesorería de los caudales públicos, prohibiendo las cajas especiales. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS CONTABLES Se encomienda tradicionalmente, desde la ley de 1850, al Tesoro Público, tratándose en este caso, no tanto de la unidad física como de la unidad contable y de dirección. La Ley General Presupuestaria ofrece una definición de Tesoro Público en su art. 90, según la cual se constituye de: “todos los recursos financieros, sean dinero, valores o créditos de la Administración General del Estado y sus organismos autónomos, tanto por operaciones presupuestarias como extrapresupuestarias”. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS CONTABLES Tradicionalmente ha sido el Banco de España el que ha hecho posible el principio de unidad de caja, al mantener el Tesoro en el mismo una cuenta, en principio, única. Pero la incorporación a la Unión Económica y Monetaria, concediendo plena autonomía al Banco Central Europeo, para lo que se aprobó la ley 13/1994, de 1 de junio, se aprovechó para, en la ley “de acompañamiento” a la de Presupuestos Generales del Estado de 1999 (Ley 50/1998), modificar la LGP de 1977 y admitir la existencia de varias cuentas, así como que se canalicen los ingresos en cuentas abiertas  en otras entidades de crédito por la Administración General del Estado distintas de la del Banco de España, modificación que se recoge en la Ley General Presupuestaria de 2003. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS CONTABLES Por otra parte, hay que destacar que la Seguridad Social cuenta con su propia tesorería, regulada en el Real Decreto 2318/1978, de 15 de septiembre, lo que es de hecho una ruptura del principio de unidad de caja. C)	Especificación: Tiene su origen en el principio de especialidad y reduce la discrecionalidad del Ejecutivo en la gestión presupuestaria, que ha de acomodarse a lo autorizado por el Parlamento, al aprobar el presupuesto con el límite cualitativo y cuantitativo, los cuales contablemente deben ser comprobados. Todos los ingresos y gastos deben ser registrados en su nivel de desagregación. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS CONTABLES Por otra parte, hay que destacar que la Seguridad Social cuenta con su propia tesorería, regulada en el Real Decreto 2318/1978, de 15 de septiembre, lo que es de hecho una ruptura del principio de unidad de caja. D)	Ejercicio cerrado: Finalizado el periodo de vigencia, se deben realizar las operaciones contables de cierre de ejercicio. A través del principio de ejercicio cerrado se establece una limitación a cualquier prórroga contable de una actuación presupuestaria vencida. Cuando haya que realizar un pago o percibir un ingreso por cuenta de otro ejercicio, se abre una cuenta especial ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: A)	Limitación del gasto público: Habida cuenta que para los economistas clásicos el gasto público era considerado como \"un mal necesario\", pues suponía un consumo improductivo de recursos, ya que no contribuía a la acumulación de capital y no favorecía el desarrollo económico, debía ser lo menor posible y destinarse exclusivamente a aquellos ámbitos donde el sector privado no pudiese intervenir. El sector público sólo debe gastar en mantener la ley y el orden. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: B)	Neutralidad impositiva: Siguiendo las clásicas máximas de ADAM SMITH sobre los impuestos, el Estado era incapaz de realizar fines político-sociales o político-económicos mediante los impuestos y no debía perturbar los benéficos efectos de la disciplina de mercado. En consecuencia, la financiación de los gastos públicos contenidos en el presupuesto, debía efectuarse sin beligerancia en los impuestos, para no obstaculizar a través de ellos el desarrollo económico. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Es el más importante de los principios económicos, se basa en que deben coincidir los ingresos y los gastos públicos del presupuesto. Tiene por objeto limitar la participación pública en el mercado, pues ello ocasionaría una intromisión en la economía privada y una disminución de la riqueza y capacidad económica de los ciudadanos. Se trata de un principio conocido como “la regla de oro” de la hacienda Pública, formulado por el ideario liberal del siglo XIX, opuesto a la intervención estatal y al déficit recordando el arbitrismo en el manejo de la Hacienda Pública de las monarquías absolutas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. El principio tenía como enunciado que los gastos presupuestarios deben ser financiados por los  ingresos ordinarios. Este principio clásico entró en crisis, sobre todo, por la revolución keynesiana y el nacimiento de la política fiscal, que admitía el déficit como instrumento de crecimiento económico y estabilización. En Europa se introduce de la mano del Pacto de Estabilidad y Crecimiento (PEC), aprobado por el Consejo de la Unión Europea celebrado en Dublín en diciembre de 1996 y adoptado en Ámsterdam en junio de 1997. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. En el contexto de un PEC, se aprueba en España la ley 18/2001, de 12 de diciembre, General de Estabilidad Presupuestaria y su complementaria, la LO 5/2001, de 13 diciembre, introduciendo en el ordenamiento jurídico- financiero español la prohibición del déficit en los Presupuestos del sector público, no sólo en los de la Adm. Central, también en los de las CCAA y los de la Corporaciones Locales.  ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. La estabilidad presupuestaria, consagrada constitucionalmente, es base para impulsar el crecimiento y la creación de empleo en la economía española, para garantizar el bienestar de los ciudadanos, crear oportunidades a los emprendedores y ofrecer una perspectiva de futuro más próspera, justa y solidaria. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. La salvaguardia de la estabilidad presupuestaria es un instrumento indispensable para lograr este objetivo, tanto para garantizar la financiación adecuada del sector público y los servicios públicos de calidad sobre los que descansa el sistema de bienestar, como para ofrecer seguridad a los inversores respecto a la capacidad de la economía española para crecer y atender nuestros compromisos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. El proceso de consolidación fiscal y reducción de la deuda pública que permitió la entrada de España en la Unión Económica y Monetaria europea, fue uno de los principales activos sobre los que se cimentó el largo período de crecimiento de la economía española hasta 2008. Sin embargo, ese año se inició una crisis económica de alcance mundial, especialmente severa en el ámbito europeo, cuyos efectos se vieron agravados en nuestra economía debido a la elevada tasa de desempleo, la más alta entre los países de la OCDE. El fuerte deterioro de las finanzas públicas registrado desde ese año, agotó rápidamente los márgenes de maniobra de la política fiscal, obligando ahora a practicar un fuerte ajuste que permita recuperar la senda hacia el equilibrio presupuestario y cumplir los compromisos de España con la Unión Europea. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. La crisis económica puso rápidamente de manifiesto la insuficiencia de los mecanismos de disciplina de la anterior Ley de Estabilidad Presupuestaria. En el marco de esa Ley se alcanzó el mayor déficit de nuestras Administraciones Públicas, con un 11,2 por ciento del Producto Interior Bruto en 2009. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Paralelamente, las tensiones financieras desencadenadas en los mercados europeos, pusieron en evidencia la fragilidad del entramado institucional de la Unión Europea y la necesidad de avanzar en el proceso de integración económica, con la premisa de lograr una mayor coordinación y responsabilidad fiscal y presupuestaria de los Estados miembros. En este contexto, en los últimos meses se han aprobado una serie de iniciativas legislativas en el ámbito europeo orientadas a reforzar las reglas fiscales comunes y desarrollar una mayor supervisión económica y fiscal. El Pacto Fiscal europeo, la mayor coordinación, vigilancia y supervisión en materia presupuestaria, acompañados del desarrollo del mecanismo de estabilidad financiera para dar una respuesta conjunta a las tensiones de los mercados financieros, constituyen el marco de gobernanza económica que define una Unión Europea reforzada y capaz de hacer frente a los exigentes retos del nuevo escenario económico internacional. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Esta situación exige aplicar una política económica contundente basada en dos ejes complementarios que se refuerzan: La consolidación fiscal, es decir, la eliminación del déficit público estructural y la reducción de la deuda pública, y las reformas estructurales. Pero más allá de esta respuesta inmediata, es necesario consolidar el marco de política económica y fiscal que permite asegurar de forma permanente el crecimiento económico y la creación de empleo. Este es un reto que debemos alcanzar de la mano de Europa, participando activamente en el diseño de las políticas y estrategias que definen la nueva gobernanza económica, y aplicando con rigor las exigencias que de ella dimanan. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. La garantía de la estabilidad presupuestaria es una de las claves de la política económica que contribuirá a reforzar la confianza en la economía española, facilitará la captación de financiación en mejores condiciones y, con ello, permitirá recuperar la senda del crecimiento económico y la creación de empleo. Este convencimiento llevó en septiembre de 2011 a reformar el artículo 135 de la Constitución Española, introduciendo al máximo nivel normativo de nuestro ordenamiento jurídico una regla fiscal que limita el déficit público de carácter estructural en nuestro país y limita la deuda pública al valor de referencia del Tratado de Funcionamiento de la Unión Europea. El nuevo artículo 135 establece el mandato de desarrollar el contenido de este artículo en una Ley Orgánica antes del 30 de junio de 2012. Con la aprobación de la presente Ley Orgánica de Estabilidad Presupuestaria y Sostenibilidad Financiera de las Administraciones Públicas se da pleno cumplimiento al mandato constitucional. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Pero, además, la reforma de la Constitución pretende también manifestar el claro compromiso de España con las exigencias de coordinación y definición del marco de estabilidad común de la Unión Europea. Por ello, la referencia a la normativa de estabilidad europea, tanto en la Constitución como en la Ley Orgánica, es constante, siendo además España uno de los primeros países en incorporar el paquete de gobernanza económica europea a su ordenamiento jurídico interno. Además, esta Ley da cumplimiento al Tratado de Estabilidad, Coordinación y Gobernanza en la Unión Económica y Monetaria de 2 de marzo de 2012, garantizando una adaptación continua y automática a la normativa europea. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Los tres objetivos de la Ley son: Garantizar la sostenibilidad financiera de todas las Administraciones Públicas; fortalecer la confianza en la estabilidad de la economía española; y reforzar el compromiso de España con la Unión Europea en materia de estabilidad presupuestaria. El logro de estos tres objetivos contribuirá a consolidar el marco de la política económica orientada al crecimiento económico y la creación del empleo. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. La primera novedad de la Ley es su propio título, ya que incorpora la sostenibilidad financiera como principio rector de la actuación económico financiera de todas las Administraciones Públicas españolas. Con ello se pretende reforzar la idea de estabilidad, no solo en un momento coyuntural, sino con carácter permanente, lo que contribuirá a preparar el camino para los retos a los que nuestro sistema de bienestar se enfrenta a medio y largo plazo. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. A diferencia de la normativa anterior, la Ley regula en un texto único la estabilidad presupuestaria y sostenibilidad financiera de todas las Administraciones Públicas, tanto del Estado como de las Comunidades Autónomas, Corporaciones Locales y Seguridad Social. Esto mejora la coherencia en la regulación jurídica, supone una mayor claridad de la Ley y transmite una idea de igualdad en las exigencias presupuestarias, de responsabilidad y lealtad institucional entre todas las Administraciones Públicas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. El capítulo II (Principios generales), destinado a los principios generales de la Ley, mantiene los cuatro principios de la legislación anterior –estabilidad presupuestaria, plurianualidad, transparencia y eficacia y eficiencia en la asignación de los recursos públicos–, reforzando alguno de sus elementos, e introduce tres nuevos principios: Sostenibilidad financiera, responsabilidad y lealtad institucional. El principio de estabilidad presupuestaria se define como la situación de equilibrio o superávit. Se entenderá que se alcanza esta situación cuando las Administraciones Públicas no incurran en déficit estructural. Este principio se refuerza con el de sostenibilidad financiera, que consagra la estabilidad presupuestaria como conducta financiera permanente de todas las Administraciones Públicas. Debe destacarse también la inclusión en la Ley del principio de lealtad institucional, como principio rector para armonizar y facilitar la colaboración y cooperación entre las distintas administraciones en materia presupuestaria. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. El capítulo III (Estabilidad presupuestaria y sostenibilidad financiera) dedicado a la estabilidad presupuestaria y sostenibilidad financiera, introduce importantes novedades en nuestra legislación. Todas las Administraciones Públicas deben presentar equilibrio o superávit, sin que puedan incurrir en déficit estructural. No obstante, el Estado y las Comunidades Autónomas podrán presentar déficit estructural en las situaciones excepcionales tasadas en la Ley: Catástrofes naturales, recesión económica o situación de emergencia extraordinaria, situaciones que deberán ser apreciadas por la mayoría absoluta del Congreso de los Diputados. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. El capítulo incorpora, además, la regla de gasto establecida en la normativa europea, en virtud de la cual el gasto de las Administraciones Públicas no podrá aumentar por encima de la tasa de crecimiento de referencia del Producto Interior Bruto. Esta regla se completa con el mandato de que cuando se obtengan mayores ingresos de los previstos, éstos no se destinen a financiar nuevos gastos, sino que los mayores ingresos se destinen a una menor apelación al endeudamiento. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Asimismo, se fija el límite de deuda de las Administraciones Públicas, que no podrá superar el valor de referencia del 60 por ciento del Producto Interior Bruto establecido en la normativa europea, salvo en las mismas circunstancias excepcionales en que se puede presentar déficit estructural. Además, se establece la prioridad absoluta de pago de los intereses y el capital de la deuda pública frente a cualquier otro tipo de gasto, tal y como establece la Constitución, lo que constituye una garantía rotunda ante los inversores. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Se regulan los criterios para el establecimiento de los objetivos de estabilidad presupuestaria y de deuda pública para cada una de las Administraciones Públicas e individualmente para las Comunidades Autónomas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: C)	Equilibrio presupuestario: El principio de estabilidad. Por último, se contemplan los informes sobre cumplimiento de los objetivos de estabilidad presupuestaria, de deuda pública y de la regla de gasto. Se informará sobre el cumplimiento de los objetivos tanto en los proyectos de Presupuesto, como en los Presupuestos iniciales y en su ejecución. La verificación del cumplimiento en las fases previas a la ejecución permitirá una actuación preventiva en caso de riesgo y la adopción, si fuera necesario, de medidas de corrección. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: D) Autoliquidación de la deuda: Parte del paralelismo entre el Estado y las empresas privadas, considerando que la emisión de deuda pública solo está justificada para la financiación de inversiones productoras de riqueza, con la cual se van a poder pagar sus intereses y amortizar el principal, ya que a través de la emisión de deuda pública el Estado detrae capital que podría utilizarse para empleos productivos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.1.	PRINCIPIOS CLÁSICOS - PRINCIPIOS ECONÓMICOS Para los economistas liberales la actividad económica del Estado tenía que ser lo más limitada posible y ajustarse a una serie de reglas que debían orientar el presupuesto y la gestión de la actividad financiera del Sector Público. Los principios económicos, por los que se debía regir el presupuesto clásico, eran los siguientes: D) Autoliquidación de la deuda: El endeudamiento  excepcional del sector público  solo debe  ser utilizado  para financiar  gastos públicos  de inversión. La deuda será autoliquidable en la medida que permita que el beneficio que va a obtener la sociedad con la inversión pública haga posible el pago de la deuda y su carga financiera sin necesidad de aumentar la carga tributaria. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN A) Principios financieros: Estos principios son de aplicación a todos los presupuestos públicos: - Asignación equitativa, eficiencia y economía (Art. 32.1.b) CE) ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN A) Principios financieros: Estos principios son de aplicación a todos los presupuestos públicos: - Posibilidad de planificar la actividad económica del Estado (Art. 133.4.d) CE) ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN A) Principios financieros: Estos principios son de aplicación a todos los presupuestos públicos: - Control de la gestión económica del Estado (Art. 153.e) CE) ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN A) Principios financieros: Estos principios son de aplicación a todos los presupuestos públicos: - Autonomía financiera, coordinación y solidaridad (Art. 156 CE) ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". Dentro de este Título, en el art. 134 se desarrollan los PGE, el cual pese a su longitud (siete apartados) no da un concepto de los mismos, limitándose a establecer su ámbito competencial y su contenido. Sin embargo, este artículo sí recoge los principios reguladores de los PGE: Competencia, Unidad, Universalidad y Anualidad. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". El art. 134.1 atribuye al Gobierno la elaboración de los Presupuestos Generales del Estado y a las Cortes, su examen, enmienda y aprobación. Configura el Poder Presupuestario como una colaboración entre los poderes del Estado, sin perjuicio de la preeminencia del Ejecutivo sobre el Legislativo (artículo 134.5 y 6.). ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". El art. 134.2 señala que los PGE incluirán la totalidad de los gastos e ingresos del sector público estatal, así como el importe de los beneficios fiscales que afecten a los tributos del Estado. Los Presupuestos Generales del Estado son contemplados desde un punto de vista microeconómico, como un instrumento para la asignación de recursos y no en su visiónmacroeconómica como instrumento de política económica. Asimismo señala su duración, atribuyéndoles carácter anual, haciendo coincidir el ejercicio presupuestario con el año natural. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". La Constitución pese a atribuir la aprobación de los Presupuestos Generales del Estado a las Cortes Generales, no define su carácter de Ley, aunque los apartados 4 y 7 hacen referencia a la Ley de Presupuestos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". No obstante dicho art. 134, recoge las notas características de los Presupuestos: - Constituyen una previsión económica. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". No obstante dicho art. 134, recoge las notas características de los Presupuestos: - Comprenden los ingresos y gastos públicos. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". No obstante dicho art. 134, recoge las notas características de los Presupuestos: - Compete elaborarlos al Ejecutivo y aprobarlos a las Cortes. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". No obstante dicho art. 134, recoge las notas características de los Presupuestos: - Revisten la forma de Ley. ",
"PRINCIPIOS PRESUPUESTARIOS 5.2. PRINCIPIOS PRESUPUESTARIOS EN LA CONSTITUCIÓN B) Principios reguladores de los Presupuestos Generales del Estado: Título VII CE \"Economía y Hacienda\". No obstante dicho art. 134, recoge las notas características de los Presupuestos: - Son vinculantes para el Ejecutivo. - Tienen una vigencia determinada. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA El presupuesto deberá ajustarse a unos principios recogidos en el TRLGHP, principalmente en los art. 9 y 10. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: a. De estabilidad presupuestaria. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: b. De eficiencia y economía. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: c. De coordinación, transparencia y eficacia en la gestión. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: d. De presupuesto anual. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: e. De unidad de caja. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: f. De intervención de todas las operaciones de contenido económico. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: g. De contabilidad pública, tanto para reflejar toda clase de operaciones y resultados de su actividad, como para facilitar datos e información, en general, que sean necesarios para el desarrollo de sus funciones. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 1. La Administración de la Hacienda de la Junta de Andalucía estará sometida a los siguientes principios: h. De no afectación de los ingresos: los recursos de la Junta se destinarán a satisfacer el conjunto de sus respectivas obligaciones, salvo que por Ley se establezca su afectación a fines determinados. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 9. Principios presupuestarios. 2. Las cuentas de la Junta de Andalucía se rendirán al Tribunal de Cuentas y a la Cámara de Cuentas de Andalucía, de acuerdo con las disposiciones que regulan las funciones de estas instituciones, serán censuradas por las mismas y sometidas al control del Parlamento de Andalucía. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. 1. Los derechos liquidados y las obligaciones reconocidas se aplicarán al Presupuesto por su importe íntegro, quedando prohibido atender obligaciones mediante minoración de los derechos liquidados o ya ingresados. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. 2. Se exceptúan de lo anterior las devoluciones de ingresos que se declaren indebidos por el Tribunal o autoridad competente. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Legalidad o reserva de ley: el presupuesto deberá aprobarse por ley del Parlamento de Andalucía. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Competencia: vinculado al anterior, el presupuesto deberá elaborarse por el ejecutivo, aprobarse por el legislativo, ejecutarse por el ejecutivo y ser controlado por los órganos competentes. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Control: el presupuesto será controlado en distintas fases y por distintos órganos de control, esto es, la Intervención General, el Tribunal de Cuentas, la Cámara de Cuentas andaluza y el Parlamento andaluz. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Unidad: el presupuesto será único para todo el sector de la Junta de Andalucía, sus organismos autónomos, sus instituciones y sus empresas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Universalidad: el presupuesto incluirá todos los Ingresos y Gastos del sector público andaluz. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Unidad de Caja: es un principio limitado a la Junta puesto que sus organismos, instituciones y empresas por su propia naturaleza tendrán patrimonio propio y por tanto la posibilidad de ingresar por su cuenta. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Estimación de ingresos y de limitación de Gastos: mientras las cifras de ingresos que aparecen en los presupuestos son sólo una previsión y la realidad económica determinará la cuantía total de estos al final del ejercicio, los gastos son limitados y vinculantes no pudiéndose, en principio, gastar por una cantidad superior a lo establecido presupuestariamente. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Anualidad: el presupuesto coincide con el año natural y a él se imputarán los derechos liquidados durante el mismo y las obligaciones reconocidas hasta el final del ejercicio. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Equilibrio formal: el presupuesto nace equilibrado de tal forma que sus ingresos y gastos consolidados coinciden. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Forma contable: el sometimiento a la contabilidad pública determina la necesidad de adaptar el sistema de información del presupuesto a la contabilidad pública a la que está sujeta ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: a) Autonomía financiera. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: b) Suficiencia financiera. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: c) Garantía de financiación de los servicios de educación, sanidad y otros servicios sociales esenciales del Estado de bienestar para alcanzar niveles similares en el conjunto del Estado ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: d) Responsabilidad fiscal, de acuerdo con los principios constitucionales de generalidad, equidad, progresividad, capacidad económica, así como coordinación y transparencia en las relaciones fiscales y financieras entre las Administraciones Públicas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: e) lealtad institucional, coordinación y colaboración con la Hacienda estatal y con las restantes haciendas públicas. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: f) Solidaridad. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: g) Nivelación de los servicios públicos fundamentales. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: h) Libre definición del destino y volumen del gasto público para la prestación de los servicios a su cargo, sin perjuicio de las exigencias en materia de estabilidad presupuestaria y de los demás criterios derivados de la normativa de la Unión Europea y de la legislación del Estado. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: i) Prudencia financiera y austeridad. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios del art. 175 del Estatuto de Autonomía: son principios generales de la Hacienda de la Comunidad Autónoma, partiendo de la garantía del principio de igualdad en el acceso y prestación de servicios y bienes públicos en todo el territorio español, los siguientes: j) Participación mediante relaciones multilaterales en los organismos que proceda, relacionados con la financiación autonómica. ",
"PRINCIPIOS PRESUPUESTARIOS 5.3. PRINCIPIOS PRESUPUESTARIOS EN LA NORMATIVA ANDALUZA Art. 10. Principio de Presupuesto bruto. Estos principios, en resumen, son los siguientes: - Principios económicos: los gastos deberán llevarse a cabo siguiendo criterios de eficacia, eficiencia y economía. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS Los presupuestos han evolucionado conforme lo hacían las doctrinas económicas, de modo que existen diversos modelos presupuestarios. Pasamos seguidamente a explicar sucintamente estos distintos modelos hasta llegar al Presupuesto por Programas, también conocido por sus siglas inglesas (PPBS). ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS A) Presupuesto administrativo o clásico: Para permitir el control del Legislativo sobre el Ejecutivo, los presupuestos se convierten en una autorización para gastar limitada a unos determinados órganos administrativos. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS A) Presupuesto administrativo o clásico: Tiene su origen a finales del siglo XVIII y comprende hasta el primer tercio del siglo XX. Está basado en la ideología del liberalismo político y económico. Para su articulación se elaboraron una serie de principios que recogen determinados condicionantes de la vida presupuestaria, durante su elaboración, ejecución y control. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS A) Presupuesto administrativo o clásico: El modelo presupuestario clásico tuvo una larga duración, pero a partir del final de la 2ª guerra mundial entró en crisis, al pasarse de un Estado liberal a uno democrático social que suple a la iniciativa privada interviniendo en la actividad económica. Se cuestionaba la vigencia de algunos de sus principios, especialmente los de carácter económico, los cuales es preciso contemplarlos con una nueva visión. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS A) Presupuesto administrativo o clásico: Como consecuencia de las crisis económicas del primer tercio del siglo XX se produjo una disminución de rentas y por consiguiente una menor recaudación por tributos, al tiempo que la demanda de servicios públicos crecía. En consecuencia y con el fin de desarrollar la actividad económica, el Estado siguiendo la doctrina keynesiana tenía que intervenir de forma paralela en la economía nacional, lo cual produjo la ruptura del equilibrio presupuestario y la aparición del déficit público. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS A) Presupuesto administrativo o clásico: Asimismo, dada la primacía del Ejecutivo, la competencia presupuestaria del Legislativo está limitada a su enmienda parcial y en su caso a su devolución al Gobierno. Por contra, el Ejecutivo a través de modificaciones presupuestarias en los supuestos previstos, puede incrementar los gastos aprobados por el Parlamento. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS B) Presupuesto funcional: La crisis económica de principios de este siglo motivó que aumentasen los gastos y disminuyesen los ingresos públicos. En consecuencia, para lograr la eficiencia en la asignación de los recursos, se distribuyen los gastos entre las funciones encaminadas a la prestación de los servicios públicos que financian. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS B) Presupuesto funcional: A través del documento presupuestario se informa de lo que va a hacer el Estado, permitiendo comparar la atención prestada a cada una de sus funciones. Sin embargo, la presentación funcional no mejoró el procedimiento presupuestario, pues en realidad se trataba de continuar con el presupuesto administrativo clásico y distribuir los gastos entre las funciones de las unidades que los producen. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS B) Presupuesto funcional: A su vez, tiene la ventaja que permite establecer comparaciones entre el nivel de las funciones del sector público en distintas Administraciones o países. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS B) Presupuesto funcional: En España la clasificación funcional de los gastos, es uno de los criterios que se utiliza para estructurarlos, siendo el más importante de los tres existentes (funcional, orgánico y económico). ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS C) Presupuesto de ejecución: Tuvo su origen en los Estados Unidos durante 1947 (Performance Budgeting), para justificar el crecimiento del gasto y de la imposición, así como del endeudamiento público. Se basa en la presentación de los presupuestos en funciones, en actividades y en proyectos, de forma que se puedan evaluar sus finalidades y sus resultados. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS C) Presupuesto de ejecución: La Oficina del Presupuesto de los Estados Unidos lo definió como \"aquel que muestra los objetivos para los que se requieren los fondos públicos, los costes de las actividades propuestas para alcanzar esos fines y los datos cuantitativos que miden las realizaciones y tareas ejecutadas\". ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS C) Presupuesto de ejecución: Su establecimiento exige tres fases consecutivas: clasificar los gastos funcionalmente por programas y actividades para indicar los objetivos previstos; establecer un sistema contable para el análisis de sus elementos y fijar los correspondientes indicadores de medida que nos permitan evaluar los objetivos alcanzados y el coste de los servicios prestados. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS C) Presupuesto de ejecución: Sin embargo, en su implantación se presentaron diversos problemas para calcular los costes de las actividades y medir los resultados, el sistema contable existente era inadecuado y no se establecían las formas alternativas de alcanzar los objetivos previstos, de modo que se pudieran elegir las más indicadas. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS C) Presupuesto de ejecución: El presupuesto de ejecución no solo permitía incrementar la eficacia de la Administración, sino también informaba a los ciudadanos sobre los beneficios que les iba a reportar la actividad pública. Asimismo, suponía un cambio de mentalidad directiva, responsabilizando a las unidades de la gestión de sus actividades, al tiempo que facilitaba al Parlamento el análisis y estudio de los presupuestos mejorando el debate presupuestario. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS C) Presupuesto de ejecución: No obstante, el modelo del presupuesto de ejecución adolecía de un defecto, al partir de tareas dadas y no analizar y evaluar sus posibles alternativas. Se trata de alcanzar la máxima eficacia al menor coste posible, pero probablemente otra alternativa, permitiría lograrlo de una forma más eficaz o con la misma eficacia pero con un menor consumo de recursos. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS D) Presupuesto planificado por programas (que examinamos seguidamente): Es un presupuesto que expone los programas de acción de cada unidad administrativa, indicando la dotación, expresada en unidades monetarias de los recursos humanos y materiales que cada uno precisa, así como los indicadores de los medios físicos sobre los que actúa y los indicadores de resultados, es decir, de los productos y servicios que se obtendrán del programa. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Parte de la hipótesis de que existen despilfarros en la utilización de los medios presupuestarios disponibles, exigiéndose a cada responsable la justificación de sus peticiones presupuestarias, probando la necesidad de todas las cantidades a gastar. Este tipo de presupuesto se encuadra dentro de las técnicas presupuestarias que pretenden una mayor eficacia en la asignación de los recursos y que, en síntesis, parte de dos premisas: - Despilfarro cero ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Parte de la hipótesis de que existen despilfarros en la utilización de los medios presupuestarios disponibles, exigiéndose a cada responsable la justificación de sus peticiones presupuestarias, probando la necesidad de todas las cantidades a gastar. Este tipo de presupuesto se encuadra dentro de las técnicas presupuestarias que pretenden una mayor eficacia en la asignación de los recursos y que, en síntesis, parte de dos premisas: - Replanteamiento de la asignación de los recursos a partir de cero. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: a) Unidad de decisión: unidad organizativa para la que se realiza un presupuesto y cuyo responsable adopta decisiones significativas sobre la naturaleza y cuantía de los gastos y actividades a realizar. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: b) Paquete de decisión: documento que contiene la información precisa para que los responsables puedan adoptar decisiones sobre los programas y actividades a desarrollar, y los recursos necesarios para financiarlas. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: c) Paquetes de decisión consolidados: son los paquetes elaborados a niveles crecientes de gestión. Pueden reflejar prioridades diferentes, nuevos programas o abandono de programas existentes. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: d) Ordenación de los paquetes de decisión: proceso en el que cada responsable ordena los programas a su cargo en orden decreciente de prioridad. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: e) Niveles de financiación de los programas: - Nivel mínimo: nivel de financiación por debajo del que no es factible continuar el programa o actividad, ya que no contribuye a alcanzar los objetivos ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: e) Niveles de financiación de los programas: - Nivel corriente: nivel que permite realizar las actividades del año anterior sin cambios importantes. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: En cuanto a la terminología utilizada en el presupuesto en base cero, cabe destacar: e) Niveles de financiación de los programas: - Nivel de mejora: niveles de financiación superiores al corriente, que permiten una superior intensidad en la consecución de uno o varios objetivos. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Se pueden distinguir las siguientes fases en la elaboración de este tipo de presupuestos: a. Identificación de Unidades de Decisión ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Se pueden distinguir las siguientes fases en la elaboración de este tipo de presupuestos: b. Identificación de Objetivos ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Se pueden distinguir las siguientes fases en la elaboración de este tipo de presupuestos: c. Análisis de Alternativas y Preparación de Paquetes de Decisión. ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Se pueden distinguir las siguientes fases en la elaboración de este tipo de presupuestos: d. Evaluación y Ordenación de los paquetes de decisión ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Se pueden distinguir las siguientes fases en la elaboración de este tipo de presupuestos: e. Preparación del Presupuesto ",
"MODELOS PRESUPUESTARIOS. PRESUPUESTO POR PROGRAMAS 6.1. MODELOS PRESUPUESTARIOS E) Presupuesto en base cero: Se pueden distinguir las siguientes fases en la elaboración de este tipo de presupuestos: f. Seguimiento y Control",

];


