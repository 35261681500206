export const vocabulario_a = [
  'ATRABILIARIO [[[irascible, irritable]]]',
  'ATREVIDO [[[resuelto, temerario, insolente]]]',
  'ATRIBULAR [[[apesadumbrar, afligir]]]',
  'ATRICIÓN [[[arrepentimiento, pesar]]]',
  'ATRIL [[[soporte, sostén]]]',
  'ATROFIA [[[raquitismo, tullimiento, consunción]]]',
  'ATRONAR [[[ensordecer, aturdir]]]',
  'AUGURAR [[[vaticinar, auspiciar]]]',
  'AUGUSTO [[[majestuoso, respetable]]]',
  'ÁUREO [[[dorado, rutilante, fulgurante]]]',
  'AUSPICIAR [[[predecir, augurar]]]',
  'AUSTRAL [[[meridional, antártico]]]',
  'AUTÓCTONO [[[oriundo, aborigen]]]',
  'AUTONOMÍA [[[independencia, emancipación]]]',
  'AVEJENTAR [[[aviejar, envejecer]]]',
  'AVENENCIA [[[conciliación, armonía, arreglo]]]',
  'AVERNO [[[abismo, infierno]]]',
  'AVEZADO [[[experimentado, habituado]]]',
  'ÁVIDO [[[ansioso, ambicioso, voraz]]]',
  'AVIESO [[[malo, perverso, torcido]]]',
  'AVITUALLAR [[[abastecer, proporcionar]]]',
  'AVIZORAR [[[acechar, atisbar]]]',
  'AXIOMÁTICO [[[evidente, indiscutible]]]',
  'AYUNAR [[[abstenerse, privarse]]]',
  'AZAROSO [[[expuesto, arriesgado, aventurado]]]',
  'AZORAR [[[asustar, espantar, turbarse]]]',
  'AZUZAR [[[achuchar, irritar, agriar]]]',
]
export const vocabulario_b = [
  'BABIECA [[[bobo, simple, papanatas]]]',
  'BACANAL [[[orgía, desorden]]]',
  'BACANTE [[[desocupada, lujuriosa, licenciosa]]]',
  'BACÍN [[[orinal]]]',
  'BACINETE [[[casco]]]',
  'BÁCULO [[[bastón, palo]]]',
  'BADULAQUE [[[tonto, tarugo, necio]]]',
  'BAGAJE [[[equipaje, acervo, patrimonio]]]',
  'BAGATELA [[[nimiedad, menudencia]]]',
  'BAJEL [[[buque, barco, navío]]]',
  'BAJÍO [[[banco, encalladero]]]',
  'BALA [[[fardo, paca, proyectil]]]',
  'BALADÍ [[[insignificante, superficial]]]',
  'BALADRONADA [[[bravata, fanfarronada]]]',
  'BALAUSTRADA [[[balcón, barandilla]]]',
  'BALBUCIR [[[mascullar, farfullar]]]',
  'BALDADO [[[tullido, impedido]]]',
  'BALDÍO [[[vano, infundado]]]',
  'BALDÓN [[[afrenta, agravio, oprobio]]]',
  'BALIZA [[[boya, indicación]]]',
  'BÁLSAMO [[[consuelo, alivio]]]',
  'BALUARTE [[[bastión, fortificación]]]',
  'BANAL [[[trivial, fútil]]]',
  'BANCAL [[[terreno, huerto]]]',
  'BANDERÍA [[[bando, parcialidad]]]',
  'BANDO [[[edicto, publicación]]]',
  'BAQUETA [[[taco, varilla]]]',
  'BAQUETEADO [[[acostumbrado, avezado]]]',
  'BAQUIANO [[[experto, versado]]]',
  'BARAJAR [[[mezclar, revolver]]]',
  'BARBARISMO [[[extranjerismo, incorrección]]]',
  'BARBECHO [[[erial, desierto]]]',
  'BARBIÁN [[[atrevido, desenvuelto]]]',
  'BARBOTAR [[[farfullar, mascullar, musitar]]]',
  'BARDO [[[poeta, vate]]]',
  'BARRENAR [[[taladrar, horadar]]]',
  'BARRUNTAR [[[presentir, sospechar]]]',
  'BÁRTULOS [[[herramientas, enseres, trastos]]]',
  'BASAR [[[fundar, asentar]]]',
  'BASTARDO [[[ilegítimo, espurio]]]',
  'BASTO [[[tosco, soez]]]',
  'BATAHOLA [[[alboroto, bulla]]]',
  'BAZAR [[[mercado, lonja]]]',
  'BEATITUD [[[bienaventuranza, felicidad, santidad]]]',
  'BEFAR [[[escarnecer, mofar]]]',
  'BELDAD [[[belleza, venus, hermosa]]]',
  'BELIGERANTE [[[contendiente, combatiente]]]',
  'BELLAQUERÍA [[[truhanería, bribonada]]]',
  'BELLO [[[hermoso, apuesto]]]',
  'BENEFICIOSO [[[útil, ventajoso]]]',
  'BENEPLÁCITO [[[aprobación, permiso]]]',
  'BENÉVOLO [[[benigno, bondadoso]]]',
  'BEODO [[[borracho, ebrio]]]',
  'BERMEJO [[[rubio, rojizo]]]',
  'BIELDO [[[horca, tridente]]]',
  'BISAGRA [[[gozne, charnela, pernio]]]',
  'BISAR [[[repetir]]]',
  'BISBISEAR [[[susurrar, musitar]]]',
  'BISOÑO [[[inexperto, novicio, novato]]]',
  'BITÁCORA [[[brújula, compás]]]',
  'BITUMINOSO [[[graso, pegajoso]]]',
  'BIZARRÍA [[[arrogancia, gallardía]]]',
  'BLANCO [[[albo, níveo, albino]]]',
  'BLASÓN [[[escudo, heráldica]]]',
  'BLASONAR [[[ostentar, alardear]]]',
  'BOBO [[[necio, cándido, pazguato]]]',
  'BOGAR [[[remar, navegar]]]',
  'BONANZA [[[prosperidad, tranquilidad]]]',
  'BONDADOSO [[[benévolo, altruista]]]',
  'BOQUETE [[[grieta, agujero]]]',
  'BORDÓN [[[bastón, cayado, estribillo]]]',
  'BOREAL [[[septentrional, nórdico, ártico]]]',
  'BORNEAR [[[torcer, ladear]]]',
  'BORRACHO [[[ebrio, alcohólico]]]',
  'BOSQUEJO [[[esquema, boceto, proyecto]]]',
  'BOTAR [[[arrojar, saltar]]]',
  'BOTICA [[[farmacia]]]',
  'BOYANTE [[[próspero, acomodado]]]',
  'BRAGADO [[[animoso, enérgico, decidido]]]',
  'BRAMANTE [[[cuerda, cordel]]]',
  'BRAMAR [[[aullar, vociferar]]]',
  'BRAVATA [[[amenaza, bravuconada]]]',
  'BRAVO [[[valiente, bizarro, esforzado]]]',
  'BREA [[[alquitrán, betún, resina]]]',
  'BREGA [[[lucha, ajetreo]]]',
  'BRETE [[[aprieto, trance]]]',
  'BREVE [[[efímero, conciso, lacónico]]]',
  'BROMEAR [[[chancearse, burlarse]]]',
  'BRONCA [[[gresca, altercado]]]',
  'BRONCO [[[áspero, hosco]]]',
  'BROZA [[[maleza, ramaje]]]',
  'BUCLE [[[rizo, mechón]]]',
  'BUCÓLICO [[[pastoril, campestre, poético]]]',
  'BULLA [[[algazara, algarabía]]]',
  'BULLIR [[[hervir, agitarse]]]',
  'BURDO [[[basto, tosco, zafio]]]',
  'BURIL [[[punzón, cincel]]]',
  'BURLA [[[mofa, befa, chanza]]]',
  'BURLESCO [[[festivo, chancero, jocoso]]]',
];

export const vocabulario_c = [
  'CABAL [[[justo, íntegro, ajustado]]]',
  'CÁBALA [[[suposición, conjetura]]]',
  'CABALÍSTICO [[[misterioso, oculto, esotérico]]]',
  'CABALLERIZA [[[cuadra, establo]]]',
  'CABEZO [[[cerro, colina]]]',
  'CABRIOLA [[[pirueta, brinco]]]',
  'CACHAZA [[[flema, calma]]]',
  'CACUMEN [[[agudeza, perspicacia, lucidez]]]',
  'CADALSO [[[patíbulo, suplicio]]]',
  'CADENCIA [[[ritmo, armonía]]]',
  'CADERA [[[cuadril, pelvis]]]',
  'CADUCO [[[decrépito, efímero, fugaz]]]',
  'CALA [[[bahía, prueba]]]',
  'CALAÑA [[[índole, calidad]]]',
  'CALAR [[[agujerear, conocer, mojar]]]',
  'CALÍGINE [[[calima, niebla]]]',
  'CALLISTA [[[pedicuro]]]',
  'CALMOSO [[[lento, parsimonioso]]]',
  'CAMÁNDULA [[[hipocresía, astucia]]]',
  'CAMBALACHE [[[trueque, cambio, trapicheo]]]',
  'CAMELAR [[[galantear, seducir]]]',
  'CAMELO [[[chasco, engaño]]]',
  'CAMORRA [[[pendencia, riña]]]',
  'CAMPANTE [[[ufano, satisfecho]]]',
  'CAMPANUDO [[[rimbombante, altisonante]]]',
  'CAMPECHANO [[[llano, sencillo, franco]]]',
  'CANANA [[[cartuchera, cinturón]]]',
  'CANCELAR [[[liquidar, anular]]]',
  'CANDENTE [[[ígneo, incandescente]]]',
  'CÁNDIDO [[[ingenuo, inocente]]]',
  'CANGUELO [[[miedo, temor]]]',
  'CANÍCULA [[[verano, calor]]]',
  'CANONJÍA [[[prebenda, privilegio]]]',
  'CANORO [[[sonoro, melodioso]]]',
  'CAÑADA [[[colada, cordel]]]',
  'CAÓTICO [[[confuso, anárquico]]]',
  'CAPCIOSO [[[engañoso, insidioso]]]',
  'CAPEAR [[[sortear, lidiar, resistir]]]',
  'CAPICÚA [[[inverso]]]',
  'CAPITULACIÓN [[[rendición, cesión, pacto]]]',
  'CAPITULAR [[[rendirse, pactar]]]',
  'CÁRCAVA [[[hoyo, foso, barranco]]]',
  'CARDINAL [[[principal, esencial]]]',
  'CAREAR [[[enfrentar, comparar]]]',
  'CARO [[[querido, amado, oneroso]]]',
  'CARPANTA [[[hambre, gazuza]]]',
  'CARTA [[[encíclica, misiva, naipe, minuta]]]',
  'CARTAPACIO [[[carpeta, cartera]]]',
  'CASACIÓN [[[anulación, derogación]]]',
  'CASADERO [[[núbil, púber, conjugable]]]',
  'CASTA [[[linaje, raza]]]',
  'CASTIZO [[[auténtico, natural]]]',
  'CATAR [[[probar, degustar]]]',
  'CATEGÓRICO [[[absoluto, concluyente]]]',
  'CATERVA [[[multitud, muchedumbre, garantía, fianza, precaución]]]',
  'CAUSTICO [[[corrosivo, mordaz]]]',
  'CAUTO [[[prudente, precavido, previsor]]]',
  'CAVILAR [[[pensar, meditar]]]',
  'CAYADO [[[báculo, bastón]]]',
  'CEBO [[[alimento, señuelo]]]',
  'CEDAZO [[[tamiz, criba]]]',
  'CEJAR [[[retroceder, ceder]]]',
  'CELADA [[[trampa, asechanza, emboscada]]]',
  'CELAR [[[velar, tapar, ocultar]]]',
  'CELERIDAD [[[prontitud, velocidad]]]',
  'CELIBATO [[[soltería]]]',
  'CELO [[[cuidado, apetito, lujuria]]]',
  'CENAGAL [[[fangal, lodazal, barrizal]]]',
  'CENIT [[[prosperidad, mediodía, culminación]]]',
  'CENOBIO [[[monasterio]]]',
  'CENOBITA [[[monje, solitario]]]',
  'CENSURAR [[[criticar, juzgar]]]',
  'CENTURIA [[[siglo]]]',
  'CEÑIR [[[cercar, ajustar, sujetar]]]',
  'CEÑO [[[gesto, entrecejo, enfado]]]',
  'CERCENAR [[[cortar, disminuir]]]',
  'CERCIORAR [[[confirmar, asegurar, certificar]]]',
  'CERNERSE [[[purificarse, mantenerse, cribarse]]]',
  'CERRIL [[[grosero, huraño, tosco]]]',
  'CERVIZ [[[cuello, pescuezo, cogote]]]',
  'CHÁCHARA [[[parloteo, charla, palique]]]',
  'CHACOTA [[[broma, chanza, zumba]]]',
  'CHAFAR [[[aplastar, apabullar]]]',
  'CHALÁN [[[tratante, traficante]]]',
  'CHAMBA [[[suerte, chiripa, casualidad]]]',
  'CHAMBELÁN [[[gentilhombre]]]',
  'CHANCEAR [[[bromear, burlarse]]]',
  'CHANTAJE [[[amenaza, coacción]]]',
  'CHARNELA [[[bisagra, gozne]]]',
  'CHASQUEAR [[[restallar, burlar, engañar]]]',
  'CHIQUILICUATRO [[[mequetrefe, zascandil]]]',
  'CHIRIBITA [[[chispa, pavesa]]]',
  'CHIRIGOTA [[[cuchufleta, chanza, broma]]]',
  'CHIRIPA [[[chamba, azar]]]',
  'CHUSCO [[[chistoso, gracioso, ocurrente]]]',
  'CICATERÍA [[[tacañería, mezquindad]]]',
  'CENAGAL [[[cenagal, fangal]]]',
  'CIENO [[[légamo, fango, lodo]]]',
  'CIFRA [[[guarismo, número]]]',
  'CINEGÉTICA [[[montería, caza]]]',
  'CÍNGARO [[[gitano, trashumante]]]',
  'CIRCUNDAR [[[rodear, cercar]]]',
  'CIRCUNSPECTO [[[discreto, reservado, mesurado]]]',
  'CITACIÓN [[[convocatoria, llamamiento]]]',
  'CIVILIDAD [[[sociabilidad, urbanidad, civismo]]]',
  'CIZALLA [[[cortadera, guillotina, tijeras]]]',
  'CIZAÑERO [[[chismoso, insidioso]]]',
  'CLARABOYA [[[tragaluz, ventana]]]',
  'CLEMENCIA [[[indulgencia, misericordia]]]',
  'CLEPTOMANÍA [[[robo]]]',
  'COADYUVAR [[[ayudar]]]',
  'COARTAR [[[estorbar, obligar]]]',
  'COERCER [[[contener, reprimir, coartar]]]',
  'COETÁNEO [[[contemporáneo]]]',
  'COHECHO [[[soborno]]]',
  'COLACIÓN [[[refracción, cotejo]]]',
  'COLECTA [[[cuestación, recaudación]]]',
  'COLEGIR [[[deducir, reunir]]]',
  'COLMO [[[saturación, exceso, abundancia]]]',
  'COLUMBRAR [[[divisar, distinguir, entrever]]]',
  'COMBA [[[curvatura, torcedura]]]',
  'CÓMICO [[[histrión, jocoso, bufón, hipócrita]]]',
  'COMINERÍA [[[menudencia, chisme, pequeñez]]]',
  'COMPACTO [[[macizo, duro, espeso]]]',
  'COMPARECER [[[acudir, presentarse]]]',
  'COMPARSA [[[acompañamiento, figurante]]]',
  'COMPELER [[[apremiar, obligar, constreñir]]]',
  'COMPILAR [[[reunir, coleccionar]]]',
  'COMPLICIDAD [[[connivencia, cooperación]]]',
  'COMPRESIÓN [[[opresión, presión]]]',
  'COMPRIMIR [[[apretar, prensar]]]',
  'COMPUNCIÓN [[[arrepentimiento, contrición]]]',
  'COMPUTAR [[[calcular, contar]]]',
  'CONATO [[[intento, empeño]]]',
  'CONCATENACIÓN [[[cadena, enlace]]]',
  'CONCAVIDAD [[[cavidad, hueco]]]',
  'CONCEDER [[[otorgar, conferir]]]',
  'CONCERNIR [[[atañer, tocar a, referirse a]]]',
  'CONCERTAR [[[pactar, estipular]]]',
  'CONCILIAR [[[armonizar, concordar]]]',
  'CONCISIÓN [[[laconismo, brevedad]]]',
  'CONCITAR [[[provocar]]]',
  'CONCLUIR [[[colegir, deducir]]]',
  'CONCOMITAR [[[acompañar, coincidir, ayudar]]]',
  'CONCULCAR [[[hollar, quebrantar, infringir]]]',
  'CONDONAR [[[otorgar, perdonar, remitir]]]',
  'CONFIANZA [[[seguridad, esperanza]]]',
  'CONFINAR [[[limitar, desterrar]]]',
  'CONFLAGRACIÓN [[[incendio, perturbación]]]',
  'CONGLOMERADO [[[mezcla, acumulación]]]',
  'CONGOJA [[[angustia, tormento, desmayo]]]',
  'CONGRATULACIÓN [[[felicitación, parabién]]]',
  'CONGRUENCIA [[[coherencia, conveniencia]]]',
  'CONJURA [[[complot, conspiración]]]',
  'CONJURO [[[petición, exorcismo]]]',
  'CONMINAR [[[amenazar, intimar, apremiar]]]',
  'CONSEJA [[[cuento, fábula, patraña]]]',
  'CONSPICUO [[[preeminente, ilustre, insigne]]]',
  'CONSTERNAR [[[abatir, desolar]]]',
  'CONSTREÑIMIENTO [[[coacción, apremio]]]',
  'CONSUETUDINARIO [[[usual, acostumbrado]]]',
  'CONSUNCIÓN [[[consumición, gasto]]]',
  'CONSUNCIÓN [[[extenuación, agotamiento]]]',
  'CONTEMPORÁNEO [[[coetáneo, actual, moderno]]]',
  'CONTENDER [[[pelear, luchar]]]',
  'CONTENTO [[[satisfecho, alegre]]]',
  'CONTESTABLE [[[discutible, rebatible]]]',
  'CONTIGÜIDAD [[[proximidad, inmediación]]]',
  'CONTORNEAR [[[rodear, circunvalar]]]',
  'CONTRACCIÓN [[[mengua, encogimiento, sinéresis]]]',
  'CONTRAFUERTE [[[espolón, pilar]]]',
  'CONTRAVENIR [[[conculcar, vulnerar, violar]]]',
  'CONTRISTADO [[[afligido, apenado]]]',
  'CONTRITO [[[arrepentido, compungido]]]',
  'CONTUBERNIO [[[confabulación, cohabitación]]]',
  'CONTUMAZ [[[obstinado, terco, pertinaz]]]',
  'CONTUSIÓN [[[golpe, magulladura]]]',
  'CONVENCIÓN [[[acuerdo, asamblea]]]',
  'COPIA [[[abundancia, reproducción]]]',
  'COPIOSO [[[abundante, exuberante]]]',
  'CORCOVA [[[joroba, giba]]]',
  'CORDIAL [[[amistoso, afectuoso]]]',
  'CORDURA [[[sensatez, juicio]]]',
  'CORREGIR [[[amonestar, educar, castigar]]]',
  'CORRERÍA [[[incursión, andanza]]]',
  'CORRETAJE [[[correduría, comisión]]]',
  'CORRIDO [[[avezado, avergonzado]]]',
  'CORROBORAR [[[confirmar, ratificar]]]',
  'CORSARIO [[[pirata, bucanero]]]',
  'CORTEJO [[[acompañamiento, comitiva]]]',
  'CORTESANO [[[palaciego, cortés]]]',
  'CORTIJO [[[alquería, granja]]]',
  'CORVO [[[curvado]]]',
  'COSMOS [[[universo, mundo]]]',
  'COSTADO [[[flanco, lado]]]',
  'COSTAL [[[saco]]]',
  'COSTALAZO [[[batacazo, golpe]]]',
  'COTEJAR [[[comparar]]]',
  'COYUNTURA [[[oportunidad, ocasión, tiempo]]]',
  'CRÁPULA [[[libertino, inmoralidad]]]',
  'CRASO [[[gordo, grueso]]]',
  'CREMA [[[diéresis, élite]]]',
  'CREMATÍSTICO [[[monetario, económico]]]',
  'CRESPO [[[rizado, retorcido, ensortijado]]]',
  'CRIADO [[[fámulo, factótum, mayordomo]]]',
  'CRIBA [[[tamiz, zaranda]]]',
  'CRIPTA [[[subterráneo, sepultura, bóveda]]]',
  'CRISPAR [[[contraer, encoger]]]',
  'CRUELDAD [[[ferocidad, truculencia]]]',
  'CUADRIL [[[anca, cadera]]]',
  'CUADRILONGO [[[rectangular]]]',
  'CUADRILLA [[[grupo, equipo]]]',
  'CUAJO [[[coágulo, fermento, pachorra, paciencia]]]',
  'CUARTEAR [[[partir, agrietarse]]]',
  'CUBIL [[[guarida, nido]]]',
  'CUCHIPANDA [[[francachela, comilona]]]',
  'CUCHUFLETA [[[chirigota, chanza, burla]]]',
  'CUCO [[[sagaz, astuto, taimado]]]',
  'CUENCA [[[concavidad, valle]]]',
  'CUENTO [[[fábula, chisme, narración, pretexto]]]',
  'CUESTIONAR [[[debatir, discutir]]]',
  'CUÉVANO [[[cesto, canasto]]]',
  'CUITA [[[aflicción, zozobra, desventura]]]',
  'CULTERANO [[[retórico, rebuscado, ampuloso]]]',
  'CUMPLIDAMENTE [[[ampliamente]]]',
  'CUMPLIMENTAR [[[felicitar, visitar, cumplir]]]',
  'CÚMULO [[[montón, infinidad, abundancia]]]',
  'CUNDIR [[[extenderse, desarrollarse]]]',
  'CURDA [[[borrachera]]]',
  'CUTÍCULA [[[* epidermis, piel, película]]]',
  'CUTRE [[[tacaño, miserable]]]',
];

export const vocabulario_d = [
  'DACTILAR [[[digital]]]',
  'DÁDIVA [[[regalo, óbolo, donativo]]]',
  'DADIVOSO [[[generoso, desinteresado]]]',
  'DAMNIFICAR [[[perjudicar, dañar, lesionar]]]',
  'DANTESCO [[[terrible, infernal]]]',
  'DATAR [[[fechar, abonar, acreditar]]]',
  'DECANTAR [[[ponderar, verter, inclinar]]]',
  'DECHADO [[[ejemplo, modelo, arquetipo]]]',
  'DECLAMAR [[[recitar,]]]',
  'DECLINACIÓN [[[decadencia, descenso]]]',
  'DECLINAR [[[rehusar, descender, apagarse]]]',
  'DECLIVE [[[decadencia, hundimiento]]]',
  'DECREPITUD [[[decadencia, declive, vetustez]]]',
  'DECÚBITO [[[horizontal, tendido, yacente]]]',
  'DECURSO [[[transcurso, discurrir]]]',
  'DÉDALO [[[laberinto, maraña, lío]]]',
  'DEDUCIR [[[inferir, suponer]]]',
  'DEFECCIÓN [[[traición, deserción, abandono]]]',
  'DEFENESTRAR [[[destituir, expulsar]]]',
  'DEFERENCIA [[[consideración, respeto, atención]]]',
  'DEFERIR [[[acceder, respetar]]]',
  'DEFICIENCIA [[[debilidad, defecto, falta]]]',
  'DÉFICIT [[[pérdida, descubierto]]]',
  'DEFLAGRAR [[[arder, quemar]]]',
  'DEGENERADO [[[deteriorado, corrompido, vicioso]]]',
  'DEGLUTIR [[[tragar, digerir]]]',
  'DEGRADAR [[[destituir, deponer, postergar]]]',
  'DEIFICAR [[[divinizar]]]',
  'DELACIÓN [[[denuncia, acusación]]]',
  'DELETÉREO [[[mortal, mortífero, venenoso]]]',
  'DELEZNABLE [[[inconsistente, frágil, delicado]]]',
  'DELIBERACIÓN [[[meditación, decisión, discusión]]]',
  'DELICADO [[[tierno, débil, atento]]]',
  'DELIQUIO [[[desmayo, desfallecimiento, éxtasis]]]',
  'DELUSORIO [[[engañoso, falso, ficticio]]]',
  'DEMANDAR [[[pedir, solicitar, reclamar]]]',
  'DEMARCAR [[[delimitar, señalar]]]',
  'DEMENCIA [[[locura, vesania]]]',
  'DEMERITO [[[desmerecimiento, descrédito]]]',
  'DEMORA [[[dilación, mora, tardanza]]]',
  'DENGOSO [[[melindroso, remilgado]]]',
  'DENODADO [[[intrépido, esforzado, resuelto]]]',
  'DENOSTAR [[[injuriar, ultrajar, vilipendiar]]]',
  'DENOTAR [[[indicar, advertir]]]',
  'DENTELLADA [[[mordedura]]]',
  'DENUEDO [[[valor, ánimo, brío]]]',
  'DEONTOLOGÍA [[[ética, moral]]]',
  'DEPARTIR [[[conversar, hablar]]]',
  'DEPAUPERAR [[[empobrecer, debilitar, adelgazar]]]',
  'DEPLORAR [[[lamentar, sentir, llorar]]]',

  'DEPONER [[[destituir, destronar, declarar]]]',
  'DEPRAVAR [[[viciar, corromper, envilecer]]]',
  'DEPRECIACIÓN [[[robo, saqueo, devaluación]]]',
  'DERRUIR [[[demoler, derribar]]]',
  'DESABRIDO [[[insulso, soso, insípido]]]',
  'DESAFORADO [[[desmedido, desordenado]]]',
  'DESAHUCIAR [[[desesperanzar, expulsar]]]',
  'DESAHUCIO [[[condena, despido, abandono]]]',
  'DESAIRE [[[desdén, desprecio, desatención]]]',
  'DESALIÑO [[[descuido, desidia, suciedad]]]',
  'DESALMADO [[[cruel, inhumano, malo]]]',
  'DESAMPARO [[[abandono, soledad]]]',
  'DESATINADO [[[desacertado, disparatado]]]',
  'DESAZÓN [[[insipidez, malestar, inquietud]]]',
  'DESBASTAR [[[pulir, limar, suavizar, instruir]]]',
  'DESCIFRAR [[[esclarecer, desentrañar]]]',
  'DESCOCO [[[descaro]]]',
  'DESCOLLANTE [[[destacado, distinguido]]]',
  'DESCOLORIDO [[[lívido, pálido]]]',
  'DESCUENTO [[[rebaja, reducción]]]',
  'DESDECIR [[[retractarse, desmentir, abjurar]]]',
  'DESDÉN [[[desprecio, displicencia, denuesto]]]',
  'DESDEÑOSO [[[arrogante, esquivo]]]',
  'DESDICHADO [[[infeliz, desgraciado]]]',
  'DESDORO [[[mancilla, mancha, descrédito]]]',
  'DESERCIÓN [[[huida, abandono]]]',
  'DESGANA [[[anorexia, abulia, displicencia]]]',
  'DESGREÑAR [[[descomponer, despeinar]]]',
  'DESHARRAPADO [[[andrajoso, harapiento]]]',
  'DESHILVANADO [[[inconexo, incoherente]]]',
  'DESIDIA [[[descuido, dejadez, indolencia]]]',
  'DESIGNIO [[[proyecto, propósito, plan]]]',
  'DESINENCIA [[[terminación]]]',
  'DESISTIR [[[renunciar, cejar, cesar]]]',
  'DESLEÍR [[[disolver, deshacer]]]',
  'DESLENGUADO [[[desvergonzado, descarado]]]',
  'DESLINDAR [[[delimitar, demarcar]]]',
  'DESLIZ [[[deslizamiento, falta, indiscreción]]]',
  'DESLUSTRAR [[[empañar, deslucir]]]',
  'DESMADEJADO [[[decaído, abatido, débil]]]',
  'DESMÁN [[[exceso, tropelía]]]',
  'DESMANDADO [[[indócil, desobediente, insolente]]]',
  'DESMAÑADO [[[torpe, inútil]]]',
  'DESMENUZAR [[[dividir, examinar, triturar]]]',
  'DESOLAR [[[destruir, devastar, afligir]]]',
  'DESPARPAJO [[[desenvoltura, desenfado]]]',
  'DESPAVORIDO [[[aterrado, espantado]]]',
  'DESPECHO [[[enfado, rencor]]]',
  'DESPECTIVO [[[desdeñoso, despreciativo]]]',
  'DESPEÑADERO [[[precipicio, sima]]]',
  'DESPERDIGAR [[[esparcir, diseminar]]]',

  'DESPOJO [[[residuo, botín]]]',
  'DÉSPOTA [[[tirano, opresor]]]',
  'DESPREOCUPADO [[[indiferente, tranquilo]]]',
  'DESPUNTAR [[[embotar, amanecer, distinguido]]]',
  'DESQUITE [[[resarcimiento, venganza]]]',
  'DESTELLO [[[resplandor, brillo, chispazo]]]',
  'DESTIERRO [[[exilio, expulsión, confinación]]]',
  'DESTREZA [[[maestría, habilidad]]]',
  'DESVAÍDO [[[pálido, descolorido]]]',
  'DESVELAR [[[descubrir, revelar, esmerarse]]]',
  'DETENER [[[demorar, parar, atajar]]]',
  'DETENTAR [[[retener (lo que no pertenece)]]]',
  'DETESTAR [[[aborrecer, abominar]]]',
  'DETONACIÓN [[[estallido, estampido]]]',
  'DETRACTAR [[[denigrar, desacreditar]]]',
  'DETRIMENTO [[[perjuicio, deterioro]]]',
  'DEUDO [[[pariente, familiar, allegado]]]',
  'DEVASTAR [[[desolar, destruir]]]',
  'DIÁFANO [[[claro, transparente, cristalino]]]',
  'DIARIO [[[cotidiano, periódico,]]]',
  'DIATRIBA [[[injuria, sátira, invectiva]]]',
  'DICTAMEN [[[informe, juicio]]]',
  'DICTERIO [[[improperio, insulto, denuesto]]]',
  'DIFAMAR [[[ultrajar, infamar, calumniar]]]',
  'DIFERIR [[[retardar, dilatar, demorar]]]',
  'DILACIÓN [[[demora, retraso]]]',
  'DILAPIDAR [[[disipar, derrochar, malgastar]]]',
  'DILECTO [[[querido, amado]]]',
  'DILIGENTE [[[rápido, presto, cumplidor]]]',
  'DILUCIDAR [[[explicar, aclarar]]]',
  'DINÁMICO [[[activo, vivo, diligente]]]',
  'DIQUE [[[malecón, puerto, muelle,]]]',
  'DIRIMIR [[[decidir, resolver]]]',
  'DISCERNIR [[[discriminar, diferenciar]]]',
  'DÍSCOLO [[[desobediente, indisciplinado]]]',
  'DISCRETO [[[prudente, ponderado]]]',
  'DISECCIÓN [[[división, corte]]]',
  'DISENSIÓN [[[desacuerdo, disputa, discordia]]]',
  'DISIDENTE [[[discorde, discrepante, cismático]]]',
  'DISLATE [[[disparate]]]',
  'DISLOCAR [[[desencajar, luxar]]]',
  'DISOLUTO [[[corrupto, licencioso, libertino]]]',
  'DISONAR [[[desentonar, discrepar, desentonar]]]',
  'DISPENDIO [[[derroche, despilfarro]]]',
  'DISPENSAR [[[otorgar, eximir, liberar]]]',
  'DISPLICENTE [[[desabrido, indiferente]]]',
  'DISPUTAR [[[discutir, cuestionar]]]',
  'DISQUISICIÓN [[[examen, divagación, digresión]]]',
  'DISTORSIÓN [[[deformación, falseamiento]]]',
  'DISUADIR [[[desaconsejar, desanimar]]]',
  'DISYUNCIÓN [[[separación, división]]]',

  'DISYUNTIVA [[[alternativa, dilema]]]',
  'DIVIESO [[[forúnculo, grano]]]',
  'DIVULGAR [[[difundir, publicar, murmurar]]]',
  'DOBLEZ [[[duplicidad, hipocresía, pliegue]]]',
  'DOGMÁTICO [[[pedante, ortodoxo, intransigente]]]',
  'DOLIENTE [[[afligido, abatido]]]',
  'DOMEÑAR [[[dominar, someter, reducir]]]',
  'DOMINIO [[[subyugación, potestad, hegemonía]]]',
  'DON [[[regalo, dádiva, presente]]]',
  'DONACIÓN [[[legado, donativo]]]',
  'DONOSO [[[gracioso, irónico]]]',
  'DRÁSTICO [[[purgante, enérgico, tajante]]]',
  'DÚCTIL [[[blando, acomodaticio, maleable]]]',
  'DUDOSO [[[incierto, vago, inseguro]]]',
  'DUPLICAR [[[replicar, calcar, doblar]]]',
  'DUPLICIDAD [[[hipocresía, doblez]]]',
  'DURADERO [[[eterno, inmortal]]]',
];

export const vocabulario_e = [
  'EBRIO [[[borracho, beodo]]]',
  'EBÚRNEO [[[marfileño]]]',
  'ECLÉTICO [[[conciliador, comedido]]]',
  'ECLIPSAR [[[oscurecer, desaparecer]]]',
  'ECLOSIÓN [[[aparición, nacimiento]]]',
  'ECUÁNIME [[[imparcial, ponderado, sereno]]]',
  'ECUESTRE [[[caballar, equino, hípico]]]',
  'ECUMÉNICO [[[universal]]]',
  'EDÉN [[[paraíso]]]',
  'EDICTO [[[mandato, decreto]]]',
  'EDIL [[[concejal, regidor]]]',
  'EDULCORAR [[[endulzar]]]',
  'EFEBO [[[mancebo, adolescente]]]',
  'EFERVESCENCIA [[[agitación, inquietud]]]',
  'EFIGIE [[[imagen, figura, representación]]]',
  'EFÍMERO [[[corto, fugaz, pasajero]]]',
  'EFLUVIO [[[irradiación, emanación]]]',
  'EFUGIO [[[evasiva, salida, subterfugio]]]',
  'EFUSIÓN [[[expansión, desahogo, cariño]]]',
  'ÉGIDA [[[protección, amparo, patrocinio]]]',
  'EGÓLATRA [[[egoísta, narciso]]]',
  'ELEGANTE [[[fino, distinguido, selecto]]]',
  'ELEGÍACO [[[lastimero, triste]]]',
  'ELENCO [[[catálogo, índice, repertorio]]]',
  'ELIDIR [[[frustrar, suprimir]]]',
  'ELIXIR [[[remedio, bálsamo]]]',
  'ELUCIDAR [[[aclarar, explicar]]]',
  'ELUDIR [[[esquivar, rehuir, soslayar]]]',
  'EMANCIPAR [[[libertar, independizar]]]',
  'EMBAJADA [[[mensaje, recado, delegación]]]',
  'EMBARRAR [[[enlodar, manchar]]]',
  'EMBATE [[[acometida, embestida]]]',
  'EMBAUCAR [[[engañar, seducir]]]',
  'EMBEBER [[[absorber, impregnar]]]',

  'EMBELECO [[[embuste, engaño, carantoña]]]',
  'EMBESTIDA [[[embate, ataque]]]',
  'EMBOCAR [[[tragar, engullir]]]',
  'ÉMBOLO [[[pistón, trompo]]]',
  'EMBOSCADA [[[celada, asechanza]]]',
  'EMBOZAR [[[encubrir, ocultar, disfrazar]]]',
  'EMINENTE [[[distinguido, insigne, célebre]]]',
  'EMOLIENTE [[[ablandativo, suavizante]]]',
  'EMOLUMENTO [[[estipendio, honorario, sueldo]]]',
  'EMPALIZADA [[[estacada, barrera, seto]]]',
  'EMPAQUE [[[seriedad, estiramiento]]]',
  'EMPECER [[[dañar, ofender, impedir]]]',
  'EMPECINARSE [[[aferrarse, obstinarse]]]',
  'EMPELLÓN [[[empujón, envite]]]',
  'EMPEÑO [[[pignoración, anhelo, tentativa]]]',
  'EMPÍRICO [[[experimental, práctico]]]',
  'EMPLASTO [[[parche, cataplasma]]]',
  'EMPONZOÑAR [[[envenenar]]]',
  'EMULACIÓN [[[rivalidad, competencia]]]',
  'ÉMULO [[[rival, competidor]]]',
  'EMULSIÓN [[[mezcla, solución]]]',
  'ENAJENACIÓN [[[locura, éxtasis, venta]]]',
  'ENAJENAR [[[vender, ceder, arrobar]]]',
  'ENARBOLAR [[[izar, levantar]]]',
  'ENCARECER [[[acrecentar, subir]]]',
  'ENCARTAR [[[proscribir, encausar]]]',
  'ENCAUSAR [[[procesar, encartar, enjuiciar]]]',
  'ENCOMENDAR [[[encargar, confiar]]]',
  'ENCOMIO [[[elogio, loa, alabanza]]]',
  'ENCONAR [[[infectar, irritarse, ensañarse]]]',
  'ENCONO [[[rencor, animadversión]]]',
  'ENCRESPAR [[[ensortijar, rizar]]]',
  'ENDILGAR [[[endosar, encajar, espetar]]]',
  'ENEMIGO [[[adversario, rival]]]',
  'ENERVAR [[[debilitar, abatir, desanimar]]]',
  'ÉNFASIS [[[acento, intensidad, redundancia]]]',
  'ENFÁTICO [[[hinchado, afectado, insistente]]]',
  'ENGARZAR [[[enlazar, trabar, encadenar]]]',
  'ENGOLADO [[[presuntuoso, altisonante]]]',
  'ENGOLFARSE [[[enfrascarse, absorberse]]]',
  'ENGREÍDO [[[pedante, soberbio, orgulloso]]]',
  'ENHIESTO [[[tieso, erguido]]]',
  'ENJALBEGAR [[[encalar, blanquear]]]',
  'ENJUGAR [[[secar, cancelar]]]',
  'ENJUNDIA [[[sustancia, meollo, núcleo]]]',
  'ENJUTO [[[flaco, magro, delgado]]]',
  'ENMARAÑADO [[[confuso, hirsuto]]]',
  'ENMASCARAR [[[encubrir, disfrazar]]]',
  'ENOJADO [[[enfadado, colérico]]]',
  'ENREDO [[[maraña, engaño]]]',
  'ENRISCADO [[[peñascoso, rocoso]]]',

  'ENSEÑA [[[bandera, señal]]]',
  'ENTECO [[[enfermizo, débil, enclenque]]]',
  'ENTIBAR [[[apuntalar, asegurar, apoyar]]]',
  'ENTONAR [[[tonificar, vigorizar]]]',
  'ENTUERTO [[[desaguisado, agravio]]]',
  'ENVÉS [[[revés, reverso]]]',
  'ENVOLVER [[[rodear, acorralar]]]',
  'EPÍLOGO [[[conclusión, recapitulación, fin]]]',
  'EPÍSTOLA [[[carta]]]',
  'EPITAFIO [[[inscripción, lápida]]]',
  'EPÍTETO [[[adjetivo, calificativo]]]',
  'EPÍTOME [[[compendio, sumario, resumen]]]',
  'EQUÍVOCO [[[ambiguo, imprecisión, anfibología]]]',
  'ERA [[[período, época, hégira]]]',
  'ERARIO [[[hacienda, fisco]]]',
  'EREMITA [[[ermitaño, anacoreta]]]',
  'ERIAL [[[yermo, improductivo]]]',
  'ERIZADO [[[hirsuto, ensortijado]]]',
  'ERRAR [[[equivocarse, vagar, fallar]]]',
  'ERRÁTICO [[[vagabundo]]]',
  'ERRÓNEO [[[falso, equivocado]]]',
  'ERUDITO [[[docto, entendido, ínclito]]]',
  'ESBOZO [[[bosquejo, esquema]]]',
  'ESCARDAR [[[desherbar, limpiar]]]',
  'ESCARNIO [[[burla, mofa, befa]]]',
  'ESCARPADO [[[abrupto, escabroso]]]',
  'ESCÉPTICO [[[incrédulo, desilusionado]]]',
  'ESCINDIR [[[separar, dividir]]]',
  'ESCISIÓN [[[partición, desgarro]]]',
  'ESCOGIDO [[[electo, elegido]]]',
  'ESCUÁLIDO [[[flaco, macilento]]]',
  'ESENCIA [[[quid, enjundia]]]',
  'ESENCIAL [[[indispensable, necesario]]]',
  'ESOTÉRICO [[[raro, oculto, misterioso]]]',
  'ESPECIOSO [[[engañoso, aparente, falso]]]',
  'ESPECTRO [[[imagen, fantasma, aparición]]]',
  'ESPECULAR [[[meditar, traficar]]]',
  'ESPEJUELO [[[ilusión, señuelo, anteojos]]]',
  'ESPIRACIÓN [[[expulsión, exhalación, suspiro]]]',
  'ESPLÉNDIDO [[[magnífico, rutilante, fastuoso]]]',
  'ESPONTÁNEO [[[natural, franco, afable]]]',
  'ESPURIO [[[falso, bastardo, adulterado, ilegítimo]]]',
  'ESPUTO [[[expectoración, salivazo]]]',
  'ESQUILAR [[[trasquilar, cortar]]]',
  'ESQUILMAR [[[agotar una fuente de riqueza]]]',
  'ESQUIVEZ [[[aspereza, desdén]]]',
  'ESQUIVO [[[huraño, arisco, huidizo]]]',
  'ESTACADA [[[empalizada, valla, cerca]]]',
  'ESTADÍA [[[detención, estancia, permanencia]]]',
  'ESTANTIGUA [[[mamarracho, fantasma]]]',
  'ESTENTÓREO [[[fuerte, retumbante, resonante]]]',

  'ESTIGMA [[[marca, señal, huella]]]',
  'ESTIPENDIO [[[sueldo, remuneración]]]',
  'ESTIPULAR [[[convenir, acordar, concertar]]]',
  'ESTIRPE [[[linaje, ascendencia]]]',
  'ESTOICO [[[impasible, imperturbable]]]',
  'ESTOLIDEZ [[[estupidez, estulticia]]]',
  'ESTRAMBÓTICO [[[excéntrico, estrafalario]]]',
  'ESTRATAGEMA [[[ardid, treta, asechanza]]]',
  'ESTRIBOR [[[lado derecho]]]',
  'ESTRIDENTE [[[desapacible, ruidoso]]]',
  'ESTRO [[[inspiración, creación]]]',
  'ESTROPAJOSO [[[desaseado, andrajoso, fibroso]]]',
  'ESTRUENDO [[[estrépito, trueno, estridencia]]]',
  'ESTUCO [[[escayola, yeso]]]',
  'ESTULTICIA [[[necedad]]]',
  'ESTULTO [[[necio]]]',
  'ESTUPEFACCIÓN [[[estupor, asombro]]]',
  'ESTUPEFACIENTE [[[narcótico, soporífero]]]',
  'ESTUPOR [[[asombro, estupefacción]]]',
  'ETERNO [[[perpetuo, sempiterno]]]',
  'ÉTICO [[[moral, casuista]]]',
  'ETIMOLOGÍA [[[procedencia, raíz, derivación]]]',
  'ETIOLOGÍA [[[causa, razón, motivo]]]',
  'ETNOGRAFÍA [[[raza]]]',
  'EUFEMISMO [[[atenuación, indirecta, rodeo]]]',
  'EUFÓNICO [[[armonioso]]]',
  'EUPÉPTICO [[[digestivo, estomacal]]]',
  'EVACUAR [[[desocupar, desempeñar]]]',
  'EVALUAR [[[estimar, valorar]]]',
  'EVAPORAR [[[vaporizar, volatilizar, desaparecer]]]',
  'EVASIVA [[[pretexto, excusa, subterfugio]]]',
  'EVENTO [[[suceso, acontecimiento]]]',
  'EVENTUAL [[[casual, fortuito, posible]]]',
  'EVIDENTE [[[claro, axiomático]]]',
  'EVOCAR [[[recordar, rememorar]]]',
  'EXABRUPTO [[[brusquedad, grosería]]]',
  'EXACERBAR [[[enojar, exasperar, irritar]]]',
  'EXANGÜE [[[desangrado, muerto]]]',
  'EXÁNIME [[[yerto, agotado, desmayado]]]',
  'EXASPERAR [[[enojar, exacerbar, irritar]]]',
  'EXCELENTE [[[perfecto, superior, óptimo]]]',
  'EXCELSO [[[eminente, ilustre, alto, eminente]]]',
  'EXCÉNTRICO [[[raro, extravagante]]]',
  'EXCRECENCIA [[[bulto, verruga, grano]]]',
  'EXCULPAR [[[perdonar, absolver]]]',
  'EXCUSAR [[[disculpar, perdonar]]]',
  'EXECRAR [[[reprobar, censurar, condenar]]]',
  'EXÉGESIS [[[explicación, interpretación]]]',
  'EXENCIÓN [[[franquicia, excepción]]]',
  'EXENTO [[[libre, franco]]]',
  'EXEQUIAS [[[funerales]]]',

  'EXHALACIÓN [[[rayo, emanación, suspiro]]]',
  'EXHALAR [[[emanar, despedir]]]',
  'EXHAUSTO [[[agotado, débil]]]',
  'EXHUMAR [[[desenterrar, recordar]]]',
  'EXIGUO [[[insuficiente, escaso]]]',
  'EXILIAR [[[desterrar, emigrar, expatriar]]]',
  'EXIMIO [[[ilustre, egregio, conspicuo]]]',
  'ÉXODO [[[emigración, expulsión]]]',
  'EXONERAR [[[aliviar, descargar]]]',
  'EXORDIO [[[introducción, prefacio, preámbulo]]]',
  'EXORNAR [[[embellecer, hermosear, adornar]]]',
  'EXOTÉRICO [[[común, accesible]]]',
  'EXPEDITIVO [[[diligente, rápido]]]',
  'EXPEDITO [[[despejado, libre]]]',
  'EXPELER [[[arrojar, lanzar]]]',
  'EXPIAR [[[reparar, purgar, pagar]]]',
  'EXPLETIVO [[[enfático]]]',
  'EXPOLIAR [[[despojar, quitar, robar]]]',
  'EXPLÍCITO [[[evidente, palmario, formal]]]',
  'EXPRESO [[[explícito, deliberado]]]',
  'EXPUESTO [[[peligroso, arriesgado]]]',
  'EXPUGNAR [[[conquistar, apoderarse]]]',
  'EXPURGAR [[[depurar, purificar]]]',
  'EXTASIAR [[[cautivar, embelesar, asombrar]]]',
  'EXTEMPORÁNEO [[[intempestivo, inoportuno]]]',
  'EXTINGUIR [[[apagar, prescribir]]]',
  'EXTORSIONAR [[[usurpar, despojar, trastornar]]]',
  'EXTRACTAR [[[resumir, compendiar]]]',
  'EXTRAÑAR [[[deportar, sorprender]]]',
  'EXTRÍNSECO [[[superficial, accidental]]]',
  'EXUBERANTE [[[ubérrimo, profuso, abundante]]]',
  'EXVOTO [[[s: voto, ofrenda]]]',
];

export const vocabulario_f = [
  'FÁBULA [[[apólogo, parábola, ficción]]]',
  'FACA [[[cuchillo, navaja]]]',
  'FACCIÓN [[[banda, pandilla]]]',
  'FACCIOSO [[[sedicioso, rebelde]]]',
  'FACETA [[[aspecto, cara]]]',
  'FACINEROSO [[[malhechor, malvado]]]',
  'FACSÍMILE [[[imitación, reproducción, copia]]]',
  'FACTIBLE [[[posible, viable]]]',
  'FACTÓTUM [[[criado, recadero, testaferro]]]',
  'FACULTAD [[[potestad, capacidad]]]',
  'FACULTAR [[[autorizar, delegar]]]',
  'FACULTATIVO [[[opcional, potestativo, voluntario]]]',
  'FALACIA [[[mentira, falsedad, bulo]]]',
  'FALIBLE [[[erróneo, inexacto, humano]]]',
  'FALLA [[[hoguera, rotura, grieta]]]',
  'FAMA [[[reputación, celebridad]]]',
  'FÁMULA [[[criada, sirvienta]]]',
  'FANÁTICO [[[exaltado, intolerante]]]',
  'FANFARRIA [[[bravata, jactancia]]]',

  'FARFULLAR [[[barbotar, mascullar]]]',
  'FARMACOPEA [[[recetario, farmacia]]]',
  'FARRAGOSO [[[confuso, desordenado]]]',
  'FASTIDIAR [[[molestar, hartar,]]]',
  'FASTUOSO [[[ostentoso, suntuoso]]]',
  'FATAL [[[predestinado, inevitable]]]',
  'FATUO [[[necio, petulante]]]',
  'FAUSTO [[[pompa, suntuosidad, ostentación]]]',
  'FECHAR [[[datar, calendar]]]',
  'FEHACIENTE [[[indudable, indiscutible]]]',
  'FELONÍA [[[traición, deslealtad]]]',
  'FERACIDAD [[[fertilidad, fecundidad]]]',
  'FERAZ [[[fértil, fecundo, ubérrimo]]]',
  'FETICHE [[[ídolo]]]',
  'FETIDEZ [[[hediondez, hedor, pestilencia]]]',
  'FEUDATARIO [[[tributario, vasallo]]]',
  'FIADOR [[[garante, avalista]]]',
  'FIGÓN [[[bodegón, taberna]]]',
  'FILÁNTROPO [[[altruista, benefactor]]]',
  'FILFA [[[mentira, patraña, bulo]]]',
  'FILIBUSTERO [[[bucanero, pirata]]]',
  'FILÍPICA [[[invectiva, reprensión, censura]]]',
  'FINIQUITAR [[[cancelar, acabar]]]',
  'FISGAR [[[indagar, husmear]]]',
  'FISIÓN [[[escisión, desintegración, división]]]',
  'FISURA [[[hendidura, grieta, raja]]]',
  'FLACO [[[delgado, enjuto, magro, canijo]]]',
  'FLAGELAR [[[azotar, censurar]]]',
  'FLAGRANTE [[[evidente, claro]]]',
  'FLAGRAR [[[arder]]]',
  'FLAMANTE [[[resplandeciente, nuevo, reciente]]]',
  'FLAMEAR [[[ondear, arder, desinfectar]]]',
  'FLAMENCO [[[robusto, chulo, gitano]]]',
  'FLAMÍGERO [[[refulgente, brillante]]]',
  'FLANCO [[[costado, lado, cadera]]]',
  'FLEMA [[[apatía, calma, lentitud]]]',
  'FLEXIBLE [[[dúctil, elástico, blando, complaciente]]]',
  'FLOREAR [[[adornar, piropear,]]]',
  'FLORESTA [[[bosque, selva, fronda]]]',
  'FLORILEGIO [[[antología, selección]]]',
  'FLUCTUAR [[[oscilar, vacilar, ondear]]]',
  'FLUIDO [[[líquido, fácil, vapor]]]',
  'FOBIA [[[miedo, temor, aversión]]]',
  'FOGOSO [[[vehemente, impetuoso, ardiente]]]',
  'FOGUEADO [[[avezado, acostumbrado]]]',
  'FONTANA [[[fuente, manantial]]]',
  'FORAJIDO [[[salteador, malhechor, bandido]]]',
  'FORNIDO [[[robusto, recio]]]',
  'FORRAJE [[[pasto, hierba]]]',
  'FORTUITO [[[inopinado, casual, imprevisto]]]',
  'FORÚNCULO [[[tumor, grano, bulto]]]',

  'FRACCIÓN [[[parte, fragmento, cociente]]]',
  'FRAGANTE [[[aromático, oloroso,]]]',
  'FRAGOR [[[estrépito, estruendo, ruido]]]',
  'FRANCACHELA [[[cuchipanda, comilona]]]',
  'FRAUDE [[[engaño, malversación]]]',
  'FRENESÍ [[[delirio, locura, furia]]]',
  'FRICCIÓN [[[frotación, masaje]]]',
  'FRISO [[[zócalo, moldura]]]',
  'FRÍVOLO [[[ligero, liviano, superficial]]]',
  'FRONDOSIDAD [[[espesura, hojarasca]]]',
  'FRUCTÍFERO [[[productivo, fértil]]]',
  'FRUGALIDAD [[[sobriedad, mesura, continencia]]]',
  'FRUSLERÍA [[[bagatela, pequeñez, trivialidad]]]',
  'FUGAZ [[[huidizo, efímero, caduco]]]',
  'FULGOR [[[brillo, resplandor]]]',
  'FULGURAR [[[brillar, resplandecer]]]',
  'FULLERO [[[tramposo, tahúr]]]',
  'FUNÁMBULO [[[equilibrista]]]',
  'FUNDIR [[[derretir, liquidar, moldear]]]',
  'FURIBUNDO [[[colérico, furioso]]]',
  'FURTIVO [[[oculto, escondido]]]',
  'FUSTA [[[látigo]]]',
  'FÚTIL [[[baladí, insignificante, nimio]]]',
];

export const vocabulario_g = [
  'GABACHO [[[francés, galo]]]',
  'GABÁN [[[abrigo, gabardina]]]',
  'GABARRA [[[embarcación, barca]]]',
  'GABELA [[[tributo, impuesto, gravamen]]]',
  'GALA [[[ornato, adorno]]]',
  'GALANTE [[[caballeroso, atento]]]',
  'GALANURA [[[gracia, gallardía]]]',
  'GALBANA [[[pereza]]]',
  'GALENO [[[médico]]]',
  'GALLARDO [[[arrogante, apuesto, bizarro]]]',
  'GALLEAR [[[envalentonarse, presumir]]]',
  'GALOPÍN [[[pícaro, bribón, pillo]]]',
  'GANDUL [[[holgazán, vago, haragán]]]',
  'GARANTE [[[fiador, garantizador]]]',
  'GARANTÍA [[[seguridad, fianza, prenda]]]',
  'GARBO [[[donaire, gracia, bizarría]]]',
  'GARFIO [[[gancho, arpón]]]',
  'GARITO [[[timba, burdel]]]',
  'GARRAFAL [[[enorme, monumental]]]',
  'GARROCHA [[[pica, vara, puya]]]',
  'GARRUCHA [[[polea, roldana]]]',
  'GÁRRULO [[[vulgar, ramplón, parlanchín]]]',
  'GATUPERIO [[[embrollo, intriga, batiburrillo]]]',
  'GAVILLA [[[haz, manojo]]]',
  'GAYO [[[alegre, vistoso]]]',
  'GAZAPO [[[error, conejo, yerro]]]',
  'GAZUZA [[[hambre]]]',
  'GÉLIDO [[[helado, frío]]]',

  'GEMIR [[[clamar, plañir, suplicar]]]',
  'GENEALOGÍA [[[ascendencia, estirpe, abolengo]]]',
  'GENEROSO [[[liberal, dadivoso, prodigo]]]',
  'GÉNESIS [[[origen, principio]]]',
  'GENTIL [[[pagano, apuesto]]]',
  'GERIFALTE [[[mandón, jefe, mandamás]]]',
  'GESTA [[[hazaña, proeza]]]',
  'GIBA [[[joroba, corcova]]]',
  'GLASEAR [[[abrillantar]]]',
  'GLAUCO [[[verde claro]]]',
  'GLOSA [[[comentario, anotación, explicación]]]',
  'GLOSARIO [[[vocabulario, diccionario]]]',
  'GOZNE [[[charnela, bisagra]]]',
  'GRABAR [[[esculpir, tallar]]]',
  'GRADA [[[peldaño, escalón]]]',
  'GRANERO [[[hórreo, silo,]]]',
  'GRANJEAR [[[adquirir, ganar, captar, atraer]]]',
  'GRANUJA [[[pillo, bribón, golfo]]]',
  'GRATUITO [[[infundado, regalado,]]]',
  'GRAVA [[[arenisca]]]',
  'GRAVITAR [[[descansar, apoyarse]]]',
  'GRAVOSO [[[oneroso, plúmbeo]]]',
  'GRESCA [[[pendencia, reyerta]]]',
  'GRIMA [[[desazón, disgusto, inquietud]]]',
  'GRUMO [[[coágulo, endurecimiento]]]',
  'GRUÑIR [[[refunfuñar, murmurar]]]',
  'GUALDO [[[amarillo]]]',
  'GUARECER [[[cobijar, proteger, refugiarse]]]',
  'GUARISMO [[[número, cifra]]]',
  'GUISA [[[manera, estilo, forma]]]',
  'GUITA [[[bramante, cuerda]]]',
  'GULA [[[glotonería, voracidad, avidez]]]',
];

export const vocabulario_h = [
  'HABITUAL [[[usual, acostumbrado]]]',
  'HACENDADO [[[rico, adinerado]]]',
  'HACENDOSO [[[diligente, trabajador]]]',
  'HACIENDA [[[heredad, bienes]]]',
  'HACINAR [[[amontonar, acumular]]]',
  'HACINAMIENTO [[[aglomeración, amontonamiento]]]',
  'HALAGO [[[elogio, mimo, lisonja]]]',
  'HÁLITO [[[aliento, brisa, emanación]]]',
  'HALLAZGO [[[encuentro, descubrimiento, solución]]]',
  'HALO [[[aureola, resplandor]]]',
  'HAMPA [[[chusma, canalla]]]',
  'HAMPÓN [[[maleante, matón]]]',
  'HANGAR [[[cobertizo]]]',
  'HARAGÁN [[[holgazán, perezoso, gandul]]]',
  'HARAPO [[[andrajo, guiñapo]]]',
  'HASTIAR [[[aburrir, fastidiar, cansar]]]',
  'HATO [[[manada, rebaño]]]',
  'HECATOMBE [[[inmolación, matanza]]]',
  'HECHIZO [[[sortilegio, maleficio, embrujo]]]',

  'HEDER [[[apestar]]]',
  'HEDONISMO [[[sibaritismo, placer, materialismo]]]',
  'HEDOR [[[fetidez]]]',
  'HEGEMONÍA [[[predominio, supremacía]]]',
  'HÉGIRA [[[huida, era, héjira]]]',
  'HEMISFERIO [[[semiesfera, mitad]]]',
  'HENCHIR [[[llenar, hartar]]]',
  'HEREJÍA [[[apostasía, cisma, disparate]]]',
  'HERMÉTICO [[[cerrado, secreto]]]',
  'HERRUMBRE [[[moho, orín, óxido]]]',
  'HERVOR [[[ebullición, efervescencia]]]',
  'HETERODOXO [[[hereje,]]]',
  'HETEROGÉNEO [[[diverso, mezclado, híbrido]]]',
  'HÉTICO [[[flaco, tísico, enfermo]]]',
  'HIBERNACIÓN [[[letargo, somnolencia, sopor]]]',
  'HIERÁTICO [[[sagrado, solemne]]]',
  'HILARIDAD [[[risa, alegría, regocijo]]]',
  'HINCHA [[[inquina, ojeriza, antipatía]]]',
  'HIPAR [[[gimotear, ansiar]]]',
  'HIPÉRBOLE [[[exageración, ampulosidad]]]',
  'HIPOCONDRÍACO [[[deprimido, melancólico]]]',
  'HIPOTECAR [[[empeñar, gravar]]]',
  'HIRSUTO [[[rizado, áspero, enmarañado]]]',
  'HISOPO [[[rociador, aspersorio, varilla]]]',
  'HISTRIÓN [[[actor, cómico, farsante]]]',
  'HITO [[[mojón, muga, señal]]]',
  'HOGAÑO [[[hoy, ahora]]]',
  'HOLGADO [[[ancho, desocupado, amplio]]]',
  'HOLGAR [[[descansar, divertirse, alegrarse]]]',
  'HOLGAZÁN [[[gandul, perezoso, vago]]]',
  'HOLLAR [[[pisotear, humillar, profanar]]]',
  'HOLLEJO [[[piel, película, pellejo]]]',
  'HOLLÍN [[[ceniza, humo, tizne]]]',
  'HOLOCAUSTO [[[sacrificio, inmolación, mortandad]]]',
  'HOMILÍA [[[plática, sermón, discurso]]]',
  'HOMOGÉNEO [[[uniforme, semejante]]]',
  'HONTANAR [[[manantial, venero, chorro]]]',
  'HORADAR [[[agujerear, taladrar, perforar]]]',
  'HORDA [[[turba, caterva, tribu]]]',
  'HORMA [[[molde, forma]]]',
  'HORNACINA [[[hueco, nicho, concavidad]]]',
  'HORNEAR [[[asar, dorar]]]',
  'HORRENDO [[[espantoso, horrible, horroroso]]]',
  'HORRIPILAR [[[espantar, sobrecoger, aterrar]]]',
  'HORRÍSONO [[[atronador, ensordecedor]]]',
  'HOSCO [[[ceñudo, huraño, áspero]]]',
  'HOSTIGAR [[[acosar, sitiar, azotar]]]',
  'HOZAR [[[hocicar, escarbar]]]',
  'HUERO [[[vacío, hueco, insustancial]]]',
  'HUESTE [[[ejército, tropa, mesnada]]]',
  'HURAÑO [[[hosco, esquivo, misántropo]]]',

  'HURGAR [[[escarbar, remover, incitar]]]',
  'HURTO [[[robo]]]',
  'HUSMEAR [[[acechar, fisgar, barruntar]]]',
  'HUSO [[[hueca, hilera, rueca]]]',
];

export const vocabulario_i = [
  'ICONO [[[figura, efigie]]]',
  'IDIOSINCRASIA [[[peculiaridad, carácter]]]',
  'IDOLATRAR [[[adorar, admirar]]]',
  'IDONEIDAD [[[aptitud, competencia]]]',
  'ÍGNEO [[[ardiente, encendido]]]',
  'IGNICIÓN [[[combustión, inflamación]]]',
  'IGNOMINIA [[[oprobio, afrenta, deshonra]]]',
  'IGNOTO [[[desconocido]]]',
  'ILACIÓN [[[relación, consecuencia, nexo]]]',
  'ILESO [[[indemne, intacto]]]',
  'LÍCITO [[[ilegal, inmoral]]]',
  'LUSO [[[cándido, ingenuo, soñador]]]',
  'ILUSORIO [[[quimérico, imaginario, engañoso]]]',
  'LUSTRE [[[insigne, célebre, excelente, eximio]]]',
  'IMBERBE [[[lampiño, desbarbado]]]',
  'IMBUIR [[[infundir, inculcar, inspirar]]]',
  'IMPÁVIDO [[[impasible]]]',
  'IMPELER [[[impulsar, empujar, arrastrar]]]',
  'IMPENITENCIA [[[contumacia, obstinación]]]',
  'IMPERATIVO [[[obligatorio, categórico, necesidad]]]',
  'IMPERICIA [[[torpeza, ineptitud,]]]',
  'IMPETRAR [[[rogar, suplicar]]]',
  'IMPÍO [[[incrédulo, hereje, laico]]]',
  'IMPLACABLE [[[riguroso, cruel]]]',
  'IMPLICAR [[[incluir, envolver]]]',
  'IMPLÍCITO [[[tácito, callado, sobreentendido]]]',
  'IMPLORAR [[[rogar, suplicar, invocar]]]',
  'IMPOLUTO [[[limpio, inmaculado]]]',
  'IMPONDERABLE [[[inapreciable, imprevisible]]]',
  'IMPORTUNAR [[[incomodar, molestar, fastidiar]]]',
  'IMPOSICIÓN [[[coacción, coerción]]]',
  'IMPOSTURA [[[engaño, falsedad]]]',
  'IMPRECACIÓN [[[maldición, execración]]]',
  'IMPREGNAR [[[embeber, empapar]]]',
  'IMPRESIONAR [[[conmover, asombrar]]]',
  'ÍMPROBO [[[trabajoso, penoso, malvado, réprobo]]]',
  'IMPROPERIO [[[insulto, denuesto, dicterio]]]',
  'IMPUDENCIA [[[desvergüenza, impudor]]]',
  'IMPÚDICO [[[deshonesto, lujurioso, libertino]]]',
  'IMPUGNAR [[[recusar, refutar, oponerse]]]',
  'IMPULSIVO [[[impetuoso, violento]]]',
  'IMPUNE [[[exento, perdonado, libre]]]',
  'IMPUTAR [[[acusar, atribuir]]]',
  'INACCESIBLE [[[inalcanzable, intrincado, difícil]]]',
  'INALIENABLE [[[inajenable, irrenunciable]]]',
  'INANE [[[fútil, vano, inútil]]]',
  'INAUDITO [[[increíble, inconcebible]]]',

  'INCANDESCENCIA [[[ignición, combustión]]]',
  'INCAUTO [[[imprudente, cándido]]]',
  'INCENTIVO [[[estímulo, acicate]]]',
  'INCISIÓN [[[corte, hendidura]]]',
  'INCISIVO [[[punzante, mordaz, cáustico]]]',
  'INCITAR [[[estimular, espolear]]]',
  'INCLEMENCIA [[[rigor, dureza]]]',
  'ÍNCLITO [[[ilustre, célebre, afamado]]]',
  'INCÓLUME [[[intacto, indemne, ileso]]]',
  'INCONEXO [[[incoherente, incongruente]]]',
  'INCONMENSURABLE [[[infinito]]]',
  'INCONTINENCIA [[[lujuria, lascivia, vicio]]]',
  'INCORDIAR [[[molestar, fastidiar]]]',
  'INCONSTANTE [[[inestable, mudable]]]',
  'INCRÉDULO [[[descreído, escéptico]]]',
  'INCREPAR [[[reñir, reprender]]]',
  'INCULCAR [[[infundir, imbuir]]]',
  'INCULPAR [[[acusar]]]',
  'INCURIA [[[abandono, desidia]]]',
  'INCURSIÓN [[[correría, irrupción]]]',
  'INDAGAR [[[inquirir, investigar]]]',
  'INDECIBLE [[[inefable, inexplicable]]]',
  'INDECISO [[[dubitativo, irresoluto, vacilante]]]',
  'INDEFINIDO [[[ilimitado, impreciso]]]',
  'INDELEBLE [[[imborrable, permanente]]]',
  'INDEMNE [[[ileso, incólume, intacto]]]',
  'ÍNDICE [[[catálogo, lista, elenco]]]',
  'INDICIO [[[señal, atisbo, muestra]]]',
  'INDIFERENTE [[[apático, abúlico]]]',
  'INDIGENCIA [[[pobreza, miseria]]]',
  'INDIGENTE [[[pobre, necesitado]]]',
  'INDIGNACIÓN [[[ira, enojo, enfado]]]',
  'INDIGNAR [[[irritar, enojar, encolerizar]]]',
  'INDOLENCIA [[[apatía, desidia, pereza]]]',
  'INDÓMITO [[[indomable, indisciplinado]]]',
  'INDUCIR [[[animar, incitar, instigar]]]',
  'INDULGENCIA [[[perdón, condescendencia]]]',
  'INDULTAR [[[perdonar, amnistiar]]]',
  'INDUSTRIA [[[destreza, maña, habilidad]]]',
  'INÉDITO [[[desconocido, ignoto]]]',
  'INEFABLE [[[inenarrable, maravilloso]]]',
  'INERCIA [[[inacción, pereza, rutina]]]',
  'INERME [[[indefenso, desarmado]]]',
  'INERTE [[[estático, desidioso]]]',
  'INESCRUTABLE [[[indescifrable]]]',
  'INESPERADO [[[sorprendido, desprevenido]]]',
  'INEXORABLE [[[inflexible, implacable]]]',
  'INEXTRICABLE [[[enmarañado, intrincado, confuso]]]',
  'INFALIBLE [[[seguro, cierto]]]',
  'INFAMAR [[[difamar, desacreditar]]]',
  'INFAMIA [[[ignominia, vileza, indignidad]]]',

  'INFAUSTO [[[desgraciado, desafortunado, infeliz]]]',
  'INFERENCIA [[[consecuencia]]]',
  'INFERIR [[[deducir, colegir]]]',
  'INFLACIÓN [[[engreimiento, hinchamiento]]]',
  'INFLIGIR [[[condenar, castigar, aplicar]]]',
  'ÍNFULAS [[[vanidad, presunción]]]',
  'INFUNDIO [[[mentira, engaño]]]',
  'INGENTE [[[enorme, grandioso, colosal]]]',
  'INGRÁVIDO [[[ligero, liviano, leve]]]',
  'INHERENTE [[[inseparable, relacionado]]]',
  'INHIBIRSE [[[abstenerse, evadirse]]]',
  'INHUMAR [[[enterrar, soterrar]]]',
  'INICUO [[[injusto, malvado]]]',
  'INJERENCIA [[[intromisión, fisgoneo]]]',
  'INMARCESIBLE [[[inmarchitable, perenne]]]',
  'INMUNIDAD [[[exención, privilegio]]]',
  'INOCUO [[[inofensivo, inocuo]]]',
  'INOPIA [[[pobreza]]]',
  'INQUINA [[[antipatía, aversión]]]',
  'INQUIRIR [[[indagar, examinar]]]',
  'INSIDIA [[[asechanza, trampa]]]',
  'INSIDIOSO [[[intrigante, capcioso, engañoso]]]',
  'INSOLENTE [[[altanero, descarado]]]',
  'INSONDABLE [[[inexplicable, impenetrable]]]',
  'INSTAR [[[rogar, insistir]]]',
  'INSTIGACIÓN [[[incitación, inducción]]]',
  'INSURGENTE [[[insurrecto, sublevado]]]',
  'INTEMPERANCIA [[[desenfreno, exceso]]]',
  'INTERCEDER [[[mediar, abogar]]]',
  'INTERDICTO [[[entredicho, veto, prohibición]]]',
  'INTERFERIR [[[interponer, cortar, imponer]]]',
  'INTERINO [[[provisional, eventual]]]',
  'INTERMITENTE [[[discontinuo, interrumpido]]]',
  'INTERPELAR [[[requerir, preguntar]]]',
  'INTERPOLAR [[[intercalar]]]',
  'INTERSTICIO [[[hendidura, grieta]]]',
  'INTRIGA [[[enredo, jaleo]]]',
  'INTRINCADO [[[complicado, enigmático]]]',
  'INUSITADO [[[insólito, inusual]]]',
  'INVECTIVA [[[diatriba, ofensa]]]',
  'INVENTARIAR [[[catalogar, clasificar]]]',
  'INVERECUNDO [[[desvergonzado, descaro]]]',
  'INVEROSÍMIL [[[absurdo, raro]]]',
  'INVETERADO [[[antiguo, arraigado, añejo]]]',
  'INVOCAR [[[exhortar, apelar]]]',
  'IRACUNDO [[[colérico, soberbio]]]',
  'IRREFLEXIVO [[[atolondrado, aturdido]]]',
  'IRREFUTABLE [[[irrebatible]]]',
  'IRRESOLUTO [[[perplejo, indeciso]]]',
  'IRREVERENTE [[[insolente, profano]]]',
  'ITERAR [[[repetir]]]',

  'IZAR [[[levantar, subir]]]',
];

export const vocabulario_j = [
  'JÁCARA [[[juerga, danza, romance]]]',
  'JACTANCIA [[[vanagloria, petulancia, presunción]]]',
  'JALBEGAR [[[blanquear, enjalbegar]]]',
  'JALEAR [[[animar, alentar]]]',
  'JALÓN [[[señal, marca, mojón]]]',
  'JALONAR [[[señalar, marcar, limitar]]]',
  'JERIGONZA [[[jerga]]]',
  'JERINGAR [[[molestar, fastidiar]]]',
  'JIBIA [[[joroba]]]',
  'JIRA [[[excursión, viaje, merienda]]]',
  'JIRÓN [[[andrajo, pedazo, trozo]]]',
  'JOCOSO [[[festivo, gracioso, chistoso]]]',
  'JOFAINA [[[palangana]]]',
  'JOLGORIO [[[fiesta, bullicio, jarana]]]',
  'JOROBA [[[giba, corcova, chepa]]]',
  'JUBILEO [[[perdón, indulgencia, conmemoración]]]',
  'JÚBILO [[[alborozo, regocijo]]]',
  'JUGLAR [[[bufón, poeta, rapsoda, bardo]]]',
  'JURISDICCIÓN [[[potestad, autoridad, facultad]]]',
  'JUSTIPRECIAR [[[tasar, valorar, estimar]]]',
];

export const vocabulario_l = [
  'LÁBARO [[[estandarte, bandera, enseña]]]',
  'LABRANZA [[[cultivo, laboreo]]]',
  'LACAYO [[[siervo, servidor]]]',
  'LACERANTE [[[hiriente, punzante,]]]',
  'LACERAR [[[lastimar, dañar, herir]]]',
  'LACIO [[[mustio, marchito, fláccido, ajado]]]',
  'LACÓNICO [[[conciso, breve]]]',
  'LACRA [[[señal, huella, perjuicio]]]',
  'LACRIMOSO [[[lloroso, lastimero]]]',
  'LACTAR [[[amamantar, criar]]]',
  'LADINO [[[astuto, taimado]]]',
  'LAICO [[[lego, seglar, civil]]]',
  'LAMA [[[cieno, fango, barro]]]',
  'LAMPIÑO [[[imberbe, impúber, adolescente]]]',
  'LÁNGUIDO [[[decaído, débil]]]',
  'LAPIDAR [[[apedrear, aniquilar, matar]]]',
  'LAPSO [[[error, intervalo]]]',
  'LARDOSO [[[grasiento, pringoso]]]',
  'LARGUEZA [[[dadivosidad, liberalidad]]]',
  'LARVA [[[oruga, gusano]]]',
  'LASCIVO [[[lujurioso, libidinoso, sátiro]]]',
  'LASITUD [[[languidez, decaimiento, cansancio]]]',
  'LASTIMERO [[[plañidero, triste]]]',
  'LASTRE [[[peso, rémora]]]',
  'LATA [[[pesadez, hastío, envase]]]',
  'LATENTE [[[oculto, escondido]]]',
  'LÁTIGO [[[tralla, zurriago]]]',
  'LATITUD [[[anchura, amplitud, extensión]]]',
  'LATO [[[extenso, dilatado]]]',
  'LATROCINIO [[[hurto, robo]]]',

  'LAUDABLE [[[loable, plausible]]]',
  'LAUDO [[[premio, galardón, recompensa]]]',
  'LAVANDA [[[espliego, perfume]]]',
  'LAXAR [[[aflojar, purgar, relajar]]]',
  'LAXITUD [[[distensión, abandono]]]',
  'LAXO [[[flojo, débil, amoral]]]',
  'LAYA [[[calidad, clase]]]',
  'LEGAR [[[testar, heredar]]]',
  'LEGATARIO [[[heredero, beneficiario]]]',
  'LEGO [[[laico, profano, ignorante]]]',
  'LENIDAD [[[blandura, suavidad, benevolencia]]]',
  'LENITIVO [[[calmante, suavizante, balsámico]]]',
  'LEONINO [[[abusivo, injusto]]]',
  'LERDO [[[pesado, torpe, lento]]]',
  'LETAL [[[mortal, mortífero]]]',
  'LETARGO [[[estupor, torpeza, modorra]]]',
  'LEVA [[[recluta, alistamiento]]]',
  'LEVANTISCO [[[inquieto, rebelde]]]',
  'LEVEDAD [[[ligereza, vaguedad]]]',
  'LEVIATÁN [[[monstruo, demonio]]]',
  'LEVITA [[[frac, casaca]]]',
  'LIBAR [[[beber, chupar, probar]]]',
  'LIBELO [[[panfleto, folleto, difamación]]]',
  'LIBERALIDAD [[[generosidad, desprendimiento]]]',
  'LIBERTINO [[[depravado, vicioso]]]',
  'LIBIDINOSO [[[lujurioso, lascivo]]]',
  'LIBRAR [[[liberar, eximir]]]',
  'LICENCIAR [[[autorizar, permitir, despachar]]]',
  'LICITACIÓN [[[subasta, almoneda]]]',
  'LÍCITO [[[legal, permitido]]]',
  'LID [[[liza, pelea, combate]]]',
  'LIGEREZA [[[rapidez, levedad, irreflexión]]]',
  'LIMAR [[[desbastar, pulir]]]',
  'LIMBO [[[aureola, ribete]]]',
  'LIMÍTROFE [[[confinante, adyacente]]]',
  'LINAJE [[[estirpe, alcurnia]]]',
  'LINDAR [[[limitar, confinar, colindar]]]',
  'LINDE [[[límite, confín]]]',
  'LISONJEAR [[[adular, alabar, halagar]]]',
  'LITIGAR [[[pleitear, querellarse, recurrir]]]',
  'LITURGIA [[[ritual, culto, ceremonial]]]',
  'LIVIANO [[[lascivo, ligero, leve]]]',
  'LÍVIDO [[[pálido, descolorido, amoratado]]]',
  'LLANEZA [[[naturalidad, sencillez, sinceridad]]]',
  'LOA [[[alabanza]]]',
  'LÓBREGO [[[oscuro, tenebroso, lúgubre]]]',
  'LOCUACIDAD [[[verbosidad, palabrería]]]',
  'LOGRERO [[[usurero, prestamista]]]',
  'LONGANIMIDAD [[[magnanimidad]]]',
  'LONGEVIDAD [[[vejez, duración]]]',
  'LOOR [[[alabanza, loa, elogio]]]',

  'LOZANO [[[joven, lustroso, fresco]]]',
  'LUBRICANTE [[[aceite, deslizante]]]',
  'LÚBRICO [[[lujurioso, libidinoso]]]',
  'LUCRO [[[ganancia, usura]]]',
  'LUCUBRAR [[[meditar, velar, estudiar]]]',
  'LUDIBRIO [[[burla, befa, escarnio]]]',
  'LUENGO [[[largo, lejano]]]',
  'LUGAREÑO [[[aldeano, pueblerino]]]',
  'LÚGUBRE [[[pesimista, sombrío, luctuoso]]]',
  'LUJURIA [[[sensualidad, liviandad, concupiscencia]]]',
  'LUSTRE [[[brillo, barniz, prestigio]]]',
  'LUSTROSO [[[refulgente, brillante]]]',
];

export const vocabulario_m = [
  'MACEDONIA [[[mezcla, mezcolanza]]]',
  'MACERAR [[[ablandar, amasar, mortificar]]]',
  'MACILENTO [[[demacrado, flaco, descolorido]]]',
  'MÁCULA [[[mancha, mancilla,]]]',
  'MAGÍN [[[ingenio, mente, imaginación]]]',
  'MAGISTRAL [[[perfecto, clásico]]]',
  'MAGNÁNIMO [[[generoso, espléndido, grande]]]',
  'MAGNATE [[[prócer, acaudalado]]]',
  'MAGNIFICENCIA [[[esplendor, gloria]]]',
  'MAGNO [[[grande, ilustre]]]',
  'MAGRO [[[flaco, enjuto, delgado]]]',
  'MAJADA [[[guarida, refugio, estiércol]]]',
  'MAJAR [[[machacar]]]',
  'MALANDANZA [[[infortunio, desdicha]]]',
  'MALANDRÍN [[[despreciable, malvado]]]',
  'MALBARATAR [[[malvender, malgastar, disipar]]]',
  'MALEAR [[[estropear, viciar, pervertir]]]',
  'MALEABILIDAD [[[ductilidad, docilidad]]]',
  'MALÉVOLO [[[malvado, cruel]]]',
  'MALHADADO [[[infeliz, desventurado, desgraciado]]]',
  'MALOGRAR [[[frustrar, fallar, abortar]]]',
  'MALSANO [[[insalubre, insano]]]',
  'MALVERSAR [[[desfalcar, defraudar]]]',
  'MANCILLA [[[mácula, mancha, desdoro]]]',
  'MANDRIA [[[pusilánime, abúlico, holgazán]]]',
  'MANÍA [[[obsesión, ojeriza]]]',
  'MANIDO [[[vulgar, visto, trivial, socorrido]]]',
  'MANIRROTO [[[derrochador, generoso]]]',
  'MAQUIAVÉLICO [[[astuto, retorcido]]]',
  'MAQUINACIÓN [[[trama, intriga, complot]]]',
  'MARASMO [[[inmovilidad, paralización]]]',
  'MARBETE [[[rótulo, etiqueta]]]',
  'MARC}AL [[[militar, guerrero]]]',
  'MAREMÁGNUM [[[desorden, confusión]]]',
  'MARIDAJE [[[unión, matrimonio]]]',
  'MARISMA [[[albufera]]]',
  'MASCULLAR [[[farfullar, balbucir, gruñir]]]',
  'MASONERÍA [[[asociación, hermandad]]]',
  'MATIZ [[[grado, tonalidad]]]',

  'MATUTE [[[contrabando, timba]]]',
  '[[[ ]]]',
  'MATUTINO [[[temprano, matinal]]]',
  'MAULA [[[retal, engaño, treta]]]',
  'MAUSOLEO [[[sepulcro, tumba]]]',
  'MÁXIMA [[[sentencia, parábola, moraleja]]]',
  'MAYESTÁTICO [[[majestuoso, solemne]]]',
  'MAZORCA [[[espiga, maíz]]]',
  'MEANDRO [[[curva, sinuosidad]]]',
  'MECENAS [[[protector, patrocinador]]]',
  'MEDIANÍA [[[mediocridad, vulgaridad]]]',
  'MEDIAR [[[promediar, apaciguar]]]',
  'MEDRAR [[[crecer, aumentar]]]',
  'MEDROSO [[[miedoso, temeroso]]]',
  'MEJORAMIENTO [[[perfección, progreso]]]',
  'MELANCÓLICO [[[triste, cabizbajo]]]',
  'MELIFLUO [[[meloso, melindroso, dulzón]]]',
  'MELINDRE [[[remilgo, escrúpulo, pamplina]]]',
  'MENDAZ [[[mentiroso, falso]]]',
  'MENESTEROSO [[[necesitado, indigente]]]',
  'MENESTRAL [[[artesano, obrero, asalariado]]]',
  'MENOSCABAR [[[mermar, disminuir]]]',
  'MENTAR [[[nombrar, aludir, mencionar]]]',
  'MENTOR [[[maestro, guía]]]',
  'MEOLLO [[[enjundia, fundamento, núcleo]]]',
  'MERCADERÍA [[[mercancía, género, artículo]]]',
  'MERCED [[[dádiva, regalo, don]]]',
  'MERMAR [[[menoscabar, perder, disminuir]]]',
  'MESURA [[[prudencia, comedimiento]]]',
  'MIGRAÑA [[[jaqueca]]]',
  'MÍNIMO [[[ínfimo, minúsculo, pequeño]]]',
  'MINUCIA [[[pequeñez, pormenor]]]',
  'MISÁNTROPO [[[arisco, huraño, egoísta]]]',
  'MISÓGINO [[[tímido, solitario, insociable]]]',
  'MITIGAR [[[suavizar, moderar]]]',
  'MIXTURA [[[mezcla, pócima,]]]',
  'MOCIÓN [[[proposición, movimiento, inclinación]]]',
  'MÓDICO [[[reducido, moderado, barato]]]',
  'MOFA [[[burla, befa, escarnio]]]',
  'MOHÍN [[[gesto, mueca]]]',
  '[[[ ]]]',
  'MOHÍNA [[[enfado, enojo]]]',
  'MOHÍNO [[[triste, melancólico, enojado]]]',
  'MOHOSO [[[z: herrumbroso, oxidado]]]',
  'MOJIGATO [[[remilgado, timorato]]]',
  'MOJÓN [[[hito, muga]]]',
  'MOLICIE [[[blandura, ocio, comodidad]]]',
  'MONASTERIO [[[convento, cenobio]]]',
  'MONÓLOGO [[[soliloquio, recitado]]]',
  'MONTARAZ [[[silvestre, rústico]]]',
  'MORATORIA [[[prórroga, demora]]]',

  'MÓRBIDO [[[blando, enfermizo, fláccido]]]',
  'MORDAZ [[[punzante, incisivo]]]',
  'MOROSIDAD [[[demora, lentitud, tardanza]]]',
  'MORTECINO [[[débil, lánguido]]]',
  'MUESCA [[[concavidad, hueco, entalladura]]]',
  'MUGRE [[[suciedad, grasa]]]',
  'MULLIR [[[ablandar, ahuecar]]]',
  'MURGA [[[ruido, molestia]]]',
  'MURMULLO [[[bisbiseo, susurro]]]',
  'MUSITAR [[[susurrar, mascullar]]]',
  'MUSTIO [[[marchito, lacio, lánguido]]]',
  'MUTISMO [[[silencio, discreción]]]',
];

export const vocabulario_n = [
  'NACIENTE [[[oriente, levante, primero]]]',
  'NADERÍA [[[insignificancia, fruslería, bagatela]]]',
  'NAO [[[embarcación]]]',
  'NARCISISMO [[[vanidad, presunción]]]',
  'NATIVO [[[aborigen, indígena]]]',
  'NECIO [[[ignorante, tonto, estúpido]]]',
  'NECRÓPOLIS [[[cementerio]]]',
  'NEFANDO [[[abominable, execrable]]]',
  'NEFASTO [[[funesto]]]',
  'NEGADO [[[incapaz, inepto]]]',
  'NEGLIGENCIA [[[desidia, descuido, incuria]]]',
  'NEGUILLA [[[candileja]]]',
  'NEÓFITO [[[novicio, bautizado, novel]]]',
  'NEPOTISMO [[[favoritismo]]]',
  'NETO [[[limpio, líquido]]]',
  'NEURÁLGICO [[[vital, fundamental]]]',
  'NIMBO [[[aureola]]]',
  'NIMIO [[[prolijo, insignificante, pueril]]]',
  'NINFA [[[nereida, sirena]]]',
  'NOMADISMO [[[vagabundo, trashumación]]]',
  'NOMBRADÍA [[[fama, reputación, notoriedad]]]',
  'NÓMINA [[[lista, plantilla, cobro]]]',
  'NOMINAL [[[falso, honorario, teórico]]]',
  'NON [[[impar, negación]]]',
  'NONADA [[[nadería, insignificancia, fruslería]]]',
  'NONAGENARIO [[[noventón, anciano, senil]]]',
  'NOSTALGIA [[[añoranza, melancolía, tristeza]]]',
  'NOVEL [[[nuevo, novato]]]',
  'NOVÍSIMO [[[postrimería]]]',
  'NUDO [[[unión, lazo, atadura]]]',
  'NUMERARIO [[[dinero, moneda, efectivo]]]',
  'NÚMERO [[[guarismo, cifra]]]',
  'NUNCIO [[[representante, mensajero]]]',
  'ÑATO [[[chato, romo, mocho, despuntado]]]',
  'ÑEQUE [[[fuerte, valiente]]]',
  'ÑOÑO [[[remilgado, tímido, corto]]]',
];

export const vocabulario_o = [
  'OBCECADO [[[cegado, ofuscado]]]',
  'OBERTURA [[[preludio, introducción]]]',
  'OBESIDAD [[[gordura]]]',

  'ÓBICE [[[obstáculo, rémora, dificultad]]]',
  'ÓBITO [[[muerte]]]',
  'OBJETAR [[[replicar, contradecir, oponer]]]',
  'OBLACIÓN [[[ofrenda, sacrificio]]]',
  'OBLICUO [[[sesgado, inclinado, soslayado]]]',
  'OBLITERAR [[[obstruir, taponar, cerrar]]]',
  'OBNUBILAR [[[ofuscar, obcecarse, confundir]]]',
  'OBRADOR [[[taller]]]',
  'OBSEQUIOSO [[[atento, servicial, amable]]]',
  'OBSOLESCENTE [[[en desuso, desaparecido]]]',
  'OBSOLETO [[[anticuado]]]',
  'OBSTAR [[[empecer, estorbar, impedir]]]',
  'OBSTINADO [[[aferrado, tozudo, pertinaz]]]',
  'OBTURAR [[[taponar]]]',
  'OBTUSO [[[romo, torpe]]]',
  'OBVIAR [[[apartar, evitar, eludir]]]',
  'OBVIO [[[evidente, claro, manifiesto]]]',
  'OCA [[[ánade]]]',
  'OCASIONAL [[[accidental]]]',
  'OCASO [[[decadencia, puesta, occidente]]]',
  'OCLUIR [[[cerrar]]]',
  'OCRE [[[amarillento, pardo]]]',
  'OCURRENCIA [[[agudeza, gracia, salida]]]',
  'ODORÍFERO [[[oloroso, aromático]]]',
  'ODRE [[[pellejo, cuero]]]',
  'OFICIOSIDAD [[[diligencia, solicitud, indiscreción]]]',
  'OFRENDA [[[donación, regalo, oblación]]]',
  'OFUSCAR [[[cegar, turbar, confundir]]]',
  'OJERIZA [[[antipatía, inquina, tirria]]]',
  'OLEAGINOSO [[[aceitoso]]]',
  'OLISCAR [[[olfatear]]]',
  'OLLA [[[marmita, cocido, puchero]]]',
  'OMINOSO [[[abominable, odioso, execrable]]]',
  'OMITIR [[[suprimir]]]',
  'OMNIPOTENTE [[[todopoderoso]]]',
  'OMNIPRESENCIA [[[ubicuidad]]]',
  'ONDEAR [[[flamear]]]',
  'ONEROSO [[[pesado, molesto, gravoso, caro]]]',
  'ONÍRICO [[[relativo al sueño]]]',
  'OPACO [[[oscuro, sombrío]]]',
  'OPCIÓN [[[elección]]]',
  'OPIMO [[[rico, fértil, copioso]]]',
  'OPROBIO [[[deshonra, afrenta, ignominia]]]',
  'OPTAR [[[elegir, escoger, preferir]]]',
  'OPUGNAR [[[asaltar, atacar]]]',
  'OPULENTO [[[rico, adinerado, abundante]]]',
  'ORATE [[[loco, demente, alienado]]]',
  'ORATORIA [[[elocuencia]]]',
  'ORBE [[[mundo, universo]]]',
  'OREAR [[[ventilar, airear]]]',
  'ORFEBRE [[[platero]]]',

  'ORÍFICE [[[artífice, artesano]]]',
  'ORIFICIO [[[abertura]]]',
  'ORIGINAL [[[primero, nuevo, auténtico, singular]]]',
  'ORILLA [[[margen, borde, ribera]]]',
  'ORILLAR [[[resolver, solventar, arreglar, bordear]]]',
  'ORIUNDO [[[originario, nativo]]]',
  'ORNAMENTO [[[adorno, ornato]]]',
  'ORONDO [[[satisfecho, hinchado, ufano, barrigudo]]]',
  'OROPEL [[[abalorio, baratija]]]',
  'ORTO [[[nacimiento, aparición]]]',
  'ORTODOXIA [[[rectitud religiosa]]]',
  'OSTENSIBLE [[[patente, visible, manifiesto]]]',
  'OSTENTACIÓN [[[exhibición, jactancia, boato, pompa]]]',
  'OSTRACISMO [[[destierro, postergación]]]',
];

export const vocabulario_p = [
  'PÁBULO [[[fundamento, base]]]',
  'PACA [[[fardo]]]',
  'PACATO [[[tímido, timorato, asustadizo]]]',
  'PACER [[[pastar]]]',
  'PACIENTE [[[tolerante, calmoso]]]',
  'PADRINAZGO [[[protección, patrocinio]]]',
  'PADRÓN [[[empadronamiento, registro]]]',
  'PAGANO [[[gentil, hereje, idólatra, infiel]]]',
  'PAJAR [[[almiar]]]',
  'PAJE [[[escudero]]]',
  'PALADÍN [[[defensor, héroe, paladino]]]',
  'PALATINO [[[palaciego, cortesano]]]',
  'PALENQUE [[[estacada, arena, liza]]]',
  'PALIAR [[[encubrir, atenuar, moderar]]]',
  'PALIATIVO [[[calmante, atenuante]]]',
  'PALIQUE [[[conversación]]]',
  'PALISANDRO [[[madera]]]',
  'PALMAR [[[palmeral, morir]]]',
  'PALMARIO [[[evidente, palpable, claro]]]',
  'PANACEA [[[remedio, elixir]]]',
  'PANADERÍA [[[tahona]]]',
  'PANDEAR [[[apandar, encorvarse, combarse]]]',
  'PANEGÍRICO [[[apología, elogio, encomio, exaltación]]]',
  'PÁNFILO [[[pausado, soso]]]',
  'PANOJA [[[mazorca, espiga]]]',
  'PANTANOSO [[[cenagoso, fangoso, palúdico]]]',
  'PAPO [[[buche, papada]]]',
  'PARABIÉN [[[felicitación]]]',
  'PARADA [[[desfile, detención, pausa, estacionamiento]]]',
  'PARÁFRASIS [[[amplificación, comentario, interpretación]]]',
  'PARAÍSO [[[edén, gloria]]]',
  'PARAJE [[[lugar]]]',
  'PARALELISMO [[[semejanza, comparación, analogía]]]',
  'PARALOGISMO [[[sofisma, argucia]]]',
  'PARANGÓN [[[semejanza]]]',

  'PARANGONAR [[[cotejar, comparar]]]',
  'PARCO [[[sobrio, escaso]]]',
  'PARIDAD [[[igualdad]]]',
  'PARIHUELA [[[camilla]]]',
  'PARLAR [[[charlar]]]',
  'PAROXISMO [[[exaltación, exacerbación]]]',
  'PARQUEDAD [[[pequeñez]]]',
  'PÁRRAFO [[[parágrafo, período, frase]]]',
  'PARRANDA [[[jarana, juerga]]]',
  'PARSIMONIA [[[frugalidad, circunspección,]]]',
  'PARTICIÓN [[[división]]]',
  'PARTÍCIPE [[[participante]]]',
  'PARTICULAR [[[específico]]]',
  'PARVO [[[pequeño, escaso, corto]]]',
  'PASADO [[[pretérito]]]',
  'PASAJE [[[paso]]]',
  'PASATIEMPO [[[solaz]]]',
  'PASMAR [[[enfriar, helar, asombrar, inutilizar]]]',
  'PASQUÍN [[[cartel]]]',
  'PASTA [[[masa]]]',
  'PASTAR [[[pastorear, pacer]]]',
  'PATÁN [[[paleto, rústico, gañán, ignorante]]]',
  'PATENTIZAR [[[manifestar, significar]]]',
  'PATÓGENO [[[enfermizo, infeccioso]]]',
  'PATRAÑA [[[mentira]]]',
  'PATROCINIO [[[protección, amparo]]]',
  'PAUTA [[[modelo, dechado]]]',
  'PAVONEAR [[[presumir, blasonar]]]',
  'PAVOR [[[pánico, espanto]]]',
  'PAZGUATO [[[simple]]]',
  'PEANA [[[pedestal]]]',
  'PECULIARIDAD [[[característica, particularidad, propiedad]]]',
  'PECULIO [[[dinero, patrimonio]]]',
  'PECUNIO [[[dinero, caudal, hacienda]]]',
  'PELÍCULA [[[cutícula]]]',
  'PENACHO [[[cresta]]]',
  'PENDEJADA [[[ruindad, vileza]]]',
  'PENDENCIA [[[riña, pelea]]]',
  'PENDER [[[colgar]]]',
  'PENDÓN [[[estandarte]]]',
  'PENURIA [[[escasez, estrechez]]]',
  'PEQUEÑO [[[minúsculo]]]',
  'PERCUTIR [[[golpear, choquear]]]',
  'PERECEDERO [[[caduco, pasajero, mortal]]]',
  'PEREGRINO [[[romero, raro]]]',
  'PERENTORIO [[[decisivo, concluyente, urgente]]]',
  'PERFECTO [[[completo]]]',
  'PERICIA [[[destreza, habilidad]]]',
  'PERICLITAR [[[declinar, decaer]]]',
  'PERIGEO [[[apogeo]]]',

  'PERILLÁN [[[pícaro]]]',
  'PERÍMETRO [[[contorno]]]',
  'PERIÓDICO [[[regular]]]',
  'PERIPLO [[[circunnavegación]]]',
  'PERIPUESTO [[[acicalado]]]',
  'PERJURAR [[[infidelidad, incumplimiento]]]',
  'PERJURO [[[traidor, renegado]]]',
  'PERMUTAR [[[cambiar, canjear]]]',
  'PERNICIOSO [[[maligno, nocivo, perjudicial]]]',
  'PERORAR [[[hablar, explicar, orar]]]',
  'PERORATA [[[prédica, monserga]]]',
  'PERPETRAR [[[consumar, realizar, cometer]]]',
  'PERPETUA [[[sempiterna, eterno, permanente]]]',
  'PERPLEJO [[[dubitativo, vacilante]]]',
  'PERSUADIR [[[convencer]]]',
  'PERTINACIA [[[obstinación, persistencia]]]',
  'PERTINAZ [[[obstinado, recalcitrante, terco]]]',
  'PERTRECHAR [[[abastecer, proveer, dotar]]]',
  'PERVERSIDAD [[[depravación]]]',
  'PESADO [[[macizo, oneroso, grávido]]]',
  'PETIMETRE [[[dandi, presumido]]]',
  'PÉTREO [[[duro, rocoso]]]',
  'PETULANCIA [[[inmodestia, descaro, fatuidad]]]',
  'PEYORATIVO [[[despreciativo]]]',
  'PICANTE [[[mordaz]]]',
  'PÍCARO [[[ruin, pillo, granuja]]]',
  'PIEDAD [[[compasión]]]',
  'PIEL [[[cuero]]]',
  'PIFIA [[[error]]]',
  'PIGNORAR [[[empeñar, hipotecar]]]',
  'PILLAJE [[[rapiña, robo, saqueo]]]',
  'PIMPOLLO [[[brote, vástago, capullo]]]',
  'PINGAJO [[[andrajo, harapo, jirón]]]',
  'PINGÜE [[[copioso, abundante, cuantioso]]]',
  'PIO [[[piadoso, devoto]]]',
  'PIONERO [[[primero]]]',
  'PIPIOLO [[[novato, inexperto]]]',
  'PIRATA [[[corsario, ladón]]]',
  'PIRRARSE [[[anhelar, desvivirse]]]',
  'PITANZA [[[comida, vitualla]]]',
  'PIZCA [[[miaja, partícula, menudencia]]]',
  'PLÁCEME [[[felicitación]]]',
  'PLÁCIDO [[[sosegado, tranquilo]]]',
  'PLAGIO [[[copia]]]',
  'PLANTEL [[[vivero, semillero]]]',
  'PLAÑIDERA [[[llorona, sollozante]]]',
  'PLAÑIDO [[[lamento, llanto, queja]]]',
  'PLASMAR [[[formar, modelar]]]',
  'PLATICAR [[[hablar, tratar, sermonear]]]',
  'PLAUSIBLE [[[laudable, recomendable]]]',
  'PLEBE [[[vulgo, pueblo, populacho, chusma]]]',

  'PLEBISCITO [[[sufragio, referéndum]]]',
  'PLEITEAR [[[litigar, incoar]]]',
  'PLÉTORA [[[abundancia, exceso]]]',
  'PLETÓRICO [[[repleto, exuberante, lleno]]]',
  'PLUMA [[[péndola, penacho]]]',
  'PLÚMBEO [[[pesado, plomizo]]]',
  'PÓCIMA [[[brebaje]]]',
  'PODAR [[[cortar, talar, cercenar]]]',
  'PODÓMETRO [[[cuentapasos]]]',
  'PODRIDO [[[fétido]]]',
  'POETA [[[bardo, vate, rapsoda, juglar]]]',
  'POLTRÓN [[[perezoso, marmota]]]',
  'POLUCIÓN [[[contaminación, impureza]]]',
  'POMPA [[[fausto, ostentación]]]',
  'POMPOSO [[[rumboso, ostentoso, suntuoso]]]',
  'PONDERAR [[[contrapesar, examinar]]]',
  'PONZOÑA [[[veneno, tóxico]]]',
  'POQUEDAD [[[escasez, cortedad, miseria]]]',
  'PORFÍA [[[discusión, disputa, contienda]]]',
  'PORMENOR [[[menudencia, descripción]]]',
  'PORO [[[intersticio]]]',
  'PORTAL [[[zaguán, vestíbulo]]]',
  'PORTE [[[presencia, aspecto]]]',
  'PORTEAR [[[transportar, acarrear]]]',
  'PORTENTO [[[prodigio]]]',
  'PÓRTICO [[[porche, soportal]]]',
  'POSESO [[[endemoniado, poseído]]]',
  'POSO [[[sedimento, resto]]]',
  'POSPONER [[[postergar, diferir]]]',
  'POSTERGAR [[[diferir, posponer]]]',
  'POSTÍN [[[presunción, lujo, boato]]]',
  'POSTOR [[[licitador]]]',
  'POSTRACIÓN [[[abatimiento, debilidad]]]',
  'POSTRERO [[[último]]]',
  'POSTULACIÓN [[[colecta, recaudación, cuestación]]]',
  'POSTULAR [[[pedir, recaudar]]]',
  'POTENCIAL [[[posible]]]',
  'POTENTADO [[[pudiente]]]',
  'POTESTATIVO [[[facultativo]]]',
  'POYO [[[banco]]]',
  'PREÁMBULO [[[prólogo, exordio, preludio]]]',
  'PREBENDA [[[beneficio, canonjía, preferencia]]]',
  'PRECARIO [[[inestable, transitorio, efímero]]]',
  'PRECAVER [[[prevenir]]]',
  'PRECEPTIVO [[[obligatorio, ordenado]]]',
  'PRECEPTO [[[mandato, disciplina]]]',
  'PRECEPTOR [[[maestro, instructor]]]',
  'PRECEPTUAR [[[mandar]]]',
  'PRECES [[[rezos, ruegos]]]',
  'PRECIADO [[[estimado, querido]]]',
  'PRECIPICIO [[[sima, barranco]]]',

  'PRECIPITACIÓN [[[apresuramiento, apremio]]]',
  'PRECIPITADO [[[sedimento, atropellado]]]',
  'PRECLARO [[[ilustre, célebre, insigne]]]',
  'PRECONIZAR [[[patrocinar, encomiar]]]',
  'PREDESTINADO [[[fatal, gracia]]]',
  'PREDIO [[[heredad, hacienda, posesión]]]',
  'PREDISPOSICIÓN [[[propensión]]]',
  'PREFACIO [[[preámbulo, introducción]]]',
  'PREGONERO [[[nuncio]]]',
  'PREJUICIO [[[oposición, parcialidad, obcecación]]]',
  'PRELACIÓN [[[preferencia]]]',
  'PRELUDIO [[[introducción, preámbulo]]]',
  'PREMIOSIDAD [[[morosidad, lentitud]]]',
  'PREMIOSO [[[lento, ajustado]]]',
  'PREMURA [[[urgencia]]]',
  'PRENDAR [[[enamorar]]]',
  'PRERROGATIVA [[[privilegio]]]',
  'PRESBÍTERO [[[sacerdote]]]',
  'PRESENTE [[[regalo]]]',
  'PRESENTIMIENTO [[[barrunto]]]',
  'PRESTAMENTE [[[prontamente]]]',
  'PRESTANCIA [[[gallardía]]]',
  'PRESTEZA [[[prontitud]]]',
  'PRESTIGIO [[[ascendiente]]]',
  'PRESUNCIÓN [[[vanidad, suposición, petulancia]]]',
  'PRESUNTO [[[supuesto]]]',
  'PRESUNTUOSO [[[petulante]]]',
  'PRETENSIÓN [[[aspiración, intención]]]',
  'PRETÉRITO [[[pasado]]]',
  'PRETEXTO [[[excusa]]]',
  'PRETIL [[[barandilla]]]',
  'PREVALERSE [[[aprovecharse]]]',
  'PREVIO [[[anterior, anticipado]]]',
  'PREZ [[[gloria, honor, honra]]]',
  'PRIMATE [[[mono]]]',
  'PRIMOGENITURA [[[mayorazgo, heredero]]]',
  'PRIMOR [[[esmero, maestría]]]',
  'PRIORIDAD [[[precedencia, preferencia]]]',
  'PRÍSTINO [[[primitivo, original]]]',
  'PROBABLE [[[verosímil]]]',
  'PROBIDAD [[[honradez, rectitud, integridad]]]',
  'PROBO [[[honrado]]]',
  'PROCACIDAD [[[desvergüenza, grosería]]]',
  'PROCELOSO [[[tormentoso, tempestuoso]]]',
  'PRÓCER [[[magnate]]]',
  'PROCLIVE [[[inclinación, tendencia]]]',
  'PRODIGALIDAD [[[despilfarro, derroche]]]',
  'PRODIGO [[[despilfarrador]]]',
  'PROFANAR [[[deshonrar]]]',
  'PROFANO [[[lego]]]',
  'PROFERIR [[[pronunciar]]]',

  'PRÓFUGO [[[desertor, fugitivo]]]',
  'PROFUSIÓN [[[abundancia]]]',
  'PROGENIE [[[linaje]]]',
  'PROLEPSIS [[[anticipación]]]',
  'PROLIFERACIÓN [[[multiplicación]]]',
  'PROLÍFICO [[[fértil, fecundo, prolífero]]]',
  'PROLIJO [[[extenso, dilatado]]]',
  'PROMULGAR [[[divulgar]]]',
  'PROPENSIÓN [[[tendencia, inclinación]]]',
  'PROSCRIBIR [[[expulsar, desterrar, vedar]]]',
  'PROTUBERANCIA [[[prominencia]]]',
  'PROVECTO [[[viejo]]]',
  'PROVECHOSO [[[útil]]]',
  'PROVEER [[[surtir]]]',
  'PSÍQUICO [[[anímico]]]',
  'PÚDICO [[[casto, recatado]]]',
  'PUGNAZ [[[belicoso]]]',
  'PUJA [[[mejora, aumento]]]',
  'PUJANTE [[[potente, vigoroso, brioso]]]',
  'PULCRO [[[limpio]]]',
  'PULULAR [[[abundar]]]',
  'PÚNICO [[[cartaginés]]]',
  'PUNIR [[[castigar]]]',
  'PUNZANTE [[[agudo, mordaz]]]',
  'PUNZAR [[[pinchar, picar]]]',
  'PÚRPURA [[[rojo]]]',
  'PURULENTO [[[virulento]]]',
  'PUSILÁNIME [[[medroso, apocado, cobarde]]]',
  'PUTREFACCIÓN [[[corrupción, descomposición]]]',
];

export const vocabulario_q = [
  'QUEBRADIZO [[[frágil]]]',
  'QUEBRANTO [[[daño, pérdida]]]',
  'QUEDA [[[toque]]]',
  'QUEDAR [[[permanecer, faltar]]]',
  'QUEDO [[[quieto, en vobaja]]]',
  'QUID [[[esencia, motivo]]]',
  'QUIEBRA [[[rotura, fractura]]]',
  'QUILLA [[[casco]]]',
  'QUIMERA [[[ilusión, fantasía, ficción]]]',
  'QUINCALLA [[[baratijas]]]',
  'QUINQUÉ [[[lámpara]]]',
  'QUITO [[[libre, exento]]]',
];

export const vocabulario_r = [
  'RACIONAL [[[lógico]]]',
  'RAER [[[raspar]]]',
  'RAHEZ [[[vil, despreciable]]]',
  'RAIL [[[riel]]]',
  'RALEA [[[especie, raza, casta]]]',
  'RALO [[[separado]]]',
  'RAMPLÓN [[[tosco, vulgar]]]',
  'RAPAZ [[[ladrón]]]',
  'RÁPIDO [[[ligero]]]',
  'RAPIÑA [[[pillaje]]]',

  'RAPSODA [[[poeta, vate]]]',
  'RAPTO [[[arrebato, robo, éxtasis]]]',
  'RARO [[[escaso]]]',
  'RASTRO [[[indicio, vestigio]]]',
  'RASURAR [[[afeitar]]]',
  'RATIFICAR [[[confirmar]]]',
  'RAUDO [[[rápido, veloz]]]',
  'RAYANO [[[limítrofe]]]',
  'REACIO [[[remiso, renuente]]]',
  'REATA [[[hilera, cuerda, fila]]]',
  'REBALSAR [[[embalsar, estancar]]]',
  'REBAÑO [[[hato, manada]]]',
  'REBELAR [[[sublevar]]]',
  'RECALAR [[[llegar]]]',
  'RECALCITRANTE [[[terco, contumaz, pertinaz]]]',
  'RECELAR [[[desconfiar]]]',
  'RECELOSO [[[suspicaz]]]',
  'RECEPTÁCULO [[[recipiente, vasija]]]',
  'RECESO [[[separación, suspensión, descanso]]]',
  'RECIO [[[fuerte]]]',
  'RECLAMO [[[señuelo]]]',
  'RECLINAR [[[recostar, apoyar]]]',
  'RECLUIR [[[encerrar, confinar]]]',
  'RECOLETO [[[austero, retirado]]]',
  'RECÓNDITO [[[escondido, oculto]]]',
  'RECONOCIMIENTO [[[chequeo]]]',
  'RECTIFICAR [[[corregir, ajustar]]]',
  'RECUSAR [[[rechazar]]]',
  'RECHIFLA [[[burla]]]',
  'REDOMADO [[[astuto, taimado]]]',
  'REDUNDANCIA [[[demasía, exceso, pleonasmo]]]',
  'REFLEJO [[[reflexivo]]]',
  'REFOCILAR [[[recrear, alegrar, regodear]]]',
  'REFRACTARIO [[[opuesto, incombustible, resistente]]]',
  'REFRIGERIO [[[tentempié]]]',
  'REFULGIR [[[brillar]]]',
  'REFUNFUÑAR [[[rezongar]]]',
  'REFUTAR [[[oponer, rebatir, impugnar]]]',
  'REGALÍA [[[privilegio, franquicia, exención]]]',
  'REGIO [[[real]]]',
  'REGODEARSE [[[deleitarse, complacerse]]]',
  'REGRESIÓN [[[retroceso]]]',
  'REHUIR [[[evitar]]]',
  'REHUSAR [[[declinar, renunciar]]]',
  'RELAPSO [[[reincidente]]]',
  'RELUCTANTE [[[desobediente]]]',
  'REMANENTE [[[residuo]]]',
  'REMEDAR [[[emular, imitar]]]',
  'REMEMBRANZA [[[recuerdo]]]',
  'REMESAR [[[enviar, expedir]]]',
  'REMINISCENCIA [[[recuerdo, memoria]]]',

  'REMISIÓN [[[envío, referencia, absolución]]]',
  'REMISO [[[renuente, reacio, irresoluto]]]',
  'RÉMORA [[[obstáculo, estorbo, freno]]]',
  'RENUENTE [[[reacio, remiso]]]',
  'RENUNCIO [[[mentira]]]',
  'REPECHO [[[cuesta]]]',
  'RÉPLICA [[[objeción, respuesta]]]',
  'REPOSAR [[[descansar]]]',
  'REPROBAR [[[censurar, vituperar]]]',
  'REPUDIAR [[[rechazar, repeler]]]',
  'REQUEBRAR [[[piropear, lisonjear]]]',
  'REQUISAR [[[comisar]]]',
  'RESABIO [[[vicio, defecto, desazón]]]',
  'RESARCIR [[[compensar, indemnizar, reintegrar]]]',
  'RESCINDIR [[[anular, abjurar]]]',
  'RESPLANDECIENTE [[[brillante, fulgurante]]]',
  'RESQUEMOR [[[escozor, resentimiento, pesadumbre]]]',
  'RESQUICIO [[[grieta]]]',
  'RESTALLAR [[[chasquear, crujir]]]',
  'RESTITUCIÓN [[[devolución, reposición]]]',
  'RESUELLO [[[respiración, jadeo, aliento]]]',
  'RETAHÍLA [[[sarta, serie]]]',
  'RETAL [[[sobrante]]]',
  'RETAR [[[desafiar]]]',
  'RETARDO [[[demora]]]',
  'RETAZO [[[fragmento]]]',
  'RETENTIVA [[[memoria]]]',
  'RETICENCIA [[[reserva, ironía]]]',
  'RETO [[[desafío]]]',
  'RETOÑAR [[[rebrotar, renovar]]]',
  'RETRACTARSE [[[desdecirse, abjurar]]]',
  'RETRUÉCANO [[[conmutación, chiste]]]',
  'REVERBERAR [[[reflejar, resplandecer]]]',
  'REVERENCIA [[[veneración]]]',
  'REVERSO [[[dorso]]]',
  'REVOCAR [[[abolir, derogar]]]',
  'REVUELTA [[[algarada, levantamiento]]]',
  'REZUMAR [[[exudar, filtrarse, escurrirse]]]',
  'RICTUS [[[mueca]]]',
  'RIGOR [[[severidad]]]',
  'RIMADOR [[[poeta]]]',
  'RIÑA [[[camorra, pendencia]]]',
  'RIPIO [[[residuo, desecho]]]',
  'RISCOSO [[[peñascoso]]]',
  'ROLLIZO [[[redondo, grueso]]]',
  'ROMO [[[chato, obtuso]]]',
  'RONCHA [[[rodaje]]]',
  'RONZAL [[[ramal]]]',
  'ROÑA [[[herrumbre, suciedad]]]',
  'ROÑERA [[[tacañería]]]',
  'ROSTRO [[[faz]]]',

  'ROTURAR [[[romper]]]',
  'RUMBOSO [[[generoso, desprendido, garboso]]]',
  'RUSTICO [[[agreste, basto]]]',
  'RUTILAR [[[resplandecer]]]',
  'RUTINA [[[hábito]]]',
];

export const vocabulario_s = [
  'SACA [[[saco, fardo]]]',
  'SACAMUELAS [[[charlatán]]]',
  'SACIAR [[[satisfacer, hartar]]]',
  'SAETA [[[flecha]]]',
  'SAGAZ [[[astuto]]]',
  'SAGITA [[[flecha]]]',
  'SALTEAR [[[acometer]]]',
  'SALUBRE [[[saludable, sano]]]',
  'SANDIO [[[simple, estúpido, tonto]]]',
  'SANDUNGA [[[donaire, gracia]]]',
  'SANTIAMÉN [[[instante]]]',
  'SANTORAL [[[hagiografía]]]',
  'SARCASMO [[[escarnio]]]',
  'SARCÁSTICO [[[mordaz, irónico, satírico]]]',
  'SARRO [[[costra, sedimento]]]',
  'SARTA [[[retahíla, serie, ristra, rosario]]]',
  'SÁTIRA [[[z: ironía, burla]]]',
  'SATURAR [[[saciar, hartar]]]',
  'SAYA [[[falda]]]',
  'SAZONADO [[[condimentado, aliñado]]]',
  'SECCIÓN [[[corte]]]',
  'SECUAZ [[[partidario, adepto]]]',
  'SECUELA [[[consecuencia]]]',
  'SECUNDARIO [[[accesorio]]]',
  'SEDIMENTO [[[depósito]]]',
  'SEGREGAR [[[separar, dividir]]]',
  'SEÍSMO [[[terremoto]]]',
  'SENECTUD [[[vejez]]]',
  'SEÑERO [[[único, insigne, solitario]]]',
  'SEÑOREAR [[[dominar]]]',
  'SEÑUELO [[[reclamo]]]',
  'SEPSIS [[[putrefacción]]]',
  'SERRALLO [[[harén, reclusión]]]',
  'SERVICIAL [[[solícito]]]',
  'SERVIL [[[rastrero]]]',
  'SERVILISMO [[[adulación, vasallaje]]]',
  'SERVIR [[[valer]]]',
  'SEVICIA [[[crueldad]]]',
  'SIBARITA [[[epicúreo]]]',
  'SIBILINO [[[oscuro, misterioso]]]',
  'SICARIO [[[mercenario]]]',
  'SIDERAL [[[estelar, astronómico]]]',
  'SIGILO [[[reserva, silencio, secreto]]]',
  'SIGLO [[[centuria, era]]]',
  'SIGNAR [[[firmar, sellar]]]',
  'SIGNATARIO [[[firmante, rubricante]]]',

  'SILVESTRE [[[salvaje, agreste]]]',
  'SINALAGMÁTICO [[[bilateral]]]',
  'SINCOPE [[[desmayo, accidente]]]',
  'SINÉRESIS [[[contracción]]]',
  'SINÓPTICO [[[resumido]]]',
  'SIRENA [[[sílfide, ninfa, silbato]]]',
  'SIRGA [[[maroma, cuerda]]]',
  'SISTEMÁTICO [[[ordenado, organizado]]]',
  'SITIAR [[[asediar, cercar]]]',
  'SOBRELLEVAR [[[soportar, aguantar, sufrir]]]',
  'SOBREPRECIO [[[recargo]]]',
  'SOBRIO [[[parco]]]',
  'SOCAPA [[[excusa, pretexto, disimulo]]]',
  'SOCARRAR [[[chamuscar, tostar]]]',
  'SOCAVAR [[[minar]]]',
  'SOEZ [[[grosero, tosco, basto]]]',
  'SOFISTICADO [[[mixtificado]]]',
  'SOLAZ [[[esparcimiento, recreo, descanso]]]',
  'SOLÍCITO [[[atento, considerado, cuidadoso]]]',
  'SOLILOQUIO [[[monólogo]]]',
  'SOLIO [[[trono, silla]]]',
  'SOLITARIA [[[tenia]]]',
  'SOMANTA [[[paliza, tunda]]]',
  'SOMÁTICO [[[corporal]]]',
  'SOMERO [[[superficial, sucinto]]]',
  'SOPOR [[[somnolencia]]]',
  'SÓRDIDO [[[disoluto, indecente, sucio]]]',
  'SORTEAR [[[evitar]]]',
  'SOSLAYAR [[[evitar, eludir]]]',
  'SOTABANCO [[[desván, buhardilla, ático]]]',
  'SOTERRAR [[[enterrar, esconder]]]',
  'SUBALTERNO [[[inferior, ujier, ordenanza]]]',
  'SUBEROSO [[[corchoso]]]',
  'SUBLIMAR [[[enaltecer, exaltar]]]',
  'SUBROGAR [[[sustituir]]]',
  'SUBTERFUGIO [[[pretexto, evasiva, medio]]]',
  'SUBVENIR [[[amparar, ayudar]]]',
  'SUBYUGAR [[[oprimir, fascinar]]]',
  'SUCCIONAR [[[chupar]]]',
  'SUCINTO [[[conciso, breve, resumido, escueto]]]',
  'SUMISO [[[dócil]]]',
  'SUMO [[[supremo]]]',
  'SUPLANTAR [[[sustituir]]]',
  'SUPOSICIÓN [[[conjetura]]]',
  'SUPREMACÍA [[[hegemonía]]]',
  'SUSPICACIA [[[recelo]]]',
  'SUTILEZA [[[agudeza]]]',
];

export const vocabulario_t = [
  'TABARRA [[[lata, tostón]]]',
  'TÁCITO [[[implícito, sobrentendido]]]',
  'TACHA [[[falta, defecto]]]',
  'TAHONA [[[panadería]]]',

  'TAHÚR [[[jugador]]]',
  'TAIMADO [[[astuto, ladino]]]',
  'TALAR [[[cortar]]]',
  'TALIÓN [[[venganza]]]',
  'TALISMÁN [[[amuleto]]]',
  'TALLA [[[imagen, escultura]]]',
  'TALLUDO [[[crecido, alto]]]',
  'TAÑER [[[tocar]]]',
  'TARACEAR [[[incrustar]]]',
  'TARDO [[[tardío, torpe, obtuso]]]',
  'TEDIO [[[aburrimiento, hastío, desgana]]]',
  'TEJAVANA [[[cobertizo]]]',
  'TELEOLOGÍA [[[finalismo]]]',
  'TEMERARIO [[[imprudente]]]',
  'TEMPERANCIA [[[moderación, templanza]]]',
  'TEMPERAR [[[calmar]]]',
  'TEMPLE [[[carácter, índole]]]',
  'TENACIDAD [[[constancia]]]',
  'TENEBROSO [[[oscuro, sombrío]]]',
  'TENTAR [[[atraer, palpar]]]',
  'TENUE [[[suave]]]',
  'TEOLÓGICO [[[relativo a Dios]]]',
  'TEORÍA [[[idea]]]',
  'TERAPÉUTICO [[[curativo]]]',
  'TERCO [[[pertinaz, obstinado, perseverante, tozudo]]]',
  'TERGIVERSAR [[[torcer, falsear, cambiar]]]',
  'TERNE [[[perseverante, obstinado]]]',
  'TERNEZA [[[ternura]]]',
  'TERSO [[[liso]]]',
  'TESITURA [[[actitud]]]',
  'TESTA [[[cabeza]]]',
  'TESTIFICAR [[[atestiguar, testimoniar]]]',
  'TESTIMONIAL [[[testifical]]]',
  'TÉTRICO [[[tenebroso]]]',
  'TIBIO [[[templado]]]',
  'TIFÓN [[[vendaval]]]',
  'TILDAR [[[tachar]]]',
  'TIMBRAR [[[sellar]]]',
  'TIMORATO [[[tímido]]]',
  'TÍSICO [[[tuberculoso, hético]]]',
  'TITÁNICO [[[gigantesco]]]',
  'TITILAR [[[centellar, temblar, brillar]]]',
  'TOMAR [[[aceptar]]]',
  'TOPONIMIA [[[nombres de lugares]]]',
  'TORCER [[[doblar]]]',
  'TORNADA [[[regreso]]]',
  'TORNADIZO [[[veleidoso]]]',
  'TORRENTERA [[[barranco, quebrada]]]',
  'TORSO [[[tronco]]]',
  'TORTUOSO [[[sinuoso]]]',

  'TORVO [[[terrible, fiero]]]',
  'TOSCO [[[burdo, áspero]]]',
  'TRABAR [[[sujetar, unir, impedir]]]',
  'TRABAZÓN [[[enlace, conexión]]]',
  'TRAGALUZ [[[claraboya]]]',
  'TRAMAR [[[urdir, tejer]]]',
  'TRANSGRESOR [[[infractor]]]',
  'TRANSIGENTE [[[condescendiente]]]',
  'TREGUA [[[suspensión, interrupción]]]',
  'TREMEBUNDO [[[aterrador]]]',
  'TRÉMULO [[[tembloroso]]]',
  'TREMOLINA [[[gresca, trifulca]]]',
  'TREPANAR [[[perforar, agujerear]]]',
  'TRETA [[[ardid]]]',
  'TRISTE [[[pesaroso]]]',
  'TRIVIALIDAD [[[vulgaridad]]]',
  'TRIZA [[[pedazo, partícula]]]',
  'TROGLODITA [[[cavernícola]]]',
  'TROPELÍA [[[exceso, abuso, atropello]]]',
  'TRÓPICO [[[figurado]]]',
  'TROQUELAR [[[acuñar]]]',
  'TRUHAN [[[pícaro]]]',
  'TUMULTO [[[alboroto]]]',
  'TUNDIR [[[azotar]]]',
  'TUPIDO [[[espeso]]]',
  'TUPIR [[[cerrar, obturar]]]',
  'TURGENCIA [[[hinchazón, rigidez]]]',
  'TURGENTE [[[erecto]]]',
];

export const vocabulario_u = [
  'UBÉRRIMO [[[abundante, fecundo, fértil]]]',
  'UBICAR [[[colocar]]]',
  'UBICUIDAD [[[omnipresencia]]]',
  'UFANARSE [[[engreírse, jactarse]]]',
  'UJIER [[[subalterno, portero]]]',
  'ULTRAJE [[[agravio, afrenta]]]',
  'UMBRÁTIL [[[sombrío, sombreado]]]',
  'UNÁNIME [[[acorde, general]]]',
  'UNCIÓN [[[devoción, fervor]]]',
  'UNCIR [[[enyugar, atar, sujetar]]]',
  'UNGIR [[[untar, proclamar]]]',
  'ÚNICO [[[singular]]]',
  'UNÍVOCO [[[idéntico]]]',
  'URBANIDAD [[[cortesía]]]',
  'URDIR [[[tramar, maquinar]]]',
  'URGENTE [[[ardiente]]]',
  'URGIR [[[apremiar]]]',
  'USANZA [[[práctica, costumbre]]]',
  'USUAL [[[habitual]]]',
  'USUFRUCTO [[[disfrute, uso]]]',
  'USURA [[[interés excesivo]]]',
  'USURPAR [[[apropiarse]]]',
  'ÚTIL [[[provechoso]]]',

  'ÚVULA [[[campanilla, paladar]]]',
];

export const vocabulario_v = [
  'VACUO [[[vacío]]]',
  'VADEAR [[[franquear, atravesar]]]',
  'VADEMÉCUM [[[guía, compendio]]]',
  'VAGAR [[[errar]]]',
  'VAGO [[[oscuro, gandul]]]',
  'VAGUEDAD [[[imprecisión]]]',
  'VAHO [[[vapor]]]',
  'VALETUDINARIO [[[enfermizo, caduco, viejo]]]',
  'VALIDO [[[favorito]]]',
  'VALIJA [[[maleta]]]',
  'VALLA [[[cerca]]]',
  'VALOR [[[denuedo, brío]]]',
  'VANAGLORIA [[[jactancia, presunción]]]',
  'VANIDOSO [[[presuntuoso]]]',
  'VANO [[[vacío]]]',
  'VAPORIZAR [[[evaporar]]]',
  'VAPOROSO [[[ligero, sutil]]]',
  'VARAR [[[encallar, zozobrar]]]',
  'VARIO [[[diverso]]]',
  'VÁSTAGO [[[descendiente, brote]]]',
  'VASTO [[[extenso, amplio]]]',
  'VATE [[[poeta, rapsoda, juglar]]]',
  'VATICINAR [[[adivinar, pronosticar, augurar]]]',
  'VATIO [[[unidad eléctrica]]]',
  'VEDAR [[[prohibir]]]',
  'VEHEMENCIA [[[ardor, apasionamiento, ímpetu]]]',
  'VEJAR [[[avasallar, oprimir, molestar]]]',
  'VELAR [[[cuidar, ocultar]]]',
  'VELEIDAD [[[capricho, versatilidad, inestabilidad]]]',
  'VELEIDOSO [[[inconstante, mudable]]]',
  'VELLO [[[pelo]]]',
  'VENAL [[[vendible]]]',
  'VENERACIÓN [[[reverencia]]]',
  'VENERO [[[manantial]]]',
  'VENIA [[[consentimiento]]]',
  'VENUSTEZ [[[hermosura]]]',
  'VENUSTIDAD [[[hermosura]]]',
  'VERDADERO [[[real]]]',
  'VEREDICTO [[[sentencia]]]',
  'VERGEL [[[jardín, oasis]]]',
  'VERIFICACIÓN [[[prueba, comprobación]]]',
  'VERNÁCULO [[[nativo, local]]]',
  'VERNAL [[[primaveral]]]',
  'VEROSÍMIL [[[creíble]]]',
  'VERSADO [[[instruido, perito]]]',
  'VERSÁTIL [[[variable, inconstante]]]',
  'VERTER [[[vaciar]]]',
  'VESANIA [[[demencia]]]',
  'VESPERTINO [[[crepuscular]]]',
  'VESTIGIO [[[huella, reliquia]]]',

  'VETA [[[filón]]]',
  'VETUSTO [[[antiguo, viejo, arcaico]]]',
  'VIANDANTE [[[transeúnte]]]',
  'VIDENCIA [[[clarividencia, perspicacia]]]',
  'VIGA [[[madero]]]',
  'VIGÍA [[[atalaya]]]',
  'VIGILANTE [[[alerta]]]',
  'VIGILIA [[[víspera, vela, ayuno]]]',
  'VIGORIZAR [[[robustecer]]]',
  'VILIPENDIAR [[[despreciar, menospreciar]]]',
  'VÍNCULO [[[unión, atadura, mancomunión]]]',
  'VINDICAR [[[vengar]]]',
  'VIOLAR [[[vulnerar, quebrantar, transgredir]]]',
  'VIOLENTAR [[[forzar]]]',
  'VIPERINO [[[venenoso, dañino]]]',
  'VIRIL [[[enérgico]]]',
  'VIRULENTO [[[ponzoñoso, maligno]]]',
  'VISAJE [[[mueca, guiño, señal]]]',
  'VISAR [[[examinar, refrendar, firmar, reconocer]]]',
  'VISCOSO [[[pegajoso]]]',
  'VISLUMBRAR [[[entrever]]]',
  'VISO [[[aspecto]]]',
  'VITUALLA [[[víveres]]]',
  'VITUPERAR [[[condenar, censurar, reprobar]]]',
  'VITUPERIO [[[vilipendio, reproche, reprobación]]]',
  'VOLATILIZAR [[[vaporizar, esfumarse]]]',
  'VOLICIÓN [[[voluntad]]]',
  'VOLUBLE [[[variable, versátil, inconstante]]]',
  'VOLUPTUOSO [[[apasionado, vehemente]]]',
  'VORÁGINE [[[torbellino, remolino]]]',
  'VÓRTICE [[[torbellino, remolino]]]',
  'VULGAR [[[ordinario, común, plebeyo]]]',
  'VULGARIZAR [[[difundir, divulgar]]]',
  'VULGO [[[pueblo, plebe]]]',
  'VULNERAR [[[infringir, conculcar]]]',
];

export const vocabulario_y = [
  'YACER [[[reposar, descansar, dormir]]]',
  'YELMO [[[casco]]]',
  'YERMO [[[estéril, improductivo, árido, erial]]]',
  'YERRO [[[error]]]',
  'YERTO [[[tieso, rígido]]]',
];

export const vocabulario_x = [
  'XENOFOBIA [[[odio a los extranjeros]]]',
  'XEROCOPIA [[[copia fotográfica]]]',
  'XEROGRAFÍA [[[fotocopia, reproducción]]]',
];

export const vocabulario_z = [
  'ZAFIO [[[grosero, inculto, basto]]]',
  'ZAFIRO [[[corindón]]]',
  'ZAGAL [[[mozo]]]',
  'ZAGUÁN [[[vestíbulo]]]',
  'ZAHERIR [[[reprender, criticar, molestar]]]',
  'ZAHORÍ [[[adivino]]]',
  'ZAHÚRDA [[[pocilga]]]',
  'ZAINO [[[falso, hipócrita, castaño]]]',

  'ZALAMERO [[[adulador, halagüeño]]]',
  'ZALEMA [[[reverencia, zalamería]]]',
  'ZANJAR [[[resolver, solventar, remover]]]',
  'ZAFAR [[[escaparse]]]',
  'ZAFIEDAD [[[tosquedad]]]',
  'ZARANDA [[[criba, hornero]]]',
  'ZARANDEAR [[[agitar, sacudir, ajetrear]]]',
  'ZARANDAJAS [[[menudencias]]]',
  'ZARRAPASTROSO [[[andrajoso, sucio, mugriento]]]',
  'ZOCATO [[[zurdo]]]',
  'ZOCO [[[mercado]]]',
  'ZOZOBRA [[[inquietud, ansiedad]]]',
  'ZOZOBRAR [[[naufragar, acongojarse]]]',
  'ZUMBA [[[chunga, guasa]]]'
];
