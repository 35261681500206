export const atajos_navegador = [
'Ctrl + N [[[Nueva ventana]]]',
'Ctrl + T [[[Nueva pestaña]]]',
'Ctrl + W / Ctrl+F4 [[[Cerrar pestaña]]]',
'Ctrl + Número pestaña [[[Selecciona una pestaña]]]',
'Ctrl + Tab [[[Cambia siguiente pestaña]]]',
'Ctrl+Shift+Tab [[[Pestaña anterior]]]',
'Ctrl + Shift+T [[[Abrir última pestaña cerrada]]]',
'Alt + lnicio [[[Abrir Página inicio]]]',
'Ctrl + F [[[Buscar]]]',
'Ctrl + H [[[Historial]]]',
'Ctrl + J [[[Descargas]]]',
'Ctrl + D [[[Marcar favorito]]]',
'Ctrl + P [[[Imprimir]]]',
'Ctrl + L / Alt+D [[[Barra direcciones]]]',
'Ctrl + R / F5 [[[Actualizar]]]',
'Ctrl + Shift + Supr [[[Eliminar datos de navegación]]]',
'Ctrl + Mayús + P [[[Abrir navegacción privada]]]',
'Ctrl + -/Ctrl + Rueda ratón [[[Disminuir zoom]]]',
'Ctrl + +/Ctrl + Rueda ratón [[[Aumentar zoom]]]',
'F11 [[[Pantalla completa]]]',
'Ctrl + A [[[Seleccionar todo]]]',
]