export const consejerias = [
  'Consejería de la [[[Presidencia]]], [[[Interior]]], [[[Diálogo Social]]] y Simplificación Administrativa.',
  'Consejería de [[[Economía]]], [[[Hacienda]]] y [[[Fondos Europeos]]].',
  'Consejería de [[[Desarrollo Educativo]]] y [[[Formación Profesional]]].',
  'Consejería de [[[Empleo]]], [[[Empresas]]] y [[[Trabajadores Autónomos]]].',
  'Consejería de [[[Salud]]] y [[[Consumo]]].',
  'Consejería de [[[Agricultura]]], [[[Agua]]] y [[[Desarrollo Rural]]].',
  'Consejería de [[[Universidad]]], [[[Investigación]]] e [[[Innovación]]].',
  'Consejería de [[[Turismo]]], [[[Cultura]]] y [[[Deporte]]].',
  'Consejería de [[[Fomento]]], [[[Articulación del Territorio]]] y [[[Vivienda]]].',
  'Consejería de [[[Integración Social]]], [[[Juventud]]], [[[Familias]]] e [[[Igualdad]]].',
  'Consejería de [[[Sostenibilidad]]], [[[Medio Ambiente]]] y [[[Economía Azul]]].',
  'Consejería de [[[Política Industrial]]] y [[[Energía]]].',
  'Consejería de [[[Justicia]]], [[[Administración Local]]] y [[[Función Pública]]].',
];