export const t0 = [
  "Artículo 1: 1. España se constituye en un [[[Estado social y democrático]]] de [[[Derecho]]], que propugna como valores superiores de su ordenamiento jurídico la [[[libertad]]], la [[[justicia]]], la [[[igualdad]]] y el [[[pluralismo político]]].",
  "Artículo 1: 2. La [[[soberanía nacional]]] reside en [[[el pueblo español]]], del que emanan [[[los poderes del Estado]]].",
  "Artículo 1: 3. La [[[forma política]]] del Estado español es [[[la Monarquía parlamentaria]]].",
  "Artículo 2: La Constitución se fundamenta en [[[la indisoluble unidad]]] de la Nación española, patria [[[común e indivisible]]] de todos los españoles, y reconoce y garantiza el derecho a [[[la autonomía de las nacionalidades y regiones]]] que la integran y [[[la solidaridad]]] entre todas ellas.",
  "Artículo 3: 1. El castellano es [[[la lengua española oficial]]] del Estado. Todos los españoles tienen [[[el deber]]] de conocerla y [[[el derecho]]] a usarla.",
  "Artículo 3: 2. Las demás lenguas españolas serán también oficiales en las respectivas [[[Comunidades Autónomas]]] de acuerdo con [[[sus Estatutos]]].",
  "Artículo 3: 3. La riqueza de las distintas [[[modalidades lingüísticas]]] de España es un [[[patrimonio cultural]]] que será objeto de [[[especial respeto y protección]]].",
  "Artículo 4: 1. La bandera de España está formada por tres franjas horizontales, roja, amarilla y roja, siendo la amarilla [[[de doble anchura]]] que cada una de las rojas.",
  "Artículo 4: 2. Los Estatutos podrán reconocer [[[banderas y enseñas]]] propias de las Comunidades Autónomas. Estas se utilizarán junto a la bandera de España en [[[sus edificios públicos]]] y en [[[sus actos oficiales]]].",
  "Artículo 5: La capital del Estado es [[[la villa de Madrid]]].",
  "Artículo 6: Los [[[partidos políticos]]] expresan [[[el pluralismo político]]], concurren a [[[la formación y manifestación]]] de [[[la voluntad popular]]] y son [[[instrumento fundamental]]] para la participación política. Su creación y el ejercicio de su actividad son [[[libres dentro del respeto a la Constitución y a la ley]]]. Su [[[estructura interna y funcionamiento]]] deberán ser [[[democráticos]]].",
  "Artículo 7: Los [[[sindicatos de trabajadores]]] y las [[[asociaciones empresariales]]] contribuyen a la [[[defensa y promoción]]] de los [[[intereses económicos]]] y [[[sociales]]] que les son propios. Su creación y el ejercicio de su actividad son [[[libres dentro del respeto a la Constitución y a la ley]]]. Su [[[estructura interna y funcionamiento]]] deberán ser [[[democráticos]]].",
  "Artículo 8: 1. Las [[[Fuerzas Armadas]]], constituidas por [[[el Ejército de Tierra]]], [[[la Armada]]] y [[[el Ejército del Aire]]], tienen como misión [[[garantizar la soberanía e independencia de España]]], [[[defender su integridad territorial]]] y [[[el ordenamiento constitucional]]].",
  "Artículo 8: 2. [[[Una ley orgánica]]] regulará [[[las bases de la organización militar]]] conforme a [[[los principios de la presente Constitución]]].",
  "Artículo 9: 1. Los [[[ciudadanos y los poderes públicos]]] están sujetos a la Constitución y [[[al resto del ordenamiento jurídico]]].",
  "Artículo 9: 2. Corresponde a [[[los poderes públicos]]] promover [[[las condiciones]]] para que [[[la libertad y la igualdad]]] del [[[individuo y de los grupos]]] en que se integra sean [[[reales y efectivas]]]; remover los obstáculos que [[[impidan o dificulten su plenitud]]] y [[[facilitar la participación]]] de todos los ciudadanos en la vida [[[política]]], [[[económica]]], [[[cultural]]] y [[[social]]].",
  "Artículo 9: 3. La Constitución garantiza el principio de [[[legalidad]]], la [[[jerarquía normativa]]], la [[[publicidad de las normas]]], la [[[irretroactividad]]] de las [[[disposiciones sancionadoras no favorables o restrictivas]]] de [[[derechos individuales]]], la [[[seguridad jurídica]]], la [[[responsabilidad]]] y la [[[interdicción]]] de la arbitrariedad de los poderes públicos.",
];

export const tX = [
  "Artículo 166: La iniciativa de [[[reforma constitucional]]] se ejercerá en los términos previstos en [[[los apartados 1 y 2 del artículo 87]]].",
  "Artículo 167: 1. Los proyectos de reforma constitucional deberán ser aprobados por [[[una mayoría de tres quintos]]] de [[[cada una de las Cámaras]]]. Si no hubiera acuerdo entre ambas, se intentará obtenerlo mediante [[[la creación de una Comisión]]] de [[[composición paritaria de Diputados y Senadores]]], que presentará un texto que será votado por [[[el Congreso y el Senado]]].",
  "Artículo 167: 2. De no lograrse la aprobación mediante el procedimiento del apartado anterior, y siempre que el texto hubiere [[[obtenido el voto favorable]]] de [[[la mayoría absoluta del Senado]]], [[[el Congreso]]], por [[[mayoría de dos tercios]]], podrá aprobar la reforma.",
  "Artículo 167: 3. Aprobada la reforma por [[[las Cortes Generales]]], será sometida a [[[referéndum para su ratificación]]] cuando así lo soliciten, dentro de [[[los quince]]] días siguientes a [[[su aprobación]]], una [[[décima parte de los miembros]]] de [[[cualquiera de las Cámaras]]].",
  "Artículo 168: 1. Cuando se propusiere la [[[revisión total de la Constitución]]] o [[[una parcial]]] que afecte al [[[Título preliminar]]], al [[[Capítulo segundo]]], [[[Sección primera del Título I]]], o [[[al Título II]]], se procederá a la aprobación del principio por [[[mayoría de dos tercios]]] de [[[cada Cámara]]], y a la [[[disolución inmediata de las Cortes]]].",
  "Artículo 168: 2. Las Cámaras elegidas deberán [[[ratificar la decisión]]] y [[[proceder al estudio del nuevo texto constitucional]]], que deberá ser aprobado por ma[[[yoría de dos tercios de ambas Cámaras]]].",
  "Artículo 168: 3. Aprobada la reforma por [[[las Cortes Generales]]], será sometida a [[[referéndum para su ratificación]]].",
  "Artículo 169: No podrá iniciarse la reforma constitucional en [[[tiempo de guerra]]] o [[[de vigencia de alguno de los estados previstos en el artículo 116]]].",
  "DISPOSICIONES ADICIONALES: Primera: La Constitución ampara y respeta los [[[derechos históricos de los territorios forales]]].",
  "DISPOSICIONES ADICIONALES: Primera: La actualización general de dicho régimen foral se llevará a cabo, en su caso, en el marco de la Constitución y de [[[los Estatutos de Autonomía]]].",
  "DISPOSICIONES ADICIONALES: Segunda: La declaración de [[[mayoría de edad]]] contenida en [[[el artículo 12]]] de esta Constitución no perjudica las situaciones amparadas por los derechos forales en el [[[ámbito del Derecho privado]]].",
  "DISPOSICIONES ADICIONALES: Tercera: La modificación del [[[régimen económico y fiscal]]] del [[[archipiélago canario]]] requerirá [[[informe previo]]] de la Comunidad Autónoma o, en su caso, del [[[órgano provisional autonómico]]].",
  "DISPOSICIONES ADICIONALES: Cuarta: En las Comunidades Autónomas donde tengan su sede más de una [[[Audiencia Territorial]]], [[[los Estatutos de Autonomía]]] respectivos podrán mantener las existentes, distribuyendo las [[[competencias]]] entre ellas, siempre de conformidad con lo previsto en la [[[Ley Orgánica del Poder Judicial]]] y dentro de la [[[unidad e independencia]]] de éste.",
  "DISPOSICIONES TRANSITORIAS: Primera: En los territorios dotados de [[[un régimen provisional de autonomía]]], sus órganos [[[colegiados superiores]]], mediante acuerdo adoptado por la [[[mayoría absoluta]]] de sus miembros, podrán sustituir la iniciativa que el apartado [[[2 del artículo 143]]] atribuye a las [[[Diputaciones Provinciales]]] o a los órganos [[[interinsulares correspondientes]]].",
  "DISPOSICIONES TRANSITORIAS: Segunda: Los territorios que en el pasado hubiesen plebiscitado afirmativamente proyectos de [[[Estatuto de autonomía]]] y cuenten, al tiempo de promulgarse esta Constitución, con [[[regímenes provisionales de autonomía]]] podrán proceder inmediatamente en la forma que se prevé en el apartado [[[2 del artículo 148]]], cuando así lo acordaren, por [[[mayoría absoluta]]], sus órganos [[[preautonómicos colegiados superiores]]], comunicándolo al Gobierno. El proyecto de [[[Estatuto]]] será elaborado de acuerdo con lo establecido en [[[el artículo 151, número 2]]], a convocatoria del órgano [[[colegiado preautonómico]]].",
  "DISPOSICIONES TRANSITORIAS: Tercera: La iniciativa del proceso autonómico por parte de [[[las Corporaciones locales]]] o de sus miembros, prevista en el apartado [[[2 del artículo 143]]], se entiende diferida, con todos sus efectos, hasta la celebración de [[[las primeras elecciones locales]]] una vez vigente la Constitución.",
  "DISPOSICIONES TRANSITORIAS: Cuarta: 1. En el caso de [[[Navarra]]], y a efectos de su incorporación al [[[Consejo General Vasco]]] o al [[[régimen autonómico vasco]]] que le sustituya, en lugar de lo que establece el artículo [[[143]]] de la Constitución, la iniciativa corresponde al [[[Órgano Foral]]] competente, el cual adoptará su decisión por [[[mayoría de los miembros]]] que lo componen. Para la validez de dicha iniciativa será preciso, además, que la decisión del Órgano Foral competente sea ratificada por [[[referéndum]]] expresamente convocado al efecto, y aprobado por [[[mayoría de los votos válidos emitidos]]].",
  "DISPOSICIONES TRANSITORIAS: Cuarta: 2. Si la iniciativa no prosperase, solamente se podrá reproducir la misma en [[[distinto período]]] del mandato del [[[Órgano Foral]]] competente, y en todo caso, cuando haya transcurrido el plazo mínimo que establece el artículo [[[143]]].",
  "DISPOSICIONES TRANSITORIAS: Quinta: Las ciudades de [[[Ceuta y Melilla]]] podrán constituirse en Comunidades Autónomas si así lo deciden sus respectivos [[[Ayuntamientos]]], mediante acuerdo adoptado por la [[[mayoría absoluta]]] de sus miembros y así lo autorizan las [[[Cortes Generales]]], mediante una [[[ley orgánica]]], en los términos previstos en el artículo [[[144]]].",
  "DISPOSICIONES TRANSITORIAS: Sexta: Cuando se remitieran a la [[[Comisión Constitucional del Congreso]]] varios proyectos de [[[Estatuto]]], se dictaminarán por [[[el orden de entrada]]] en aquélla, y el plazo de [[[dos meses]]] a que se refiere el artículo [[[151]]] empezará a contar desde que la Comisión termine el estudio del proyecto o proyectos de que sucesivamente haya conocido.",
  "DISPOSICIONES TRANSITORIAS: Séptima: Los organismos provisionales autonómicos se considerarán disueltos en los siguientes casos: a) Una vez constituidos los órganos que establezcan [[[los Estatutos de Autonomía]]] aprobados conforme a esta Constitución.",
  "DISPOSICIONES TRANSITORIAS: Séptima: Los organismos provisionales autonómicos se considerarán disueltos en los siguientes casos: b) En el supuesto de que la iniciativa del proceso autonómico no llegara a prosperar por no cumplir los requisitos previstos en el artículo [[[143]]].",
  "DISPOSICIONES TRANSITORIAS: Séptima: Los organismos provisionales autonómicos se considerarán disueltos en los siguientes casos: c) Si el organismo no hubiera ejercido el derecho que le reconoce la [[[disposición transitoria primera]]] en el plazo de [[[tres años]]].",
  "DISPOSICIONES TRANSITORIAS: Octava: 1. Las Cámaras que han aprobado la presente Constitución asumirán, tras la entrada en vigor de la misma, las [[[funciones y competencias]]] que en ella se señalan, respectivamente, para el [[[Congreso y el Senado]]], sin que en ningún caso el mandato se extienda más allá del [[[15 de junio de 1981]]].",
  "DISPOSICIONES TRANSITORIAS: Octava: 2. A los efectos de lo establecido en el artículo [[[99]]], la promulgación de la Constitución se considerará como [[[supuesto constitucional]]] en el que procede su aplicación. A tal efecto, a partir de la citada promulgación se abrirá un período de [[[treinta días]]] para la aplicación de lo dispuesto en dicho artículo. Durante este período, el actual [[[Presidente del Gobierno]]], que asumirá las [[[funciones y competencias]]] que para dicho cargo establece la Constitución, podrá optar por utilizar la facultad que le reconoce el artículo [[[115]]] o dar paso, mediante la [[[dimisión]]], a la aplicación de lo establecido en el artículo [[[99]]], quedando en este último caso en la situación prevista en el apartado [[[2 del artículo 101]]].",
  "DISPOSICIONES TRANSITORIAS: Octava: 3. En caso de [[[disolución]]], de acuerdo con lo previsto en el artículo [[[115]]], y si no se hubiera desarrollado legalmente lo previsto en los artículos [[[68 y 69]]], serán de aplicación en las elecciones las normas vigentes con anterioridad, con las solas excepciones de que en lo referente a [[[inelegibilidades e incompatibilidades]]] se aplicará directamente lo previsto en el inciso [[[segundo]]] de la letra [[[b)]]] del apartado [[[1]]] del artículo [[[70]]] de la Constitución, así como lo dispuesto en la misma respecto a la edad para el voto y lo establecido en el artículo [[[69.3]]].",
  "DISPOSICIONES TRANSITORIAS: Novena: A los [[[tres años]]] de la elección por vez primera de los miembros del [[[Tribunal Constitucional]]], se procederá por [[[sorteo]]] para la designación de un grupo de [[[cuatro]]] miembros de la misma procedencia electiva que haya de cesar y renovarse. A estos solos efectos se entenderán agrupados como miembros de la misma procedencia a los [[[dos]]] designados a propuesta del Gobierno y a los [[[dos]]] que proceden de la formulada por el [[[Consejo General del Poder Judicial]]]. Del mismo modo se procederá transcurridos otros [[[tres]]] años entre los dos grupos no afectados por el sorteo anterior. A partir de entonces se estará a lo establecido en el número [[[3 del artículo 159]]].",
  "DISPOSICIÓN DEROGATORIA: 1. Queda derogada la Ley [[[1/1977]]], de 4 de enero, para la [[[Reforma Política]]], así como, en tanto en cuanto no estuvieran ya derogadas por la anteriormente mencionada Ley, la de [[[Principios del Movimiento Nacional]]], de [[[17 de mayo de 1958]]]; el [[[Fuero de los Españoles]]], de [[[17 de julio de 1945]]]; el del [[[Trabajo]]], de [[[9 de marzo de 1938]]]; la [[[Ley Constitutiva de las Cortes]]], de [[[17 de julio de 1942]]]; la [[[Ley de Sucesión de la Jefatura del Estado]]], de [[[26 de julio de 1947]]], todas ellas modificadas por la [[[Ley Orgánica del Estado]]], de [[[10 de enero de 1967]]], y en los mismos términos esta última y la de [[[Referéndum Nacional]]] de [[[22 de octubre de 1945]]].",
  "DISPOSICIÓN DEROGATORIA: 2. En tanto en cuanto pudiera conservar alguna vigencia, se considera definitivamente derogada la Ley de [[[25 de octubre de 1839]]] en lo que pudiera afectar a las provincias de [[[Álava]]], [[[Guipúzcoa]]] y [[[Vizcaya]]]. En los mismos términos se considera definitivamente derogada la Ley de [[[21 de julio de 1876]]].",
  "DISPOSICIÓN DEROGATORIA: 3. Asimismo quedan derogadas cuantas disposiciones se [[[opongan]]] a lo establecido en esta Constitución.",
  "DISPOSICIÓN FINAL: Esta Constitución entrará en vigor el mismo día de [[[la publicación de su texto oficial]]] en el [[[Boletín Oficial del Estado]]]. Se publicará también en las demás [[[lenguas]]] de España.Publicadas en los suplementos del «B.O.E.» de [[[29 de diciembre de 1978]]], las versiones en lenguas [[[Balear]]], [[[Catalana]]], [[[Gallega]]], [[[Valenciana]]] y [[[Vasca]]]. Por tanto, Mando a todos los Españoles, particulares y [[[autoridades]]], que guarden y hagan guardar esta Constitución como norma [[[fundamental]]] del Estado.",
];

export const tI = [
  "Artículo 10: 1. La dignidad de la persona, los derechos inviolables que le son inherentes, el libre desarrollo de la personalidad, el respeto a la ley y a los derechos de los demás son fundamento del orden político y de la paz social.: ",
  "Artículo 10: 2. Las normas relativas a los derechos fundamentales y a las libertades que la Constitución reconoce se interpretarán de conformidad con la Declaración Universal de Derechos Humanos y los tratados y acuerdos internacionales sobre las mismas materias ratificados por España.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 11: 1. La nacionalidad española se adquiere, se conserva y se pierde de acuerdo con lo establecido por la ley.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 11: 2. Ningún español de origen podrá ser privado de su nacionalidad.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 11: 3. El Estado podrá concertar tratados de doble nacionalidad con los países iberoamericanos o con aquellos que hayan tenido o tengan una particular vinculación con España. En estos mismos países, aun cuando no reconozcan a sus ciudadanos un derecho recíproco, podrán naturalizarse los españoles sin perder su nacionalidad de origen.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 12: Los españoles son mayores de edad a los dieciocho años.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 13: 1. Los extranjeros gozarán en España de las libertades públicas que garantiza el presente Título en los términos que establezcan los tratados y la ley.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 13: 2. Solamente los españoles serán titulares de los derechos reconocidos en el artículo 23, salvo lo que, atendiendo a criterios de reciprocidad, pueda establecerse por tratado o ley para el derecho de sufragio activo y pasivo en las elecciones municipales.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 13: 3. La extradición sólo se concederá en cumplimiento de un tratado o de la ley, atendiendo al principio de reciprocidad. Quedan excluidos de la extradición los delitos políticos, no considerándose como tales los actos de terrorismo.",
  "CAPÍTULO PRIMERO DE LOS ESPAÑOLES Y LOS EXTRANJEROS: Artículo 13: 4. La ley establecerá los términos en que los ciudadanos de otros países y los apátridas podrán gozar del derecho de asilo en España.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: Artículo 14: Los españoles son iguales ante la ley, sin que pueda prevalecer discriminación alguna por razón de nacimiento, raza, sexo, religión, opinión o cualquier otra condición o circunstancia personal o social.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 15: Todos tienen derecho a la vida y a la integridad física y moral, sin que, en ningún caso, puedan ser sometidos a tortura ni a penas o tratos inhumanos o degradantes. Queda abolida la pena de muerte, salvo lo que puedan disponer las leyes penales militares para tiempos de guerra.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 16: 1. Se garantiza la libertad ideológica, religiosa y de culto de los individuos y las comunidades sin más limitación, en sus manifestaciones, que la necesaria para el mantenimiento del orden público protegido por la ley.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 16: 2. Nadie podrá ser obligado a declarar sobre su ideología, religión o creencias.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 16: 3. Ninguna confesión tendrá carácter estatal. Los poderes públicos tendrán en cuenta las creencias religiosas de la sociedad española y mantendrán las consiguientes relaciones de cooperación con la Iglesia Católica y las demás confesiones.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 17: 1. Toda persona tiene derecho a la libertad y a la seguridad. Nadie puede ser privado de su libertad, sino con la observancia de lo establecido en este artículo y en los casos y en la forma previstos en la ley.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 17: 2. La detención preventiva no podrá durar más del tiempo estrictamente necesario para la realización de las averiguaciones tendentes al esclarecimiento de los hechos, y, en todo caso, en el plazo máximo de setenta y dos horas, el detenido deberá ser puesto en libertad o a disposición de la autoridad judicial.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 17: 3. Toda persona detenida debe ser informada de forma inmediata, y de modo que le sea comprensible, de sus derechos y de las razones de su detención, no pudiendo ser obligada a declarar. Se garantiza la asistencia de abogado al detenido en las diligencias policiales y judiciales, en los términos que la ley establezca.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 17: 4. La ley regulará un procedimiento de \"habeas corpus\" para producir la inmediata puesta a disposición judicial de toda persona detenida ilegalmente. Asimismo, por ley se determinará el plazo máximo de duración de la prisión provisional.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 18: 1. Se garantiza el derecho al honor, a la intimidad personal y familiar y a la propia imagen.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 18: 2. El domicilio es inviolable. Ninguna entrada o registro podrá hacerse en él sin consentimiento del titular o resolución judicial, salvo en caso de flagrante delito.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 18: 3. Se garantiza el secreto de las comunicaciones y, en especial, de las postales, telegráficas y telefónicas, salvo resolución judicial.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 18: 4. La ley limitará el uso de la informática para garantizar el honor y la intimidad personal y familiar de los ciudadanos y el pleno ejercicio de sus derechos.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 19: Los españoles tienen derecho a elegir libremente su residencia y a circular por el territorio nacional. Asimismo, tienen derecho a entrar y salir libremente de España en los términos que la ley establezca. Este derecho no podrá ser limitado por motivos políticos o ideológicos.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 1. Se reconocen y protegen los derechos: a) A expresar y difundir libremente los pensamientos, ideas y opiniones mediante la palabra, el escrito o cualquier otro medio de reproducción.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 1. Se reconocen y protegen los derechos: b) A la producción y creación literaria, artística, científica y técnica.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 1. Se reconocen y protegen los derechos: c) A la libertad de cátedra.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 1. Se reconocen y protegen los derechos: d) A comunicar o recibir libremente información veraz por cualquier medio de difusión. La ley regulará el derecho a la cláusula de conciencia y al secreto profesional en el ejercicio de estas libertades.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 2. El ejercicio de estos derechos no puede restringirse mediante ningún tipo de censura previa.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 3. La ley regulará la organización y el control parlamentario de los medios de comunicación social dependientes del Estado o de cualquier ente público y garantizará el acceso a dichos medios de los grupos sociales y políticos significativos, respetando el pluralismo de la sociedad y de las diversas lenguas de España.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 4. Estas libertades tienen su límite en el respeto a los derechos reconocidos en este Título, en los preceptos de las leyes que lo desarrollen y, especialmente, en el derecho al honor, a la intimidad, a la propia imagen y a la protección de la juventud y de la infancia.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 20: 5. Sólo podrá acordarse el secuestro de publicaciones, grabaciones y otros medios de información en virtud de resolución judicial.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 21: 1. Se reconoce el derecho de reunión pacífica y sin armas. El ejercicio de este derecho no necesitará autorización previa.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 21: 2. En los casos de reuniones en lugares de tránsito público y manifestaciones se dará comunicación previa a la autoridad, que sólo podrá prohibirlas cuando existan razones fundadas de alteración del orden público, con peligro para personas o bienes.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 22: 1. Se reconoce el derecho de asociación.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 22: 2. Las asociaciones que persigan fines o utilicen medios tipificados como delito son ilegales.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 22: 3. Las asociaciones constituidas al amparo de este artículo deberán inscribirse en un registro a los solos efectos de publicidad.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 22: 4. Las asociaciones sólo podrán ser disueltas o suspendidas en sus actividades en virtud de resolución judicial motivada.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 22: 5. Se prohíben las asociaciones secretas y las de carácter paramilitar.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 23: 1. Los ciudadanos tienen el derecho a participar en los asuntos públicos, directamente o por medio de representantes, libremente elegidos en elecciones periódicas por sufragio universal.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 23: 2. Asimismo, tienen derecho a acceder en condiciones de igualdad a las funciones y cargos públicos, con los requisitos que señalen las leyes.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 24: 1. Todas las personas tienen derecho a obtener la tutela efectiva de los jueces y tribunales en el ejercicio de sus derechos e intereses legítimos, sin que, en ningún caso, pueda producirse indefensión.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 24: 2. Asimismo, todos tienen derecho al Juez ordinario predeterminado por la ley, a la defensa y a la asistencia de letrado, a ser informados de la acusación formulada contra ellos, a un proceso público sin dilaciones indebidas y con todas las garantías, a utilizar los medios de prueba pertinentes para su defensa, a no declarar contra sí mismos, a no confesarse culpables y a la presunción de inocencia.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 24: La ley regulará los casos en que, por razón de parentesco o de secreto profesional, no se estará obligado a declarar sobre hechos presuntamente delictivos.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 25: 1. Nadie puede ser condenado o sancionado por acciones u omisiones que en el momento de producirse no constituyan delito, falta o infracción administrativa, según la legislación vigente en aquel momento.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 25: 2. Las penas privativas de libertad y las medidas de seguridad estarán orientadas hacia la reeducación y reinserción social y no podrán consistir en trabajos forzados. El condenado a pena de prisión que estuviere cumpliendo la misma gozará de los derechos fundamentales de este Capítulo, a excepción de los que se vean expresamente limitados por el contenido del fallo condenatorio, el sentido de la pena y la ley penitenciaria. En todo caso, tendrá derecho a un trabajo remunerado y a los beneficios correspondientes de la Seguridad Social, así como al acceso a la cultura y al desarrollo integral de su personalidad.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 25: 3. La Administración civil no podrá imponer sanciones que, directa o subsidiariamente, impliquen privación de libertad.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 26: Se prohíben los Tribunales de Honor en el ámbito de la Administración civil y de las organizaciones profesionales.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 1. Todos tienen el derecho a la educación. Se reconoce la libertad de enseñanza.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 2. La educación tendrá por objeto el pleno desarrollo de la personalidad humana en el respeto a los principios democráticos de convivencia y a los derechos y libertades fundamentales.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 3. Los poderes públicos garantizan el derecho que asiste a los padres para que sus hijos reciban la formación religiosa y moral que esté de acuerdo con sus propias convicciones.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 4. La enseñanza básica es obligatoria y gratuita.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 5. Los poderes públicos garantizan el derecho de todos a la educación, mediante una programación general de la enseñanza, con participación efectiva de todos los sectores afectados y la creación de centros docentes.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 6. Se reconoce a las personas físicas y jurídicas la libertad de creación de centros docentes, dentro del respeto a los principios constitucionales.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 7. Los profesores, los padres y, en su caso, los alumnos intervendrán en el control y gestión de todos los centros sostenidos por la Administración con fondos públicos, en los términos que la ley establezca.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 8. Los poderes públicos inspeccionarán y homologarán el sistema educativo para garantizar el cumplimiento de las leyes.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 9. Los poderes públicos ayudarán a los centros docentes que reúnan los requisitos que la ley establezca.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 27: 10. Se reconoce la autonomía de las Universidades, en los términos que la ley establezca.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 28: 1. Todos tienen derecho a sindicarse libremente. La ley podrá limitar o exceptuar el ejercicio de este derecho a las Fuerzas o Institutos armados o a los demás Cuerpos sometidos a disciplina militar y regulará las peculiaridades de su ejercicio para los funcionarios públicos. La libertad sindical comprende el derecho a fundar sindicatos y a afiliarse al de su elección, así como el derecho de los sindicatos a formar confederaciones y a fundar organizaciones sindicales internacionales o afiliarse a las mismas. Nadie podrá ser obligado a afiliarse a un sindicato.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 28: 2. Se reconoce el derecho a la huelga de los trabajadores para la defensa de sus intereses. La ley que regule el ejercicio de este derecho establecerá las garantías precisas para asegurar el mantenimiento de los servicios esenciales de la comunidad.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 29: 1. Todos los españoles tendrán el derecho de petición individual y colectiva, por escrito, en la forma y con los efectos que determine la ley.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 1: De los derechos fundamentales y de las libertades públicas: Artículo 29: 2. Los miembros de las Fuerzas o Institutos armados o de los Cuerpos sometidos a disciplina militar podrán ejercer este derecho sólo individualmente y con arreglo a lo dispuesto en su legislación específica.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 30: 1. Los españoles tienen el derecho y el deber de defender a España.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 30: 2. La ley fijará las obligaciones militares de los españoles y regulará, con las debidas garantías, la objeción de conciencia, así como las demás causas de exención del servicio militar obligatorio, pudiendo imponer, en su caso, una prestación social sustitutoria.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 30: 3. Podrá establecerse un servicio civil para el cumplimiento de fines de interés general.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 30: 4. Mediante ley podrán regularse los deberes de los ciudadanos en los casos de grave riesgo, catástrofe o calamidad pública.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 31: 1. Todos contribuirán al sostenimiento de los gastos públicos de acuerdo con su capacidad económica mediante un sistema tributario justo inspirado en los principios de igualdad y progresividad que, en ningún caso, tendrá alcance confiscatorio.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 31: 2. El gasto público realizará una asignación equitativa de los recursos públicos, y su programación y ejecución responderán a los criterios de eficiencia y economía.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 31: 3. Sólo podrán establecerse prestaciones personales o patrimoniales de carácter público con arreglo a la ley.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 32: 1. El hombre y la mujer tienen derecho a contraer matrimonio con plena igualdad jurídica.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 32: 2. La ley regulará las formas de matrimonio, la edad y capacidad para contraerlo, los derechos y deberes de los cónyuges, las causas de separación y disolución y sus efectos.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 33: 1. Se reconoce el derecho a la propiedad privada y a la herencia.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 33: 2. La función social de estos derechos delimitará su contenido, de acuerdo con las leyes.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 33: 3. Nadie podrá ser privado de sus bienes y derechos sino por causa justificada de utilidad pública o interés social, mediante la correspondiente indemnización y de conformidad con lo dispuesto por las leyes.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 34: 1. Se reconoce el derecho de fundación para fines de interés general, con arreglo a la ley.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 34: 2. Regirá también para las fundaciones lo dispuesto en los apartados 2 y 4 del artículo 22.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 35: 1. Todos los españoles tienen el deber de trabajar y el derecho al trabajo, a la libre elección de profesión u oficio, a la promoción a través del trabajo y a una remuneración suficiente para satisfacer sus necesidades y las de su familia, sin que en ningún caso pueda hacerse discriminación por razón de sexo.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 35: 2. La ley regulará un estatuto de los trabajadores.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 36: La ley regulará las peculiaridades propias del régimen jurídico de los Colegios Profesionales y el ejercicio de las profesiones tituladas. La estructura interna y el funcionamiento de los Colegios deberán ser democráticos.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 37: 1. La ley garantizará el derecho a la negociación colectiva laboral entre los representantes de los trabajadores y empresarios, así como la fuerza vinculante de los convenios.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 37: 2. Se reconoce el derecho de los trabajadores y empresarios a adoptar medidas de conflicto colectivo. La ley que regule el ejercicio de este derecho, sin perjuicio de las limitaciones que pueda establecer, incluirá las garantías precisas para asegurar el funcionamiento de los servicios esenciales de la comunidad.",
  "CAPÍTULO II DERECHOS Y LIBERTADES: SECCIÓN 2: De los derechos y deberes de los ciudadanos: Artículo 38: Se reconoce la libertad de empresa en el marco de la economía de mercado. Los poderes públicos garantizan y protegen su ejercicio y la defensa de la productividad, de acuerdo con las exigencias de la economía general y, en su caso, de la planificación.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 39: 1. Los poderes públicos aseguran la protección social, económica y jurídica de la familia.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 39: 2. Los poderes públicos aseguran, asimismo, la protección integral de los hijos, iguales éstos ante la ley con independencia de su filiación, y de las madres, cualquiera que sea su estado civil. La ley posibilitará la investigación de la paternidad.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 39: 3. Los padres deben prestar asistencia de todo orden a los hijos habidos dentro o fuera del matrimonio, durante su minoría de edad y en los demás casos en que legalmente proceda.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 39: 4. Los niños gozarán de la protección prevista en los acuerdos internacionales que velan por sus derechos.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 40: 1. Los poderes públicos promoverán las condiciones favorables para el progreso social y económico y para una distribución de la renta regional y personal más equitativa, en el marco de una política de estabilidad económica. De manera especial realizarán una política orientada al pleno empleo.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 40: 2. Asimismo, los poderes públicos fomentarán una política que garantice la formación y readaptación profesionales; velarán por la seguridad e higiene en el trabajo y garantizarán el descanso necesario, mediante la limitación de la jornada laboral, las vacaciones periódicas retribuidas y la promoción de centros adecuados.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 41: Los poderes públicos mantendrán un régimen público de Seguridad Social para todos los ciudadanos que garantice la asistencia y prestaciones sociales suficientes ante situaciones de necesidad, especialmente en caso de desempleo. La asistencia y prestaciones complementarias serán libres.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 42: El Estado velará especialmente por la salvaguardia de los derechos económicos y sociales de los trabajadores españoles en el extranjero y orientará su política hacia su retorno.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 43: 1. Se reconoce el derecho a la protección de la salud.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 43: 2. Compete a los poderes públicos organizar y tutelar la salud pública a través de medidas preventivas y de las prestaciones y servicios necesarios. La ley establecerá los derechos y deberes de todos al respecto.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 43: 3. Los poderes públicos fomentarán la educación sanitaria, la educación física y el deporte. Asimismo facilitarán la adecuada utilización del ocio.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 44: 1. Los poderes públicos promoverán y tutelarán el acceso a la cultura, a la que todos tienen derecho.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 44: 2. Los poderes públicos promoverán la ciencia y la investigación científica y técnica en beneficio del interés general.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 45: 1. Todos tienen el derecho a disfrutar de un medio ambiente adecuado para el desarrollo de la persona, así como el deber de conservarlo.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 45: 2. Los poderes públicos velarán por la utilización racional de todos los recursos naturales, con el fin de proteger y mejorar la calidad de la vida y defender y restaurar el medio ambiente, apoyándose en la indispensable solidaridad colectiva.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 45: 3. Para quienes violen lo dispuesto en el apartado anterior, en los términos que la ley fije se establecerán sanciones penales o, en su caso, administrativas, así como la obligación de reparar el daño causado.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 46: Los poderes públicos garantizarán la conservación y promoverán el enriquecimiento del patrimonio histórico, cultural y artístico de los pueblos de España y de los bienes que lo integran, cualquiera que sea su régimen jurídico y su titularidad. La ley penal sancionará los atentados contra este patrimonio.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 47: Todos los españoles tienen derecho a disfrutar de una vivienda digna y adecuada. Los poderes públicos promoverán las condiciones necesarias y establecerán las normas pertinentes para hacer efectivo este derecho, regulando la utilización del suelo de acuerdo con el interés general para impedir la especulación.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 47: La comunidad participará en las plusvalías que genere la acción urbanística de los entes públicos.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 48: Los poderes públicos promoverán las condiciones para la participación libre y eficaz de la juventud en el desarrollo político, social, económico y cultural.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 49: Los poderes públicos realizarán una política de previsión, tratamiento, rehabilitación e integración de los disminuidos físicos, sensoriales y psíquicos, a los que prestarán la atención especializada que requieran y los ampararán especialmente para el disfrute de los derechos que este Título otorga a todos los ciudadanos.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 50: Los poderes públicos garantizarán, mediante pensiones adecuadas y periódicamente actualizadas, la suficiencia económica a los ciudadanos durante la tercera edad. Asimismo, y con independencia de las obligaciones familiares, promoverán su bienestar mediante un sistema de servicios sociales que atenderán sus problemas específicos de salud, vivienda, cultura y ocio.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 51: 1. Los poderes públicos garantizarán la defensa de los consumidores y usuarios, protegiendo, mediante procedimientos eficaces, la seguridad, la salud y los legítimos intereses económicos de los mismos.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 51: 2. Los poderes públicos promoverán la información y la educación de los consumidores y usuarios, fomentarán sus organizaciones y oirán a éstas en las cuestiones que puedan afectar a aquéllos, en los términos que la ley establezca.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 51: 3. En el marco de lo dispuesto por los apartados anteriores, la ley regulará el comercio interior y el régimen de autorización de productos comerciales.",
  "CAPÍTULO III DE LOS PRINCIPIOS RECTORES DE LA POLÍTICA SOCIAL Y ECONÓMICA: Artículo 52: La ley regulará las organizaciones profesionales que contribuyan a la defensa de los intereses económicos que les sean propios. Su estructura interna y funcionamiento deberán ser democráticos.",
  "CAPÍTULO IV DE LAS GARANTÍAS DE LAS LIBERTADES Y DERECHOS FUNDAMENTALES: Artículo 53: 1. Los derechos y libertades reconocidos en el Capítulo segundo del presente Título vinculan a todos los poderes públicos. Sólo por ley, que en todo caso deberá respetar su contenido esencial, podrá regularse el ejercicio de tales derechos y libertades, que se tutelarán de acuerdo con lo previsto en el artículo 161, 1, a).",
  "CAPÍTULO IV DE LAS GARANTÍAS DE LAS LIBERTADES Y DERECHOS FUNDAMENTALES: Artículo 53: 2. Cualquier ciudadano podrá recabar la tutela de las libertades y derechos reconocidos en el artículo 14 y la Sección primera del Capítulo segundo ante los Tribunales ordinarios por un procedimiento basado en los principios de preferencia y sumariedad y, en su caso, a través del recurso de amparo ante el Tribunal Constitucional. Este último recurso será aplicable a la objeción de conciencia reconocida en el artículo 30.",
  "CAPÍTULO IV DE LAS GARANTÍAS DE LAS LIBERTADES Y DERECHOS FUNDAMENTALES: Artículo 53: 3. El reconocimiento, el respeto y la protección de los principios reconocidos en el Capítulo tercero informarán la legislación positiva, la práctica judicial y la actuación de los poderes públicos. Sólo podrán ser alegados ante la Jurisdicción ordinaria de acuerdo con lo que dispongan las leyes que los desarrollen.",
  "CAPÍTULO IV DE LAS GARANTÍAS DE LAS LIBERTADES Y DERECHOS FUNDAMENTALES: Artículo 54: Una ley orgánica regulará la institución del Defensor del Pueblo, como alto comisionado de las Cortes Generales, designado por éstas para la defensa de los derechos comprendidos en este Título, a cuyo efecto podrá supervisar la actividad de la Administración, dando cuenta a las Cortes Generales.",
  "CAPÍTULO V DE LA SUSPENSIÓN DE LOS DERECHOS Y LIBERTADES: Artículo 55: 1. Los derechos reconocidos en los artículos 17, 18, apartados 2 y 3, artículos 19, 20, apartados 1, a) y d), y 5, artículos 21, 28, apartado 2, y artículo 37, apartado 2, podrán ser suspendidos cuando se acuerde la declaración del estado de excepción o de sitio en los términos previstos en la Constitución. Se exceptúa de lo establecido anteriormente el apartado 3 del artículo 17 para el supuesto de declaración de estado de excepción.",
  "CAPÍTULO V DE LA SUSPENSIÓN DE LOS DERECHOS Y LIBERTADES: Artículo 55: 2. Una ley orgánica podrá determinar la forma y los casos en los que, de forma individual y con la necesaria intervención judicial y el adecuado control parlamentario, los derechos reconocidos en los artículos 17, apartado 2, y 18, apartados 2 y 3, pueden ser suspendidos para personas determinadas, en relación con las investigaciones correspondientes a la actuación de bandas armadas o elementos terroristas.",
  "CAPÍTULO V DE LA SUSPENSIÓN DE LOS DERECHOS Y LIBERTADES: Artículo 55: La utilización injustificada o abusiva de las facultades reconocidas en dicha ley orgánica producirá responsabilidad penal, como violación de los derechos y libertades reconocidos por las leyes.",
];