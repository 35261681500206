import { data_leyigualdad } from './data/ley-igualdad.js';
import { data_presupuestos } from './data/presupuestos.js';
import { t0, tI, tX } from './data/constitucion.js';
import { consejerias } from './data/consejerias.js';
import { consejerias_orden } from './data/consejerias.orden.js';
import { atajos_win } from './data/atajos.win.js';
import { atajos_navegador } from './data/atajos.navegador.js';
import { contabilidad_docs } from './data/contabilidad_docs.js';
import { constitucion_articulos } from './data/constitución_articulos.js';
import { estado_t6_atajoscomunes } from './data/estado_t6_atajoscomunes.js'
import { estado_t6_atajoswindows } from './data/estado_t6_atajoswindows.js';
import * as vocabulario from './data/vocabulario.js';

const db = [
  {
    title: 'Constitución: Título preliminar',
    data: t0,
  },
  {
    title: 'Constitución: Título I De los derechos y deberes fundamentales',
    data: tI,
  },
  {
    title: 'Constitución: Título X De la reforma constitucional',
    data: tX,
  },
  {
    title: 'Junta: Ley 12/2007: Igualdad de género.',
    data: data_leyigualdad,
  },
  {
    title: 'Junta: El presupuesto',
    data: data_presupuestos,
  },
  {
    title: 'Junta: Consejerías',
    data: consejerias,
  },
  {
    title: 'Junta: Consejerías (orden)',
    data: consejerias_orden,
    hidePlaceholder: true,
  },
  {
    title: 'Junta: Atajos de Windows',
    data: atajos_win,
    hidePlaceholder: true,
  },
  {
    title: 'Junta: Atajos de navegadores',
    data: atajos_navegador,
    hidePlaceholder: true,
  },
  {
    title: 'Junta: Contabilidad pública - Documentos de apertura/modificación',
    data: contabilidad_docs,
    hidePlaceholder: true
  },
  {
    title: 'Junta: Constitución - Artículos',
    data: constitucion_articulos,
    hidePlaceholder: true
  },
  {
    title: 'Estado: Informática básica - atajos comunes',
    data: estado_t6_atajoscomunes,
    hidePlaceholder: true
  },
  {
    title: 'Estado: Informática básica - atajos Windows',
    data: estado_t6_atajoswindows,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: A',
    data: vocabulario.vocabulario_a,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: B',
    data: vocabulario.vocabulario_b,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: C',
    data: vocabulario.vocabulario_c,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: D',
    data: vocabulario.vocabulario_d,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: E',
    data: vocabulario.vocabulario_e,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: F',
    data: vocabulario.vocabulario_f,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: G',
    data: vocabulario.vocabulario_g,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: H',
    data: vocabulario.vocabulario_h,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: I',
    data: vocabulario.vocabulario_i,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: J',
    data: vocabulario.vocabulario_j,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: L',
    data: vocabulario.vocabulario_l,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: M',
    data: vocabulario.vocabulario_m,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: N',
    data: vocabulario.vocabulario_n,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: O',
    data: vocabulario.vocabulario_o,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: P',
    data: vocabulario.vocabulario_p,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: Q',
    data: vocabulario.vocabulario_q,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: R',
    data: vocabulario.vocabulario_r,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: S',
    data: vocabulario.vocabulario_s,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: T',
    data: vocabulario.vocabulario_t,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: U',
    data: vocabulario.vocabulario_u,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: V',
    data: vocabulario.vocabulario_v,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: Y',
    data: vocabulario.vocabulario_y,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: X',
    data: vocabulario.vocabulario_x,
    hidePlaceholder: true
  },
  {
    title: 'Vocabulario: Z',
    data: vocabulario.vocabulario_z,
    hidePlaceholder: true
  },
];

export default db;

