export const contabilidad_docs = [
  'Z0 [[[Créditos iniciales]]]',
  // 'Z2 [[[Redistribuciones de crédito]]]',
  'Z3 [[[Créditos extraordinarios]]]',
  'Z4 [[[Suplementos de créditos]]]',
  'Z5 [[[Ampliaciones de créditos]]]',
  'Z6 [[[Anualidades futuras]]]',
  'Z7 [[[Incorporaciones de remanentes]]]',
  'Z8 [[[Generaciones de crédito]]]',
  'Z9 [[[Transferencias de crédito]]]',
];