export const consejerias_orden = [
  '[[[1]]] Consejería de la Presidencia, Interior, Diálogo Social y Simplificación Administrativa.',
  '[[[2]]] Consejería de Economía, Hacienda y Fondos Europeos.',
  '[[[3]]] Consejería de Desarrollo Educativo y Formación Profesional.',
  '[[[4]]] Consejería de Empleo, Empresas y Trabajadores Autónomos.',
  '[[[5]]] Consejería de Salud y Consumo.',
  '[[[6]]] Consejería de Agricultura, Agua y Desarrollo Rural.',
  '[[[7]]] Consejería de Universidad, Investigación e Innovación.',
  '[[[8]]] Consejería de Turismo, Cultura y Deporte.',
  '[[[9]]] Consejería de Fomento, Articulación del Territorio y Vivienda.',
  '[[[10]]] Consejería de Integración Social, Juventud, Familias e Igualdad.',
  '[[[11]]] Consejería de Sostenibilidad, Medio Ambiente y Economía Azul.',
  '[[[12]]] Consejería de Política Industrial y Energía.',
  '[[[13]]]Consejería de Justicia, Administración Local y Función Pública.',
];