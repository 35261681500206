export const estado_t6_atajoscomunes = [
  '[[[Ctrl+C]]] Copia',
  '[[[Ctrl+X]]] Corta',
  '[[[Ctrl+V]]] Pega',
  '[[[Ctrl+E]]] Seleccionar todo lo que aparece en la pantalla.',
  '[[[Tob]]] Avanza por los opciones.',
  '[[[Supr]]] Elimina.',
  '[[[Esc]]] Cancela lo tarea actual.',
  '[[[Shift+Supr]]] Elimina directamente un objeto seleccionado sin pasar por lo papelera',
  '[[[Moyús+Supr]]] Elimina el elemento seleccionado permanentemente sin enviarlo o lo papelera',
  '[[[Shift+Tob]]] Mueve hacia atrás por las opciones',
  '[[[Ctrl+Shift+Tob]]] Mueve hacia atrás a través de las pestañas',
  '[[[Ctrl+Alt+Tob]]] Uso las teclas de dirección poro cambiar entre todos los aplicaciones abiertos',
  '[[[Ctrl+Tab]]] Avanzo o través de los pestañas.',
  '[[[Ctrl+Esc]]] Muestra el menú Inicio.',
  '[[[Ctrl+mientras arrastra un elemento]]] Copiar el elemento seleccionado.',
  '[[[Ctrl+Mayús+arrastrar un elemento]]] Crea un acceso directo al elemento seleccionado.',
  '[[[Ctrl+F4]]] Cierra el documento activo en programas que le permiten tener varios documentos abiertos simultáneamente. ',
  '[[[Ctrl+Flecha derecha]]] Mueve el punto de inserción al principio de lo siguiente palabra.',
  '[[[Ctrl+Flecha izquierda]]] Mueve el punto de inserción al principio de lo palabra anterior.',
  '[[[Ctrl+Flecha abajo]]] Mueve el punto de inserción al principio del párrafo siguiente. ',
  '[[[Ctrl+Flecha arriba]]] Mueve el punto de inserción al principio del párrafo anterior',
  '[[[Ctrl+Flecha cualquiera]]] Resalta un bloque de texto',
  '[[[Ctrl+A]]] Seleccionar todos',
  '[[[Alt+Impr Pant]]] Captura pantalla ',
  '[[[Alt+Impr Pant y seguidamente Ctrl+V]]] Inserta en un documento Word una captura de pantalla limitada a una ventana activa',
  '[[[Alt+Enter]]] Ve las propiedades del elemento seleccionado ',
  '[[[Alt+F4]]] Cierra el elemento activo, o salir del programa activo',
  '[[[Alt+Enter]]] Muestra las prpopiedades del objecto seleccionado',
  '[[[Alt+Barra espaciadora]]] Cierra el elemento activo, o salir del program activo',
  '[[[Alt+Tab]]] Cambia entre los temas abiertos',
  '[[[Alt+Esc]]] Ciclo de los artículos que en el orden en que se había abierto',
  '[[[Alt+Letra subrayada en un nombre de menú]]] Muestra el menú correspondiente',
  '[[[Mayus+cualquiera de las teclas de dirección]]] Selecciona más de un artículo en una ventana o en el escritorio, o seleccionar texto en un documento',
];


