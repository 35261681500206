export const estado_t6_atajoswindows = [
  '[[[Tecla de Windows+A]]] Abre el centro de actividades',
  '[[[Tecla de Windows+C]]] Activa el reconocimiento de voz de Cortana',
  '[[[Tecla de Windows+D]]] Muestra el escritorio, presiona de nuevo y muestra las ventanas',
  '[[[Tecla de Windows+E]]] Abre el explorador de Windows',
  '[[[Tecla de Windows+G]]] Activa Game DVR para grabar la pantalla',
  '[[[Tecla de Windows+H]]] Activa la función para compartir en las apps para Windows 10',
  '[[[Tecla de Windows+I]]] Abre la configuración del sistema',
  '[[[Tecla de Windows+K]]] Inicia "conectar" para enviar datos a dispositivos inalámbricos',
  '[[[Tecla de Windows+L]]] Bloquea el equipo',
  '[[[Tecla de Windows+R]]] Abre la ventana ejecutar',
  '[[[Tecla de Windows+S]]] Activa Cortana',
  '[[[Tecla de Windows+X]]] Abre el menú de opciones avanzadas (igual que hacer clic derecho en el botón de inicio)',
  '[[[Tecla de Windows+Tab]]] Abre la vista de tareas',
  '[[[Tecla de Windows+flechas de dirección]]] Pega una ventana a la derecha, izquierda, arriba o abajo de la pantalla (Windows Snap)',
  '[[[Tecla de Windows+L]]] Crea un nuevo escritorio virtual',
  '[[[Tecla de Windows+Tab]]] Cierra un escritorio virtual (las apps se van al más cercano)',
  '[[[Alt+Tab]]] Cambia entre las ventanas abiertas en todos los escritorios',
];


