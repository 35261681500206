export const atajos_win = [
  'F1 [[[Ayuda]]]',
  'F2 [[[Cambiar nombre seleccion]]]',
  'Win + A [[[Centro actividades]]]',
  'F3 [[[Buscar explorador archivos]]]',
  'Ctrl + Esc [[[Abrir inicio]]]',
  'Win + C [[[Cortana]]]',
  'F4 [[[Barra direcciones]]]',
  'F5 [[[Actualizar ventana]]]',
  'Win + D [[[Mostrar / ocultar escritorio]]]',
  'Win + E [[[Explorador de archivos]]]',
  'F6 [[[Moverse paneles explorador Windows]]]',
  'F10 [[[Opciones barra menús]]]',
  'Win + L [[[Bloquear equipo / Cambiar cuenta]]]',
  'Win + I [[[Conﬁguración]]]',
  'F11 [[[Maximizar / minimizar ventana activa]]]',
  'Win + M [[[Minimizar todo]]]',
  'Win + S [[[Buscar]]]',
  'Win + R [[[Ejecutar]]]',
  'Alt + F4 / Ctrl + W [[[Cerrarventana activa]]]',
  'Alt + Tab [[[Cambiar entre aplicaciones]]]',
  'Win + U [[[Centro accesibilidad]]]',
  'Win + Tab [[[Vista tareas]]]',
  'Win + Pausa [[[Propiedades del sistema]]]',
  'Ctrl + A [[[Seleccionar todo]]]',
  'Ctrl + D / Supr [[[Enviar a la papelera]]]',
  'Ctrl + Alt + Supr [[[Administrador de tareas]]]',
  'Alt + Intro [[[Propiedades elemento]]]',
  'Alt + Flecha izq. [[[Volver]]]',
  'Alt + Flecha dcha. [[[Ir hacia delante]]]',
  'Mayus + Supr [[[Eliminar un elemento permanentemente]]]',
  'Alt + Imp Pant [[[copia la ventana activa]]]',
  'Imp Pant [[[Copia de toda la pantalla.]]]',
]