export const data_leyigualdad = [
  "Artículo 1. Objeto. Constituye el objeto de esta ley hacer efectivo el derecho de igualdad de trato y oportunidades entre mujeres y hombres para, en el desarrollo de los [[[artículos 9.2 y 14]]] de la Constitución y [[[15 y 38]]] del Estatuto de Autonomía para Andalucía, seguir avanzando hacia una sociedad más [[[democrática]]], más [[[justa]]] y más [[[solidaria]]].",
  "Artículo 2. Ámbito de aplicación. 1. La presente ley será de aplicación en todo el [[[ámbito territorial]]] de la Comunidad Autónoma de Andalucía.",
  "Artículo 2. Ámbito de aplicación. 2. En particular, en los términos establecidos en la propia ley, será de aplicación: a) A la [[[Administración de la Junta de Andalucía]]] y sus [[[organismos autónomos]]], a las [[[empresas]]] de la Junta de Andalucía, a los [[[consorcios]]], [[[fundaciones]]] y demás entidades con [[[personalidad jurídica propia]]] en los que sea mayoritaria la [[[representación directa]]] de la Junta de Andalucía.",
  "Artículo 2. Ámbito de aplicación. 2. En particular, en los términos establecidos en la propia ley, será de aplicación: b) A las [[[entidades]]] que integran la Administración local, sus [[[organismos autónomos]]], [[[consorcios]]], [[[fundaciones]]] y demás entidades con personalidad jurídica propia en los que sea mayoritaria la representación directa de dichas entidades.",
  "Artículo 2. Ámbito de aplicación. 2. En particular, en los términos establecidos en la propia ley, será de aplicación: c) Al [[[sistema universitario]]] andaluz.",
  "Artículo 2. Ámbito de aplicación. 3. Igualmente, será de aplicación a las personas [[[físicas y jurídicas]]], en los términos establecidos en la presente ley.",
  "Artículo 3. Definiciones. 1. Se entiende por [[[discriminación directa]]] por [[[razón de sexo]]] la situación en que se encuentra una persona que sea, haya sido o pudiera ser tratada, en atención a su sexo, de manera [[[menos favorable]]] que otra en situación equiparable.",
  "Artículo 3. Definiciones. 2. Se entiende por [[[discriminación indirecta]]] por [[[razón de sexo]]] la situación en que la aplicación de [[[una disposición]]], [[[criterio]]] o [[[práctica]]] aparentemente neutros pone a las personas de un sexo en desventaja particular con respecto a las personas del otro, salvo que la aplicación de dicha disposición, criterio o práctica pueda [[[justificarse objetivamente]]] en atención a una [[[finalidad legítima]]] y que los medios para alcanzar dicha finalidad sean [[[necesarios y adecuados]]].",
  "Artículo 3. Definiciones. 3. Se entiende por representación equilibrada aquella situación que [[[garantice la presencia de mujeres y hombres]]] de forma que, en el conjunto de personas a que se refiera cada sexo ni supere el [[[sesenta]]] por ciento ni sea menos del [[[cuarenta]]] por ciento.",
  "Artículo 3. Definiciones. 4. El [[[condicionamiento]]] de un derecho o de una expectativa de derecho a la aceptación de una [[[situación constitutiva de acoso sexual]]] o de [[[acoso por razón de sexo]]] se considerará acto de discriminación por razón de sexo. Tendrá la misma consideración [[[cualquier tipo de acoso]]].",
  "Artículo 3. Definiciones. 5. Se entiende por [[[transversalidad]]] el instrumento para integrar la [[[perspectiva de género]]] en el ejercicio de las [[[competencias]]] de las distintas políticas y acciones públicas, desde la consideración sistemática de la igualdad de género.",
  "Artículo 3. Definiciones. 6. Se entiende por [[[acoso sexual]]] el comportamiento de tipo [[[verbal]]], [[[no verbal]]] o [[[físico]]] de [[[índole sexual]]] realizado por [[[el hombre contra la mujer]]], que tenga como objeto o produzca el efecto de atentar contra [[[su dignidad]]], o crear[[[ un entorno intimidatorio]]], [[[hostil]]], [[[degradante]]], [[[humillante]]] u [[[ofensivo]]], cualquiera que sea el [[[ámbito]]] en el que se produzca, incluido el [[[laboral]]].",
  "Artículo 3. Definiciones. 7. Se entiende por [[[acoso por razón de sexo]]] el referido a comportamientos que tengan como [[[causa]]] o estén [[[vinculados]]] con su [[[condición de mujer]]] y tengan como propósito o produzcan el efecto de [[[atentar]]] contra la [[[dignidad]]] de las mujeres y crear un entorno [[[intimidatorio]]], [[[hostil]]], [[[degradante]]], [[[humillante]]] u [[[ofensivo]]], cualquiera que sea el [[[ámbito]]] en el que se produzca, incluido el [[[laboral]]].",
  "Artículo 3. Definiciones. 8. Se entiende por [[[lenguaje sexista]]] el uso [[[discriminatorio]]] del lenguaje que se hace por razón de sexo.",
  "Artículo 3. Definiciones. 9. Se entiende por [[[interseccionalidad]]] la situación de discriminación múltiple en que una mujer padece [[[formas agravadas]]] y [[[específicas]]] de discriminación por razón de [[[clase]]], [[[etnia]]], [[[religión]]], [[[orientación]]] o [[[identidad sexual]]], o [[[discapacidad]]].",
  "Artículo 4. Principios generales. 1. La igualdad de trato entre mujeres y hombres, que supone la [[[ausencia]]] de toda discriminación, directa o indirecta, por razón de sexo, en los ámbitos [[[económico]]], [[[político]]], [[[social]]], [[[laboral]]], [[[cultural]]] y [[[educativo]]], en particular, en lo que se refiere al [[[empleo]]], a la [[[formación profesional]]] y a las [[[condiciones de trabajo]]].",
  "Artículo 4. Principios generales. 2. La adopción de las medidas necesarias para la eliminación de la discriminación y especialmente, aquellas que incidan en la creciente [[[feminización]]] de [[[la pobreza]]].",
  "Artículo 4. Principios generales. 3. El reconocimiento de la [[[maternidad]]], [[[biológica o no biológica]]], como un [[[valor social]]], evitando los efectos negativos en los derechos de las mujeres y la consideración de la [[[paternidad]]] en un contexto [[[familiar]]] y [[[social]]] de [[[corresponsabilidad]]], de acuerdo con los [[[nuevos modelos de familia]]].",
  "Artículo 4. Principios generales. 4. El fomento de la [[[corresponsabilidad]]], a través del reparto [[[equilibrado]]] entre mujeres y hombres de las responsabilidades familiares, de las tareas [[[domésticas]]] y del [[[cuidado de las personas]]] en situación de [[[dependencia]]].",
  "Artículo 4. Principios generales. 5. La adopción de las medidas específicas necesarias destinadas a eliminar las [[[desigualdades de hecho]]] por razón de sexo que pudieran existir en los diferentes ámbitos.",
  "Artículo 4. Principios generales. 6. La especial protección del derecho a la igualdad de trato de aquellas mujeres o colectivos de mujeres que se encuentren en riesgo de padecer [[[múltiples]]] situaciones de discriminación.",
  "Artículo 4. Principios generales. 7. La promoción del [[[acceso a los recursos]]] de todo tipo a las mujeres que viven en el [[[medio rural]]] y su participación [[[plena]]], [[[igualitaria]]] y [[[efectiva]]] en la [[[economía]]] y en la [[[sociedad]]].",
  "Artículo 4. Principios generales. 8. El fomento de la participación o composición equilibrada de mujeres y hombres en los distintos [[[órganos de representación]]] y de toma de decisiones, así como en las [[[candidaturas]]] a las elecciones al Parlamento de Andalucía.",
  "Artículo 4. Principios generales. 9. El impulso de las relaciones entre las distintas Administraciones, [[[instituciones]]] y [[[agentes sociales]]] sustentadas en los principios de colaboración[[[, coordinación y cooperación]]], para garantizar la igualdad entre mujeres y hombres.",
  "Artículo 4. Principios generales. 10. La adopción de las medidas necesarias para eliminar el [[[uso sexista del lenguaje]]], y garantizar y promover la utilización de una [[[imagen]]] de las mujeres y los hombres, fundamentada en la igualdad de sexos, en todos los ámbitos de [[[la vida pública y privada]]].",
  "Artículo 4. Principios generales. 11. La adopción de las medidas necesarias para permitir la [[[compatibilidad efectiva]]] entre [[[responsabilidades laborales]]], [[[familiares]]] y [[[personales]]] de las mujeres y los hombres en Andalucía.",
  "Artículo 4. Principios generales. 12. El impulso de la [[[efectividad]]] del [[[principio de igualdad]]] en las relaciones entre particulares.",
  "Artículo 4. Principios generales. 13. La incorporación del principio de igualdad de género y la [[[coeducación]]] en el sistema educativo.",
  "Artículo 4. Principios generales. 14. La adopción de medidas que aseguren la igualdad entre hombres y mujeres en lo que se refiere al [[[acceso al empleo]]], a [[[la formación]]], [[[promoción profesional]]], [[[igualdad salarial]]] y a [[[las condiciones de trabajo]]].",
  "Artículo 5. [[[Transversalidad de género]]]. Los [[[poderes públicos]]] potenciarán que la perspectiva de la igualdad de género esté presente en la [[[elaboración]]], [[[ejecución]]] y [[[seguimiento]]] de las [[[disposiciones normativas]]], de [[[las políticas en todos los ámbitos de actuación]]], considerando sistemáticamente las [[[prioridades y necesidades]]] propias de las mujeres y de los hombres, teniendo en cuenta su incidencia en la situación específica de unas y otros, al objeto de adaptarlas para eliminar los [[[efectos discriminatorios]]] y [[[fomentar la igualdad de género]]].",
  "Artículo 6. Evaluación de impacto de género. 1. Los poderes públicos de Andalucía incorporarán la [[[evaluación del impacto]]] de género en el [[[desarrollo de sus competencias]]], para garantizar la integración del principio de igualdad entre hombres y mujeres.",
  "Artículo 6. Evaluación de impacto de género. 2. Todos los [[[proyectos de ley]]], [[[disposiciones reglamentarias]]] y [[[planes]]] que apruebe el [[[Consejo de Gobierno]]] incorporarán, de forma efectiva, el objetivo de la igualdad por razón de género. A tal fin, en el proceso de [[[tramitación]]] de esas decisiones, deberá emitirse por parte de [[[quien reglamentariamente]]] corresponda, un informe de [[[evaluación del impacto de género]]] del contenido de las mismas.",
  "Artículo 6. Evaluación de impacto de género. 3. Dicho informe de evaluación de impacto de género irá acompañado de [[[indicadores]]] pertinentes en género, [[[mecanismos]]] y medidas dirigidas a [[[paliar]]] y [[[neutralizar]]] los posibles impactos negativos que se detecten sobre las mujeres y los hombres, así como a [[[reducir o eliminar]]] las diferencias encontradas, promoviendo de esta forma la igualdad entre los sexos.",
  "Artículo 7. Plan Estratégico para la Igualdad de Mujeres y Hombres. 1. El Consejo de Gobierno de la Junta de Andalucía, con la participación de [[[las entidades locales]]], formulará y aprobará, con una periodicidad que no será [[[inferior]]] a [[[cuatro años]]], un [[[Plan Estratégico para la Igualdad]]] de Mujeres y Hombres en Andalucía, a propuesta de la [[[Consejería]]] competente en materia de [[[igualdad]]], en el que se incluirán las [[[líneas de intervención]]] y [[[directrices]]] que orientarán las actividades de los poderes públicos en Andalucía en materia de igualdad entre mujeres y hombres, con el objeto de promover la [[[democracia paritaria]]] y la [[[plena incorporación]]] de las mujeres, a fin de superar cualquier discriminación [[[social]]], [[[política]]], [[[económica]]] o [[[laboral]]], entre otras. Este Plan también incorporará entre sus líneas directrices una [[[estrategia de apoyo]]] a las mujeres del [[[ámbito rural]]].",
  "Artículo 7. Plan Estratégico para la Igualdad de Mujeres y Hombres. 2. En desarrollo de las [[[líneas de intervención]]] y [[[directrices]]] del [[[Plan Estratégico]]] previsto en el apartado 1, cada [[[Consejería]]] de la Administración de la Junta de Andalucía elaborará y aprobará sus propios [[[planes de igualdad]]], de ámbito [[[específico]]], que contemplarán [[[las medidas]]] y [[[el presupuesto]]] en materia de igualdad entre mujeres y hombres en el ámbito de sus competencias, que serán evaluados [[[anualmente]]] para incluir las medidas correctoras oportunas.",
  "Artículo 7. Plan Estratégico para la Igualdad de Mujeres y Hombres. 3. Las [[[entidades locales]]] de Andalucía aprobarán sus propios [[[planes de igualdad]]], en el marco definido por el [[[Plan Estratégico]]] para la Igualdad de Mujeres y Hombres de Andalucía, y adoptarán [[[las medidas]]] y [[[el presupuesto]]] necesario para garantizar su cumplimiento. Asimismo, se promoverá la existencia de un [[[servicio especializado]]] de igualdad de género en el ámbito municipal, considerando como tales los [[[centros municipales]]] de información a la mujer.",
  "Artículo 7. Plan Estratégico para la Igualdad de Mujeres y Hombres. 4. El Instituto Andaluz de la Mujer asesorará a las consejerías y a las entidades locales que así lo soliciten en el proceso de elaboración de los planes previstos en los apartados 2 y 3, en lo relativo a la adecuación de sus contenidos a las líneas y directrices previstas en el Plan Estratégico para la Igualdad de Mujeres y Hombres de Andalucía.",
  "Artículo 7. Plan Estratégico para la Igualdad de Mujeres y Hombres. 5. Las consejerías y las entidades locales remitirán al Instituto Andaluz de la Mujer, para su conocimiento, los planes previstos en los apartados 2 y 3, con carácter previo a su aprobación.",
  "Artículo 8. Enfoque de género en el presupuesto. 1. El Presupuesto de la Comunidad Autónoma de Andalucía será un elemento activo en la consecución de forma efectiva del objetivo de la igualdad entre mujeres y hombres; a tal fin, la Comisión de Impacto de Género en los Presupuestos, dependiente de la Consejería con competencias en la materia, con participación del Instituto Andaluz de la Mujer, emitirá el informe de evaluación de impacto de género sobre el anteproyecto de Ley del Presupuesto de la Comunidad Autónoma de cada ejercicio.",
  "Artículo 8. Enfoque de género en el presupuesto. 2. La Comisión de Impacto de Género en los Presupuestos impulsará y fomentará la preparación de anteproyectos con perspectiva de género de los estados de ingresos y de gastos en las diversas Consejerías y, cuando proceda, de recursos y dotaciones de las entidades instrumentales de la Administración de la Junta de Andalucía. Además, promoverá la realización de auditorías de género en las Consejerías y entidades instrumentales de la Administración de la Junta de Andalucía e impulsará la aplicación de la perspectiva del enfoque de género en el plan de auditorías de cada ejercicio.",
  "Artículo 8. Enfoque de género en el presupuesto. 3. La Cámara de Cuentas de Andalucía incorporará en el informe sobre la Cuenta General de la Comunidad Autónoma la fiscalización del cumplimiento de la perspectiva de género en el presupuesto de la Junta de Andalucía.",
  "Artículo 9. Lenguaje no sexista e imagen pública. 1. Las Administraciones públicas de Andalucía garantizarán un uso no sexista del lenguaje y un tratamiento igualitario en los contenidos e imágenes que utilicen en el desarrollo de sus políticas, en todos los documentos, titulaciones académicas y soportes que produzcan directamente o bien a través de personas o entidades. Todas las publicaciones y emisiones en las que la Junta de Andalucía participe garantizarán un tratamiento inclusivo y no discriminatorio de las mujeres.",
  "Artículo 9. Lenguaje no sexista e imagen pública. 2. Las entidades instrumentales de las Administraciones públicas de Andalucía, así como las corporaciones de derecho público de Andalucía, adaptarán su denominación oficial a un lenguaje no sexista, en el marco de sus respectivas normas reguladoras, y garantizarán un tratamiento igualitario en los contenidos e imágenes que utilicen en el desarrollo de sus actividades y en todos los documentos y soportes que produzcan directamente o bien a través de personas o entidades.",
  "Artículo 9. Lenguaje no sexista e imagen pública. 3. La Administración de la Junta de Andalucía promoverá que los colegios profesionales y las corporaciones de derecho público hagan un uso no sexista del lenguaje y un tratamiento igualitario en los contenidos e imágenes que utilicen.",
  "Artículo 9 bis. Capacitación del personal al servicio de las Administraciones públicas. 1. Las Administraciones públicas de Andalucía, en el ámbito de sus respectivas competencias, adoptarán las medidas necesarias para garantizar una formación básica, progresiva y permanente de su personal en materia de igualdad de mujeres y hombres, con enfoque feminista y transformador de los roles tradicionales de género, a fin de hacer efectiva la integración de la perspectiva de género en la actuación administrativa, conforme a lo establecido en esta ley y sus normas de desarrollo.",
  "Artículo 9 bis. Capacitación del personal al servicio de las Administraciones públicas. 2. A tal efecto, las Administraciones públicas de Andalucía elaborarán y desarrollarán sus respectivos planes de formación del personal a su servicio en materia de igualdad de mujeres y hombres, así como realizarán actividades de sensibilización para el personal que desempeñe funciones de dirección.",
  "Artículo 9 bis. Capacitación del personal al servicio de las Administraciones públicas. 3. Por la Consejería competente en materia de igualdad se prestará apoyo a los organismos responsables de la formación de las empleadas y empleados públicos de Andalucía, con el fin de garantizar la formación continuada y la actualización permanente del personal responsable de la misma en materia de igualdad de mujeres y hombres.",
  "Artículo 9 bis. Capacitación del personal al servicio de las Administraciones públicas. 4. El órgano competente de la Junta de Andalucía en materia de función pública garantizará la experiencia o capacitación específica del personal que vaya a ocupar puestos de trabajo entre cuyas funciones se incluyan las de elaborar e impulsar programas y prestar asesoramiento técnico en materia de igualdad de mujeres y hombres, estableciendo requisitos específicos de conocimiento en dicha materia para el acceso a los mismos.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 1.Los poderes públicos de Andalucía, para garantizar de modo efectivo la integración de la perspectiva de género en su ámbito de actuación, deberán: a) Incluir sistemáticamente la variable sexo en las estadísticas, encuestas y recogida de datos que realicen.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 1.Los poderes públicos de Andalucía, para garantizar de modo efectivo la integración de la perspectiva de género en su ámbito de actuación, deberán: b) Incorporar indicadores de género en las operaciones estadísticas que posibiliten un mejor conocimiento de las diferencias en los valores, roles, situaciones, condiciones, aspiraciones y necesidades de mujeres y hombres, y su manifestación e interacción en la realidad que se vaya a analizar.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 1.Los poderes públicos de Andalucía, para garantizar de modo efectivo la integración de la perspectiva de género en su ámbito de actuación, deberán: c) Analizar los resultados desde la dimensión de género.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 1.Los poderes públicos de Andalucía, para garantizar de modo efectivo la integración de la perspectiva de género en su ámbito de actuación, deberán: d) Analizar y cuantificar el valor de los cuidados.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 2. Asimismo, realizarán análisis e investigaciones sobre la situación de desigualdad por razón de sexo y difundirán sus resultados. Especialmente, contemplarán la situación y necesidades de las mujeres en el medio rural y pesquero, y de aquellos colectivos de mujeres sobre los que influyen diversos factores de discriminación.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 3. El Instituto de Estadística y Cartografía de Andalucía, como organismo coordinador de la ejecución de la actividad estadística y cartográfica de los órganos y entidades del Sistema Estadístico y Cartográfico de Andalucía, publicará anualmente un informe síntesis que recoja las principales estadísticas de Andalucía desde una perspectiva de género.",
  "Artículo 10. Estadísticas e investigaciones con perspectiva de género. 4. Los diferentes observatorios de la Administración de la Junta de Andalucía y otros órganos colegiados que tengan entre sus fines el análisis e investigación en su ámbito de competencias publicarán un informe anual que recoja sus principales estadísticas desde una perspectiva de género.",
  "Artículo 11. Representación equilibrada en los órganos directivos y colegiados. 1. Cada Consejería, organismo público y entidad de derecho público, vinculado o dependiente de la Administración pública andaluza, garantizará la representación equilibrada de hombres y mujeres en el nombramiento de titulares de órganos directivos.",
  "Artículo 11. Representación equilibrada en los órganos directivos y colegiados. 2. En la composición de los órganos colegiados de la Administración de la Junta de Andalucía deberá respetarse la representación equilibrada de mujeres y hombres, incluyendo en el cómputo a aquellas personas que formen parte de los mismos en función del cargo específico que desempeñen. Este mismo criterio de representación se observará en la modificación o renovación de dichos órganos. A tal efecto, cada organización, institución o entidad a la que corresponda la designación o propuesta facilitará la composición de género que permita la representación equilibrada.",
  "Artículo 11. Representación equilibrada en los órganos directivos y colegiados. 3. Sin perjuicio de otras medidas que se consideren oportunas, las normas que regulen los jurados creados para la concesión de cualquier tipo de premio promovido o subvencionado por las Administraciones públicas de Andalucía establecerán las mismas reglas de representación equilibrada definidas para los órganos colegiados en el apartado 2 del presente artículo.",
  "Artículo 11 bis. Órganos directivos o colegiados y denominación de los colegios profesionales de Andalucía y corporaciones de derecho público. 1. Los estatutos de los colegios profesionales de Andalucía deberán establecer las medidas adecuadas para asegurar que en los órganos de dirección a los que se refiere el artículo 32 de la Ley 10/2003, de 6 de noviembre, reguladora de los Colegios Profesionales de Andalucía, así como en todos aquellos órganos colegiados que se deban constituir con carácter preceptivo, se garantice la representación equilibrada de mujeres y hombres.",
  "Artículo 11 bis. Órganos directivos o colegiados y denominación de los colegios profesionales de Andalucía y corporaciones de derecho público. 2. Las corporaciones de derecho público de Andalucía deberán establecer los mecanismos adecuados para asegurar la representación equilibrada de mujeres y hombres en sus órganos de dirección.",
  "Artículo 11 bis. Órganos directivos o colegiados y denominación de los colegios profesionales de Andalucía y corporaciones de derecho público. 3. Las federaciones deportivas de Andalucía deberán establecer las medidas adecuadas para que en sus órganos colegiados se garantice la representación equilibrada de mujeres y hombres.",
  "Artículo 11 bis. Órganos directivos o colegiados y denominación de los colegios profesionales de Andalucía y corporaciones de derecho público. 4. Las entidades a las que se refiere este artículo deberán adaptar su denominación a un uso no sexista del lenguaje.",
  "Artículo 12. Contratación pública.1. La Administración de la Junta de Andalucía, a través de sus órganos de contratación, establecerá condiciones especiales en relación con la ejecución de los contratos que celebren, con el fin de promover la igualdad entre mujeres y hombres, especialmente en el ámbito laboral, siempre dentro del marco proporcionado por la normativa vigente.",
  "Artículo 12. Contratación pública.2. Los órganos de contratación de la Administración de la Junta de Andalucía señalarán, en los pliegos de cláusulas administrativas particulares, la preferencia de la adjudicación de los contratos para las proposiciones presentadas por aquellas empresas que, en el momento de acreditar su solvencia técnica, tengan la marca de excelencia o desarrollen medidas destinadas a lograr la igualdad de oportunidades, cuenten con protocolo de acoso sexual y por razón de sexo, así como que las medidas de igualdad aplicadas permanezcan en el tiempo y mantengan la efectividad, de acuerdo con las condiciones que reglamentariamente se establezcan. Todo ello, sin perjuicio de lo establecido en las normas reguladoras de los contratos del sector público.",
  "Artículo 13. Ayudas y Subvenciones. 1. La Administración de la Junta de Andalucía incorporará a las bases reguladoras de las subvenciones públicas la valoración de actuaciones de efectiva consecución de la igualdad de género por parte de las entidades solicitantes, salvo en aquellos casos en que, por la naturaleza de la subvención o de las entidades solicitantes, esté justificada su no incorporación.",
  "Artículo 13. Ayudas y Subvenciones. 2. La Administración de la Junta de Andalucía no formalizará contratos ni subvencionará, bonificará o prestará ayudas públicas a aquellas personas físicas o jurídicas condenadas por alentar o tolerar prácticas laborales consideradas discriminatorias por la legislación vigente, durante un plazo de cinco años desde la fecha de la condena por sentencia firme. Tampoco podrán acceder a ningún tipo de ayudas que conceda la Administración de la Junta de Andalucía y sus agencias aquellas personas físicas o jurídicas que, mediante resolución administrativa firme, sean objeto de las sanciones accesorias previstas en la letra a) de los apartados 2 y 3 del artículo 80. A tal efecto, los solicitantes deberán presentar, junto con la solicitud de la ayuda, una declaración responsable del hecho de no haber sido objeto de sanciones administrativas firmes ni de sentencias firmes condenatorias, en los plazos establecidos en la presente ley.",
  "Artículo 13. Ayudas y Subvenciones. 3. La Administración de la Junta de Andalucía establecerá medidas concretas de vigilancia del cumplimiento del principio de igualdad en el ámbito laboral para aquellas personas físicas o jurídicas con las que contrate, que subvencione, bonifique o a las que preste ayudas públicas.",
  "Artículo 14. Principio de igualdad en la educación. 1. El principio de igualdad entre mujeres y hombres inspirará el sistema educativo andaluz y el conjunto de políticas que desarrolle la Administración educativa. Las acciones que realicen los centros educativos de la Comunidad Autónoma contemplarán la perspectiva de género en la elaboración, desarrollo y seguimiento de sus actuaciones.",
  "Artículo 14. Principio de igualdad en la educación. 2. La Administración educativa potenciará la participación equilibrada de mujeres y hombres en los puestos directivos y de decisión. Asimismo, formulará acciones de conciliación de la vida laboral y familiar para favorecer la promoción profesional y curricular de todo el personal docente.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 1. La Administración educativa andaluza garantizará que todos los centros docentes elaboren e incluyan, dentro de su Plan de Centro, un Plan de Igualdad de Género que recogerá las actuaciones en materia de igualdad, coeducación y prevención de la violencia de género, incluyendo cualquier forma de discriminación; que fomenten la construcción de relaciones entre mujeres y hombres sobre la base de la igualdad, y que ayuden a identificar y eliminar las situaciones de discriminación y violencia de género.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 2. Los centros docentes andaluces observarán el principio de integración de la perspectiva de género en el conjunto de sus actuaciones incluidas en el plan de igualdad del centro, así como en la realización de su autoevaluación, valorando el grado de desarrollo y la eficacia de las actuaciones contempladas en el citado plan.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: a) La eliminación de los prejuicios, estereotipos y roles en función del sexo, construidos según los patrones socioculturales de conducta asignados a niños y niñas, mujeres y hombres, con el fin de garantizar un desarrollo personal integral.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: b) Hacer visible y reconocer la contribución de las mujeres en las distintas facetas de la historia, la ciencia, la política, la cultura y el desarrollo de la sociedad.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: c) Hacer visibles ante el alumnado a los grupos de mujeres y niñas en situación de múltiple discriminación desde un enfoque interseccional.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: d) Fomentar la autonomía de las mujeres y niñas en la sociedad y en todos los aspectos de la vida, en especial en aquellos colectivos que cuenten con especiales dificultades.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: e) Apoyar, impulsar y visibilizar modelos positivos de masculinidad y el compromiso de los hombres y niños con el cambio hacia una sociedad igualitaria.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: f) Garantizar que el alumnado adquiera la formación adecuada para fomentar su autonomía personal y los conocimientos y habilidades para compartir responsabilidades domésticas, familiares y de cuidado, y contribuir a eliminar comportamientos y actitudes sexistas.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: g) Incorporar el aprendizaje de métodos para la resolución pacífica de conflictos y la gestión de las emociones, y de modelos de convivencia basados en la diversidad, la tolerancia y el respeto a la igualdad de mujeres y hombres.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: h) Fomentar la diversificación profesional como forma de promover una elección académica y profesional libre y como vía de eliminación progresiva de la segregación ocupacional vertical y horizontal.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: i) Informar y asesorar a madres y padres a fin de que apoyen al alumnado para unas elecciones académicas y profesionales libres de condicionantes de género.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: j) Coordinar al profesorado en la puesta en práctica de actuaciones curriculares y metodológicas relativas al plan de igualdad o a los proyectos de coeducación.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: k) Promover el respeto a la identidad de género, orientación sexual, o expresión de género, el valor de la diversidad y el rechazo de toda actuación que suponga odio o discriminación.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: l) Promover el rechazo a la violencia de género en todas sus manifestaciones.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 3. La Administración educativa andaluza garantizará que todos los centros docentes cuenten con una persona responsable de coeducación, con formación específica, que, bajo la dirección del director o directora del centro docente, impulse la igualdad de género y facilite un mayor conocimiento de los obstáculos y discriminaciones que dificultan la plena igualdad de mujeres y hombres mediante actuaciones y actividades, que formarán parte del plan de igualdad del centro, dirigidas a: m) Formar al alumnado como personas críticas ante situaciones de discriminación de género en el contexto del consumo de bienes y servicios y, muy especialmente, en el ámbito de la publicidad de los mismos, de forma que el alumnado sepa reconocer y rechazar aquellos estereotipos sexistas que perpetúan la discriminación de género.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 4. La Administración educativa andaluza, con el fin de integrar la perspectiva de género en su labor, garantizará que los órganos responsables de la evaluación, calidad e investigación educativa, así como los servicios de apoyo y formación al profesorado, cuenten con personal capacitado específicamente en materia de coeducación.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 5. La Administración educativa, a través de programas de sensibilización con un enfoque de género, abordará los contenidos relacionados con las relaciones igualitarias en las relaciones afectivas y la educación sexual, la prevención de los embarazos no deseados y las enfermedades de transmisión sexual.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 6. La Administración educativa promoverá la capacitación de alumnos y alumnas y el apoyo a las expectativas individuales para que hagan sus elecciones académicas y profesionales libres de los condicionantes de género.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 7. La Administración educativa impulsará la elaboración de planes de igualdad en educación que posibiliten la puesta en marcha de acciones para educar en igualdad, con la participación de padres, madres, profesorado y alumnado.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 8. El profesorado está obligado a poner en conocimiento de los órganos directivos de los centros docentes correspondientes, tanto públicos como concertados o privados, aquellos indicios o sospechas de una situación de violencia de género ejercida sobre una alumna o cualquier profesional de los mismos, así como a denunciar situaciones de discriminación y comportamientos sexistas.",
  "Artículo 15. Promoción de la igualdad de género en los centros docentes. 9. La Administración educativa realizará programas de sensibilización y formación con un enfoque de género, para las asociaciones de madres y padres de los centros educativos, implicándolas para impulsar la igualdad de género en la comunidad educativa.",
  "Artículo 15 bis. Integración de contenidos curriculares. a) El tratamiento del currículo garantizará un desarrollo personal integral y en igualdad del alumnado, promoviendo la eliminación de los prejuicios, estereotipos y roles en función del género, construidos según los patrones socioculturales de conducta asignados tradicionalmente en la cultura patriarcal a mujeres y hombres, contemplando la diversidad sexual y de modelos familiares.",
  "Artículo 15 bis. Integración de contenidos curriculares. b) La integración del saber de las mujeres y de su contribución social e histórica al desarrollo de la humanidad, revisando y, en su caso, corrigiendo los contenidos que se imparten, incorporando la historia del feminismo como movimiento social y político que ha contribuido al cambio de valores y al avance de las mujeres.",
  "Artículo 15 bis. Integración de contenidos curriculares. c) El análisis crítico del modelo de masculinidad hegemónica y la existencia de androcentrismo en la transmisión del conocimiento.",
  "Artículo 15 bis. Integración de contenidos curriculares. d) La incorporación de conocimientos necesarios bajo los principios de corresponsabilidad y el reparto igualitario de responsabilidades, a fin de que el alumnado se haga cargo de sus actuales y futuras necesidades y responsabilidades relacionadas con el trabajo doméstico y de cuidado de las personas.",
  "Artículo 15 bis. Integración de contenidos curriculares. e) La capacitación del alumnado para que la elección de las opciones académicas y profesionales se realice libre de condicionamientos basados en el género.",
  "Artículo 15 bis. Integración de contenidos curriculares. f) La prevención de la violencia contra las mujeres, en cada una de sus manifestaciones, mediante el aprendizaje de métodos para la resolución pacífica de conflictos y de modelos de convivencia basados en la diversidad, la tolerancia y el respeto a la igualdad de derechos y oportunidades de mujeres y hombres.",
  "Artículo 16. Materiales curriculares y libros de texto. 1. La Administración educativa andaluza garantizará que en los libros de texto y materiales curriculares se eliminen los prejuicios culturales y los estereotipos sexistas o discriminatorios, incidiendo en la erradicación de modelos en los que aparezcan situaciones de desigualdad y violencia de género, valorando los que mejor respondan a la coeducación entre las niñas y los niños.",
  "Artículo 16. Materiales curriculares y libros de texto. 2. La Administración educativa trasladará al profesorado, a las empresas editoriales y a los consejos escolares las instrucciones relativas a los criterios de selección de los materiales curriculares, garantizando lo dispuesto en este precepto.",
  "Artículo 16. Materiales curriculares y libros de texto. 3. La Consejería competente en materia de educación creará una comisión de personas expertas en coeducación, en la que participará el Instituto Andaluz de la Mujer, para el seguimiento del lenguaje, de las imágenes y de los contenidos de los materiales curriculares y los libros de texto que se utilicen en el ámbito del sistema educativo de Andalucía. Esta comisión emitirá un informe anual, que remitirá para su conocimiento al Consejo Andaluz de Participación de las Mujeres.",
  "Artículo 16. Materiales curriculares y libros de texto. La composición, competencias y régimen de funcionamiento de la comisión de personas expertas en coeducación se determinarán reglamentariamente.",
  "Artículo 17. Formación del profesorado. 1. La Administración educativa incluirá, en la formación del profesorado en fase de prácticas, en la formación inicial obligatoria para el ejercicio de la función directiva y en la formación inicial de las asesorías y de la dirección de los centros del profesorado, contenidos relativos a igualdad de género, coeducación y prevención de la violencia de género, educación sexual y afectiva y diversidad familiar.",
  "Artículo 17. Formación del profesorado. 2. La Administración educativa adoptará las medidas necesarias para incluir en los planes de formación permanente del profesorado una formación continua en materia de igualdad de género, coeducación, prevención de la violencia de género, educación sexual y afectiva, y diversidad familiar, desde un enfoque feminista, que deberá desarrollarse con una metodología vivencial y no basándose en una mera impartición de contenidos teóricos.",
  "Artículo 17. Formación del profesorado. 3. A tal fin, incorporará transversalmente los contenidos curriculares de las materias referidas en el apartado 1 en los planes para la formación inicial y continua del profesorado, así como en la formación del equipo directivo de los centros docentes.",
  "Artículo 18. Consejos escolares. 1. En los consejos escolares de los centros públicos y privados concertados y en el Consejo Escolar de Andalucía se designará una persona, con formación en igualdad de género, que impulse y lleve a cabo el seguimiento de medidas educativas que fomenten la igualdad real y efectiva entre hombres y mujeres.",
  "Artículo 18. Consejos escolares. 2. La composición del consejo escolar respetará el equilibrio entre ambos sexos. Asimismo, en el Consejo Escolar de Andalucía participará una persona en representación del Instituto Andaluz de la Mujer.",
  "Artículo 19. Inspección educativa. 1. Los servicios de inspección educativa de Andalucía velarán por el cumplimiento de los principios y valores destinados a fomentar la igualdad real entre mujeres y hombres, establecidos en la presente ley.",
  "Artículo 19. Inspección educativa. 2. La Administración educativa, con el asesoramiento del Instituto Andaluz de la Mujer, incorporará en los planes generales y planes de actuación de la inspección educativa las directrices y acciones necesarias para la supervisión, evaluación, asesoramiento e información a los centros docentes en materia de igualdad de oportunidades entre mujeres y hombres.",
  "Artículo 19. Inspección educativa. 3. La inspección educativa tendrá entre sus actuaciones el asesoramiento, supervisión y evaluación de la educación para la igualdad de mujeres y hombres. En este sentido, supervisará el respeto de esta normativa en los materiales curriculares, libros de texto y, en general, en todas las programaciones didácticas.",
  "Artículo 19. Inspección educativa. 4. La Administración educativa de Andalucía organizará periódicamente actividades formativas dirigidas a los servicios de inspección educativa, sobre educación para la igualdad entre mujeres y hombres, inspirada en los principios de pluralismo y diversidad.",
  "Artículo 20. Igualdad de oportunidades en la Educación Superior. 1. El sistema universitario andaluz, en el ámbito de sus competencias, fomentará la igualdad de oportunidades de mujeres y hombres con relación a la carrera profesional. Igualmente, desarrollará medidas de conciliación de la vida laboral y familiar para favorecer la promoción profesional y curricular de todo el personal docente y no docente.",
  "Artículo 20. Igualdad de oportunidades en la Educación Superior. 2. El sistema universitario andaluz adoptará las medidas necesarias para que se incluyan enseñanzas obligatorias en materia de igualdad entre mujeres y hombres en los planes de estudios universitarios. De manera especial, en enseñanzas universitarias oficiales de grado, máster y doctorado en Ciencias Sociales y Jurídicas. En Ciencias de la Educación, serán de obligado cumplimiento los contenidos de coeducación, de prevención de la violencia de género y de igualdad. Igualmente, se incluirán estas materias en el sistema de acceso a la función pública docente, como méritos de formación, en los criterios de evaluación y en el programa de contenidos, incluyendo un módulo específico de coeducación, prevención de la violencia de género y promoción de la igualdad.",
  "Artículo 20. Igualdad de oportunidades en la Educación Superior. 3. Asimismo, el sistema universitario andaluz, dentro del respeto a la autonomía universitaria, y a tenor de lo establecido en su legislación específica, impulsará medidas para garantizar la representación equilibrada entre mujeres y hombres en la composición de los órganos colegiados de las universidades y comisiones de selección y evaluación.",
  "Artículo 20. Igualdad de oportunidades en la Educación Superior. 4. Cada universidad pública de Andalucía se dotará, con estructura propia y suficiente, de una unidad de igualdad de género con el fin de impulsar, coordinar y evaluar la implementación de la perspectiva de género en su ámbito de actuación. Asimismo, entre sus funciones estará el prevenir y responder ante los casos de acoso que se produzcan en el seno de la comunidad universitaria (PDI, PAS y estudiantado), garantizando la adecuada atención de las víctimas, la investigación necesaria para esclarecer las circunstancias de los hechos denunciados y la adopción de medidas cautelares que protejan a la víctima e impidan que el proceso de investigación se pueda ver afectado.",
  "Artículo 20. Igualdad de oportunidades en la Educación Superior. 5. Todas las universidades de Andalucía elaborarán y aprobarán un plan de igualdad y prevención de la discriminación, que implicará al proyecto educativo, laboral, investigador y social de la universidad y que tendrá un carácter cuatrienal. Para su elaboración podrán contar, de ser preciso, con el asesoramiento del Instituto Andaluz de la Mujer.",
  "Artículo 20. Igualdad de oportunidades en la Educación Superior. 6. El sistema universitario andaluz adoptará las acciones necesarias a fin de consolidar los estudios de género en los ámbitos de la docencia y la investigación.",
  "Artículo 21. Proyectos de investigación. 1. El sistema universitario andaluz impulsará la presencia equilibrada de mujeres y hombres en el ámbito de la investigación, la ciencia y la tecnología.",
  "Artículo 21. Proyectos de investigación. 2. El sistema universitario andaluz promoverá que se reconozcan los estudios de género como mérito a tener en cuenta en la evaluación de la actividad docente, investigadora y de gestión del personal docente e investigador de las universidades públicas de Andalucía.",
  "Artículo 21. Proyectos de investigación. 3. Las Administraciones públicas de Andalucía, en el ámbito de sus competencias, fomentarán el apoyo a la formación y a la investigación en materia de igualdad entre mujeres y hombres y promoverán y velarán por que en los proyectos de investigación de los que se puedan extraer resultados para las personas tengan en cuenta la perspectiva de género.",
  "Artículo 21. Proyectos de investigación. 4. El sistema universitario andaluz promoverá la creación de cátedras sobre estudios de género y violencia sexista en facultades, escuelas técnicas superiores y escuelas universitarias.",
  "Artículo 21 bis. Mujeres en la ciencia, la tecnología, la innovación y la investigación. 1. Las Administraciones públicas de Andalucía, en el ámbito de sus competencias, promoverán la participación de las mujeres en el ámbito de la ciencia, la tecnología, la innovación y la investigación.",
  "Artículo 21 bis. Mujeres en la ciencia, la tecnología, la innovación y la investigación. 2. A tal efecto, impulsarán la incorporación de la perspectiva de género como una dimensión transversal en la investigación, el desarrollo y la innovación, de manera que su relevancia sea considerada en todos los aspectos del proceso científico, y fomentarán la presencia paritaria de las mujeres en los eventos científicos, académicos e institucionales.",
  "Artículo 21 bis. Mujeres en la ciencia, la tecnología, la innovación y la investigación. 3. Los agentes del Sistema Andaluz del Conocimiento del sector público andaluz establecerán mecanismos para eliminar los sesgos de género en los procedimientos de selección y evaluación del personal investigador.",
  "Artículo 21 bis. Mujeres en la ciencia, la tecnología, la innovación y la investigación. 4. Asimismo, los agentes citados en el apartado 3 desarrollarán mecanismos para evitar el sesgo de género en los procedimientos de concesión de ayudas públicas a la actividad investigadora.",
  "Artículo 21 bis. Mujeres en la ciencia, la tecnología, la innovación y la investigación. 5. Los organismos públicos de investigación del Sistema Andaluz del Conocimiento en Andalucía adoptarán planes de igualdad que deberán incluir medidas incentivadoras para aquellos centros de investigación de su ámbito de competencias que avancen en la incorporación de indicadores y análisis de género. Para su elaboración podrán contar, de ser preciso, con el asesoramiento del Instituto Andaluz de la Mujer.",
  "Artículo 22. Igualdad de oportunidades en el acceso al empleo. 1. Será un objetivo prioritario de la actuación de la Administración de la Junta de Andalucía la igualdad de oportunidades en el empleo. A tal efecto, se llevarán a cabo políticas de fomento del empleo y actividad empresarial que impulsen la presencia de mujeres y hombres en el mercado de trabajo con un empleo de calidad, y una mejor conciliación de la vida laboral, familiar y personal.",
  "Artículo 22. Igualdad de oportunidades en el acceso al empleo. 2. La Administración de la Junta de Andalucía desarrollará las medidas de acción positiva destinadas a garantizar la igualdad de oportunidades y la superación de las situaciones de segregación profesional, tanto vertical como horizontal, así como las que supongan desigualdades retributivas.",
  "Artículo 23. Políticas de empleo. 1. Las Administraciones públicas de Andalucía, en el marco de sus competencias, impulsarán la transversalidad de género como instrumento para integrar la perspectiva de género en el diseño, ejecución, seguimiento y evaluación de las políticas de empleo que se desarrollen en el ámbito de la Comunidad Autónoma, incluyéndose, en su caso, las medidas específicas y necesarias.",
  "Artículo 23. Políticas de empleo. 2. La Administración de la Junta de Andalucía garantizará la participación de mujeres en el desarrollo de los programas de políticas activas de empleo, que aseguren la coordinación de los diferentes dispositivos y contemplen las necesidades que impidan o dificulten el acceso de las mujeres a los mismos, con especial atención a las mujeres que presenten una mayor vulnerabilidad y discriminación.",
  "Artículo 23. Políticas de empleo. 3. La Administración de la Junta de Andalucía prestará especial atención a las mujeres en las que se unan varias causas de discriminación, a través de políticas activas de empleo y planes de empleo, favoreciendo la inserción de las mujeres víctimas de la violencia de género, mujeres con discapacidad, mujeres al frente de familias monoparentales y mujeres especialmente vulnerables, como víctimas de trata y explotación sexual, migrantes y racializadas, entre otras. Asimismo, fomentará la formación profesional para el empleo y la incorporación de las mujeres al trabajo por cuenta propia o ajena, y el acceso a las nuevas tecnologías, especialmente de aquellas de mayor edad y de las mujeres especialmente vulnerables.",
  "Artículo 23. Políticas de empleo. 4. El Servicio Andaluz de Empleo no podrá tramitar ninguna oferta de empleo discriminatoria por razón de sexo. A tal efecto, se formará a su personal para incorporar la perspectiva de género en el proceso de inserción laboral, en el acceso y en el mantenimiento del empleo.",
  "Artículo 23. Políticas de empleo. 5. Asimismo, posibilitará que el personal de los servicios de empleo y entidades colaboradoras disponga de la formación necesaria en igualdad de oportunidades, para la incorporación efectiva de la perspectiva de género en el proceso de inserción laboral.",
  "Artículo 23. Políticas de empleo. 6. Para facilitar el acceso, en condiciones de igualdad efectiva, en los servicios públicos de empleo, se adecuarán sus horarios de atención, las formas de difusión, las metodologías de apoyo y los materiales utilizados por estos servicios a toda la diversidad de mujeres, con flexibilidad de adaptación a las diferentes características de las mismas.",
  "Artículo 23. Políticas de empleo. 7. Se incluirán módulos específicos de igualdad de género en el diseño de la formación que reciba el personal que interviene en el proceso de orientación e inserción laboral, con especial atención al personal de los centros de formación profesional ocupacional y de formación profesional para el empleo y de los servicios de acompañamiento al empleo.",
  "Artículo 23. Políticas de empleo. 8. Las empresas están obligadas a respetar la igualdad de trato y de oportunidades en el ámbito laboral y, con esta finalidad, deberán adoptar medidas dirigidas a evitar cualquier tipo de discriminación laboral entre mujeres y hombres.",
  "Artículo 23. Políticas de empleo. 9. Corresponde a la Junta de Andalucía, en el marco de la legislación del Estado y del Estatuto de Autonomía para Andalucía, garantizar que, en la elaboración de los planes de actuación de la Inspección de Trabajo, se recoja como objetivo prioritario la actuación contra la discriminación laboral directa e indirecta y los incumplimientos en materia de planes de igualdad. A tal efecto, propondrá anualmente, en el seno de la Comisión Operativa Autonómica de la Inspección de Trabajo y Seguridad Social, la planificación anual de medidas para combatir este tipo de discriminación laboral, así como el acoso sexual y el acoso por razón de sexo.",
  "Artículo 23. Políticas de empleo. 10. La Administración de la Junta de Andalucía, en el marco de sus competencias en materia de empleo, promoverá la realización de estudios en relación con el empleo y la igualdad de género, teniendo en cuenta, como eje transversal, la conciliación de la vida personal y laboral, la corresponsabilidad y, en particular, el valor económico del trabajo doméstico y el cuidado de las personas y la construcción social de los usos del tiempo. El resultado de esos estudios será difundido con el fin de dar a conocer el papel de las mujeres y su contribución a la economía y a la sociedad andaluza.",
  "Artículo 24. Incentivos a la contratación de mujeres. Se establecerán incentivos a la contratación estable de las mujeres, atendiendo con carácter prioritario a aquellos sectores y categorías laborales en los que se encuentren subrepresentadas, así como a sus situaciones singulares.",
  "Artículo 25. Promoción empresarial. 1. Los poderes públicos de Andalucía, en el marco de sus políticas de fomento empresarial, contemplarán ayudas específicas a mujeres para la creación de empresas o ayudas al autoempleo, priorizando las iniciativas emprendedoras de trabajo autónomo y economía social. Asimismo, establecerán medidas de formación, asesoramiento y seguimiento que permitan la consolidación de los proyectos empresariales.",
  "Artículo 25. Promoción empresarial. 2. Se podrán establecer otras acciones de fomento del emprendimiento de mujeres, así como todas aquellas consistentes en orientación, formación, asesoramiento, tutorización, acompañamiento o de apoyo a la creación de empresa o actividad económica, mantenimiento, desarrollo de la misma, y el acceso a los servicios de emprendimiento en primera y segunda oportunidad, desde una perspectiva de género.",
  "Artículo 25. Promoción empresarial. 3. Los poderes públicos de Andalucía, en el marco de sus competencias, impulsarán medidas y actuaciones que favorezcan la aportación de las mujeres para construir nuevos modelos de economía y empresa.",
  "Artículo 26. Calidad en el empleo. 1. La Administración de la Junta de Andalucía garantizará la igualdad de género en el acceso al empleo. En especial, incidirá en aspectos relacionados con la estabilidad, la calidad y la igualdad de remuneración entre mujeres y hombres. Asimismo, fomentará la igualdad en la promoción profesional, en la participación en procesos de formación continua, en el desarrollo de la trayectoria profesional, y velará por la prevención del acoso sexual y acoso por razón de sexo.",
  "Artículo 26. Calidad en el empleo. 2. La Administración de la Junta de Andalucía garantizará la igualdad de oportunidades en todas las facetas de la relación laboral y, en especial, en relación con la igualdad de retribución por trabajo de igual valor. A tal fin, promoverá que, en los planes de actuación de la Inspección de Trabajo y Seguridad Social, tenga carácter prioritario el cumplimiento del principio de igualdad de oportunidades en el ámbito laboral y del empleo.",
  "Artículo 26. Calidad en el empleo. 3. Las Administraciones públicas de Andalucía, con la colaboración de los agentes sociales, incentivarán la calidad en el empleo y la promoción de la igualdad de mujeres y hombres.",
  "Artículo 26 bis. Política de igualdad salarial entre mujeres y hombres. 1. La Administración de la Junta de Andalucía, en colaboración con los agentes económicos y sociales más representativos, desarrollará programas específicos dirigidos a la eliminación de la discriminación salarial por razón de género.",
  "Artículo 26 bis. Política de igualdad salarial entre mujeres y hombres. 2. La Consejería competente en materia de empleo realizará anualmente estudios que permitan analizar las diferencias retributivas entre mujeres y hombres en las empresas y sectores de Andalucía, sus causas y su evolución en el tiempo, con el fin de diseñar políticas e incentivos que permitan erradicar estas situaciones. De los estudios se dará traslado, para su conocimiento, al Consejo Andaluz de Participación de las Mujeres.",
  "Artículo 26 bis. Política de igualdad salarial entre mujeres y hombres. 3. La Consejería competente en materia de empleo, en colaboración con los agentes económicos y sociales más representativos, adoptará acciones destinadas a promover una mayor diversificación profesional de mujeres y hombres, suprimir las desigualdades de género y eliminar la segregación por sexos en el mercado laboral. Asimismo, desarrollará las medidas necesarias para que los sectores feminizados sean revalorizados socialmente y tengan el mismo reconocimiento y las mismas condiciones laborales que los demás.",
  "Artículo 27. Planes de igualdad y presencia equilibrada en el sector empresarial. 1. La Administración de la Junta de Andalucía fomentará y prestará apoyo y asesoramiento para la elaboración de los planes de igualdad en las empresas privadas que no estén obligadas por la Ley Orgánica 3/2007, de 22 de marzo, para la igualdad efectiva de mujeres y hombres. Dichos planes, sin perjuicio de lo dispuesto en la normativa básica sobre la materia, deberán contemplar medidas para el acceso al empleo, la promoción, la formación, la igualdad retributiva; medidas para fomentar la conciliación de la vida familiar y laboral, la protección frente el acoso sexual y el acoso por razón de sexo, así como incluir criterios y mecanismos de seguimiento, evaluación y actuación.",
  "Artículo 27. Planes de igualdad y presencia equilibrada en el sector empresarial. 2. Las empresas procurarán la presencia equilibrada de mujeres y hombres en sus órganos de dirección.",
  "Artículo 27. Planes de igualdad y presencia equilibrada en el sector empresarial. 3. Las organizaciones empresariales procurarán la presencia equilibrada de mujeres y hombres en sus órganos de dirección.",
  "Artículo 27. Planes de igualdad y presencia equilibrada en el sector empresarial. 4. Los programas de formación incluidos en los Planes de Igualdad de las empresas priorizarán las acciones formativas cuyo objetivo sea la igualdad entre mujeres y hombres dentro de la organización.",
  "Artículo 27. Planes de igualdad y presencia equilibrada en el sector empresarial. 5. Con independencia de las previsiones normativas sobre inscripción y registro de los planes de igualdad de las empresas acordados en el ámbito de la negociación colectiva, la Junta de Andalucía creará un Registro de Planes de Igualdad en las Empresas, dependiente de la Consejería con competencia en materia laboral, que regulará reglamentariamente su constitución, sus características y sus condiciones para la inscripción y acceso. Este registro estará conectado con el Registro y depósito de convenios y acuerdos colectivos de trabajo (plataforma Regcon), que regula el Real Decreto 713/2010, de 28 de mayo, sobre registro y depósito de convenios y acuerdos colectivos de trabajo.",
  "Artículo 28. Negociación colectiva.1. Partiendo del pleno respeto al principio constitucional de la autonomía en la negociación colectiva, la Administración de la Junta de Andalucía fomentará la inclusión de cláusulas destinadas a promover y garantizar la igualdad de oportunidades y la no discriminación por razón de género en la negociación colectiva en Andalucía. Se promoverá la elaboración de recomendaciones o cláusulas tipo en esta materia, y en materia de conciliación de la vida laboral, familiar y personal.",
  "Artículo 28. Negociación colectiva.2. Se realizarán actividades de sensibilización destinadas a fomentar la participación de las mujeres en la negociación colectiva. En los estudios que se realicen sobre la negociación se incluirá el papel de la mujer en la misma.",
  "Artículo 28. Negociación colectiva.3. La Administración de la Junta de Andalucía garantizará que los convenios colectivos: a) No contengan cláusulas contrarias al principio de igualdad de oportunidades de mujeres y hombres, ya sea esta discriminación directa o indirecta, y que no establezcan diferencias retributivas por razón de género.",
  "Artículo 28. Negociación colectiva.3. La Administración de la Junta de Andalucía garantizará que los convenios colectivos: b) Hagan un uso no sexista del lenguaje.",
  "Artículo 28. Negociación colectiva.4. Las organizaciones sindicales procurarán la presencia equilibrada de mujeres y hombres en sus órganos de dirección.",
  "Artículo 28. Negociación colectiva.5. La Administración de la Junta de Andalucía promoverá que los convenios colectivos incluyan el correspondiente análisis de impacto de género.",
  "Artículo 28. Negociación colectiva.6. Las Administraciones públicas de Andalucía incluirán cláusulas vinculadas a la existencia de planes o medidas de igualdad negociados con la representación legal de los trabajadores y trabajadoras en los criterios de todo tipo de adjudicaciones.",
  "Artículo 29. Seguridad y salud laboral. 1. Las Administraciones públicas de Andalucía promoverán una concepción integral de la salud que tenga en cuenta tanto los riesgos físicos como psicosociales, atendiendo a las diferencias de las mujeres y de los hombres.",
  "Artículo 29. Seguridad y salud laboral. 2. Se adoptarán las medidas adecuadas de protección relativas a la salud y a la seguridad de las trabajadoras embarazadas que hayan dado a luz recientemente o que se encuentren en período de lactancia.",
  "Artículo 29. Seguridad y salud laboral. 3. Se considerará discriminación por razón de sexo todo trato desfavorable a las mujeres relacionado con el embarazo o la maternidad.",
  "Artículo 29. Seguridad y salud laboral. 4. Los departamentos competentes en materia de salud y seguridad y de salud laboral deben fomentar la recogida y el tratamiento de la información existente en los centros de atención primaria y en las mutuas de accidentes de trabajo y enfermedades profesionales, desglosada por sexos, a fin de identificar riesgos específicos en las trabajadoras debido a su actividad laboral.",
  "Artículo 30. Acoso sexual y acoso por razón de sexo. 1. Las Administraciones públicas de Andalucía adoptarán las medidas necesarias, en su ámbito competencial, para que exista un entorno laboral libre de acoso sexual y de acoso por razón de sexo. En este sentido, tendrán la consideración de conductas que afectan a la salud laboral, y su tratamiento y prevención deberán abordarse desde esta perspectiva, sin perjuicio de la responsabilidad penal, laboral y civil que se derive. Igualmente, y con esta finalidad, se establecerán medidas que deberán negociarse con los representantes de las trabajadoras y los trabajadores, tales como la elaboración y difusión de códigos de buenas prácticas, la realización de campañas informativas o acciones de formación.",
  "Artículo 30. Acoso sexual y acoso por razón de sexo. 2. En todo caso, se considerarán discriminatorios el acoso sexual y el acoso por razón de sexo, y, a tal efecto, las Administraciones públicas arbitrarán los protocolos de actuación con las medidas de prevención y protección necesarias frente a las consecuencias derivadas de estas situaciones, garantizando la prontitud y confidencialidad en la tramitación de las denuncias y el impulso de las medidas cautelares.",
  "Artículo 30. Acoso sexual y acoso por razón de sexo. 3. Los protocolos de actuación contemplarán las indicaciones a seguir ante situaciones de acoso sexual y acoso por razón de sexo.",
  "Artículo 30. Acoso sexual y acoso por razón de sexo. 4. La Consejería competente en materia de igualdad impulsará la elaboración de dichos protocolos y realizará el seguimiento y evaluación de los mismos.",
  "Artículo 30. Acoso sexual y acoso por razón de sexo. 5. Las Administraciones Públicas Andaluzas, en el ámbito de sus competencias, garantizarán el asesoramiento jurídico y psicológico especializado a las víctimas de acoso sexual y acoso relacionado con el sexo y el apoyo en ambos supuestos.",
  "Artículo 31. Empleo en el sector público andaluz. 1. Al objeto de acceder al empleo público en la Administración de la Junta de Andalucía, los temarios para la celebración de pruebas selectivas incluirán materias relativas a la normativa sobre igualdad y violencia de género.",
  "Artículo 31. Empleo en el sector público andaluz. 2. Para que la igualdad de oportunidades entre mujeres y hombres sea integrada en el desarrollo de la actividad pública, la Administración de la Junta de Andalucía garantizará la formación de su personal sobre igualdad y violencia de género y se integrará la perspectiva de género de manera transversal en los contenidos de la formación.",
  "Artículo 31. Empleo en el sector público andaluz. 3. Las ofertas públicas de empleo de la Administración de la Junta de Andalucía deberán ir acompañadas de un informe de impacto de género.",
  "Artículo 31. Empleo en el sector público andaluz. 4. Previa negociación de acuerdo con la normativa aplicable y sin perjuicio de otras medidas que se consideren adecuadas, las normas que regulen los procesos selectivos de acceso, provisión y promoción en el empleo público en la Administración de la Junta de Andalucía, sus agencias y demás entidades instrumentales podrán disponer que las bases de la convocatoria, en caso de empate en la calificación final, establezcan, como criterio de desempate, la prioridad de las personas del sexo cuya presencia en el cuerpo, escala o categoría profesional correspondiente sea inferior al cuarenta por ciento en el momento de la aprobación de la oferta de empleo público.",
  "Artículo 32. Planes de igualdad en el empleo en la Administración pública. 1. La Administración de la Junta de Andalucía, sus agencias y demás entidades instrumentales elaborarán cada cuatro años planes de igualdad en el empleo.",
  "Artículo 32. Planes de igualdad en el empleo en la Administración pública. 2. En estos planes se establecerán los objetivos a alcanzar en materia de igualdad de trato y de oportunidades en el empleo público, así como las estrategias y medidas a adoptar para su consecución, incluyendo las medidas para la conciliación de la vida laboral con la familiar y personal, con medidas específicas sobre diversidad familiar y personal.",
  "Artículo 32. Planes de igualdad en el empleo en la Administración pública. 3. Los planes de igualdad serán evaluados y establecerán medidas correctoras, en su caso, cada cuatro años.",
  "Artículo 33. Protección frente al acoso sexual y al acoso por razón de sexo. La Administración de la Junta de Andalucía adoptará las medidas necesarias para una protección eficaz frente al acoso sexual y el acoso por razón de sexo, tanto en el ámbito de la Administración Pública como en el de las empresas privadas.",
  "Artículo 34. Actuaciones de responsabilidad social de las empresas en materia de igualdad. 1. Sin perjuicio de las normas en materia de empleo recogidas en el presente Título, las empresas podrán asumir, en virtud de acuerdos, con la representación legal de las trabajadoras y trabajadores, instituciones, organismos y asociaciones para la igualdad de género, actuaciones de responsabilidad social, a través de medidas económicas, comerciales, laborales, asistenciales o de otra índole, con la finalidad de mejorar la situación de igualdad entre mujeres y hombres en la empresa.",
  "Artículo 34. Actuaciones de responsabilidad social de las empresas en materia de igualdad. 2. Los poderes públicos de Andalucía impulsarán medidas para fomentar el desarrollo de actuaciones de responsabilidad social de las empresas en materia de igualdad de género.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: a) La equilibrada representación de mujeres y hombres en los grupos y categorías profesionales y la adopción de medidas de acción positiva en el acceso al empleo y en la promoción profesional en los niveles en los que las mujeres estén subrepresentadas.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: b) Las garantías de igualdad de retribución por trabajos de igual valor.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: c) Las medidas concretas de conciliación de la vida laboral, familiar y personal.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: d) La implantación de medidas adecuadas de prevención y sanción contra la violencia de género, acoso sexual y por razón de sexo.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: e) La publicidad no sexista.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: f) La implantación de un Plan de Igualdad en la Empresa.",
  "Artículo 35. Marca de excelencia en igualdad. 1. El Consejo de Gobierno de la Junta de Andalucía establecerá un reconocimiento para distinguir a aquellas entidades comprometidas con la igualdad entre mujeres y hombres, con la finalidad de incentivar las iniciativas empresariales que implanten medidas para la promoción de la igualdad en la gestión de los recursos humanos, así como mejoras en la calidad del empleo de las mujeres. Se valorará: g) La implementación de actuaciones de responsabilidad social en materia de igualdad de oportunidades.",
  "Artículo 35. Marca de excelencia en igualdad. 2. Los criterios para la obtención, el control de la ejecución y la renovación de la marca de excelencia serán establecidos reglamentariamente.",
  "Artículo 36. Derecho y deber de la corresponsabilidad de hombres y mujeres en el ámbito público y privado. 1. Las mujeres y los hombres en Andalucía tienen el derecho y el deber de compartir adecuadamente las responsabilidades familiares, las tareas domésticas y el cuidado y la atención de las personas en situación de dependencia, posibilitando la conciliación de la vida laboral, personal y familiar y la configuración de su tiempo, para alcanzar la efectiva igualdad de oportunidades de mujeres y hombres.",
  "Artículo 36. Derecho y deber de la corresponsabilidad de hombres y mujeres en el ámbito público y privado. 2. Las Administraciones públicas de Andalucía adoptarán medidas para garantizar la conciliación de la vida laboral, familiar y personal de las mujeres y hombres, teniendo en cuenta los nuevos modelos de familia y la situación de las mujeres que viven en el medio rural.",
  "Artículo 37. Organización de espacios, horarios y creación de servicios. 1. Para facilitar la conciliación de la vida laboral, familiar y personal a través de un reparto equilibrado del tiempo de hombres y mujeres, la Administración de la Junta de Andalucía, en colaboración con las Entidades Locales, promoverá la elaboración de planes de diseño y organización de los espacios que faciliten la funcionalidad de la ciudad, propicien la proximidad de las dotaciones y servicios y minimicen los desplazamientos y tiempos de acceso.",
  "Artículo 37. Organización de espacios, horarios y creación de servicios. 2. Se promoverá la coordinación entre los horarios laborales y el de los centros educativos.",
  "Artículo 37. Organización de espacios, horarios y creación de servicios. 3. Asimismo, se impulsará la creación de infraestructuras y servicios para facilitar el cumplimiento de las responsabilidades familiares y el cuidado y atención de menores y de personas en situación de dependencia.",
  "Artículo 37. Organización de espacios, horarios y creación de servicios. 4. Las Administraciones públicas de Andalucía elaborarán estudios que investiguen los tiempos, los espacios y horarios de la actividad laboral, comercial y de ocio de las personas trabajadoras. Asimismo efectuarán el estudio de los cuidados y la actividad no remunerada de mujeres y hombres.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 1. La Administración de la Junta de Andalucía garantizará el desarrollo de medidas, en el ámbito de la escolarización en educación infantil, educación primaria y educación secundaria obligatoria, en los centros docentes públicos de su titularidad, orientadas a facilitar la conciliación de la vida laboral, familiar y personal en Andalucía y, en particular: a) La ampliación del horario de apertura de los centros más allá del estrictamente lectivo, para acomodarlos a la realidad laboral de las familias.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 1. La Administración de la Junta de Andalucía garantizará el desarrollo de medidas, en el ámbito de la escolarización en educación infantil, educación primaria y educación secundaria obligatoria, en los centros docentes públicos de su titularidad, orientadas a facilitar la conciliación de la vida laboral, familiar y personal en Andalucía y, en particular: b) La oferta de una atención complementaria extracurricular con el servicio de aula matinal y con actividades extraescolares, priorizada y bonificada en función de los ingresos de la unidad familiar, de su situación laboral y composición. En los supuestos de familias monoparentales se aplicará la corrección oportuna a los ingresos de la unidad familiar.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 1. La Administración de la Junta de Andalucía garantizará el desarrollo de medidas, en el ámbito de la escolarización en educación infantil, educación primaria y educación secundaria obligatoria, en los centros docentes públicos de su titularidad, orientadas a facilitar la conciliación de la vida laboral, familiar y personal en Andalucía y, en particular: c) La oferta del servicio complementario de comedor escolar, bonificado en función de los ingresos de la unidad familiar, de su situación laboral y composición. En los supuestos de familias monoparentales se aplicará la corrección oportuna a los ingresos de la unidad familiar.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 1. La Administración de la Junta de Andalucía garantizará el desarrollo de medidas, en el ámbito de la escolarización en educación infantil, educación primaria y educación secundaria obligatoria, en los centros docentes públicos de su titularidad, orientadas a facilitar la conciliación de la vida laboral, familiar y personal en Andalucía y, en particular: d) Aquellas otras que puedan favorecer la corresponsabilidad y la conciliación de la vida laboral, familiar y personal que se determinen reglamentariamente.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 2. La Administración de la Junta de Andalucía garantizará progresivamente la existencia de plazas escolares en el primer ciclo de la educación infantil para atender la demanda de las familias.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 3. La Administración de la Junta de Andalucía promoverá el desarrollo de los servicios previstos en la Ley 39/2006, de 14 de diciembre, de Promoción de la Autonomía Personal y Atención a las personas en situación de dependencia, para facilitar su autonomía, con el fin de garantizar el apoyo necesario para ellas, a la vez que se favorecen las condiciones para la conciliación de la vida personal y laboral de las personas del núcleo de convivencia familiar responsables del cuidado continuado de las personas en situación de dependencia.",
  "Artículo 37 bis. Servicios públicos para la conciliación en el ámbito educativo y social. 4. El Sistema Público de Servicios Sociales de Andalucía garantizará un sistema de atención y cuidados a domicilio, de forma reglada y continuada, a todas aquellas personas con discapacidad o situación de dependencia que lo necesiten, incluyendo las acciones de información, formación y medidas de apoyo a las cuidadoras y cuidadores no profesionales.",
  "Artículo 38. Conciliación en las empresas. 1. La Junta de Andalucía impulsará medidas que favorezcan en la empresa la promoción de la corresponsabilidad y la conciliación de la vida laboral, familiar y personal, a través de la inclusión de medidas de flexibilidad de horarios, entre otras, con pleno respeto a la autonomía en la negociación colectiva en los convenios colectivos y planes de igualdad. También potenciará la elaboración de protocolos de acoso por razón de sexo, así como los planes de igualdad en aquellas empresas que no estén obligadas a aprobarlos.",
  "Artículo 38. Conciliación en las empresas. 2. Asimismo, se incentivará a las empresas para que proporcionen servicios y medidas destinadas a facilitar la conciliación de la vida laboral, familiar y personal, mediante la creación de centros infantiles en el ámbito laboral, infraestructuras y servicios adecuados.",
  "Artículo 38. Conciliación en las empresas. 3. La Junta de Andalucía impulsará la creación de centros infantiles en polígonos industriales y parques tecnológicos que posibiliten, a los hombres y mujeres, la conciliación de la vida laboral, familiar y personal.",
  "Artículo 38. Conciliación en las empresas. 4. Las empresas potenciarán en el ámbito laboral la formación en igualdad de género del personal de recursos humanos responsable de gestionar y difundir internamente las medidas de conciliación de la vida personal, familiar y laboral que se han de implementar, y realizarán acciones de sensibilización en materia de igualdad dirigidas a todo el personal de la empresa.",
  "Artículo 38. Conciliación en las empresas. 5. Asimismo, se promoverán acciones de sensibilización destinadas a fomentar la participación de los hombres en materia de corresponsabilidad y de conciliación de la vida laboral, familiar y personal.",
  "Artículo 39. Conciliación en el empleo público. 1. La Administración de la Junta de Andalucía impulsará la creación de centros infantiles en los centros de trabajo, para facilitar la conciliación de la vida laboral y familiar de las empleadas y empleados públicos, y hacer posible un reparto equilibrado de las tareas y responsabilidades familiares domésticas.",
  "Artículo 39. Conciliación en el empleo público. 2. Asimismo, previa negociación colectiva en el ámbito correspondiente, se establecerán medidas de conciliación, en el marco de los planes de igualdad, que podrán incluir aspectos relacionados con la organización de los tiempos de trabajo, espacios, adaptación de horarios, de jornada y de días de asistencia, reducciones de jornada, disfrute de excedencias y de vacaciones y permisos, fórmulas de teletrabajo y de desconexión digital. En los aspectos mencionados se incluirán medidas concretas para familias monoparentales, teniendo en consideración tanto sus dificultades para la conciliación como la diferente cobertura que los permisos actuales proporcionan a los menores de estas familias respecto de los menores de familias biparentales.",
  "Artículo 39. Conciliación en el empleo público. 3. Con el fin de facilitar la promoción profesional de las empleadas públicas y su acceso a puestos directivos en la Administración de la Junta de Andalucía, sus agencias y demás entidades instrumentales, en las convocatorias de los correspondientes cursos de formación se reservará, al menos, un cuarenta por ciento de las plazas destinadas al alumnado, para su adjudicación a aquellas que hayan disfrutado del permiso por motivos de conciliación de la vida personal, familiar y laboral y por razón de violencia de género, o hayan reingresado desde la situación de excedencia voluntaria por agrupación familiar o de excedencia por cuidado de familiares o por razón de violencia de género, durante los doce meses inmediatamente posteriores a su disfrute o incorporación. Todas aquellas plazas que no queden cubiertas podrán ser ocupadas por el resto del personal según criterios establecidos para ello y con prioridad entre el personal del sexo que en su mayoría no haya recibido dicha formación.",
  "Artículo 40. Permiso de paternidad. 1. La Administración de la Junta de Andalucía garantizará que, una vez agotado el permiso de paternidad por el nacimiento, guarda con fines de adopción o acogimiento, o adopción de un hijo o hija, el personal del sector público andaluz dispondrá de un permiso adicional irrenunciable y retribuido con una duración que, sumada a la del permiso de paternidad, alcance un período de descanso total de veinte semanas, o de las que corresponda en caso de discapacidad del hijo o hija y por cada hijo o hija a partir del segundo en caso de parto, guarda con fines de adopción o acogimiento, o adopción múltiples. Este permiso adicional es intransferible al otro progenitor, y podrá disfrutarse de forma ininterrumpida o bien de modo fraccionado, en este último caso, dentro de los doce meses siguientes al nacimiento, guarda con fines de adopción o acogimiento, o adopción, y será de al menos cinco semanas, individual y no transferible a favor de todo su personal en los casos de nacimiento de hija o hijo, adopción o acogimiento permanente de menores de hasta 6 años.",
  "Artículo 40. Permiso de paternidad. 2. Asimismo, se establecerá un permiso de las mismas características señaladas en el apartado anterior cuando progenitores adoptantes o acogedores tengan el mismo sexo, que disfrutará la persona a la que no le correspondiera el permiso por parto, adopción o acogimiento establecido con carácter general.",
  "Artículo 40. Permiso de paternidad. 3. Las condiciones de acceso y las modalidades de los indicados permisos se establecerán reglamentariamente.",
  "Artículo 41. Políticas de salud. 1. El Sistema Sanitario Público de Andalucía impulsará, en los ámbitos de promoción de salud y prevención de la enfermedad, las medidas necesarias para atender a las diferentes necesidades de hombres y mujeres, fomentando los activos en salud de la población.",
  "Artículo 41. Políticas de salud. 2. Asimismo, impulsará la aplicación de medidas que permitan la atención específica a las necesidades en materia de salud que, por razón de sexo, presenten las mujeres, con especial atención a los colectivos menos favorecidos.",
  "Artículo 41. Políticas de salud. 3. Igualmente, se establecerán las medidas que garanticen, en el ámbito territorial de la Comunidad Autónoma, la integridad física y psíquica de las mujeres y niñas, impidiendo la realización de prácticas médicas o quirúrgicas que atenten contra dicha integridad.",
  "Artículo 41. Políticas de salud. 4. Asimismo, se establecerán medidas que garanticen la accesibilidad a los servicios sanitarios y prestaciones complementarias en condiciones de igualdad entre hombres y mujeres y de forma compatible con la conciliación de la vida familiar y laboral.",
  "Artículo 41. Políticas de salud. 5. Se impulsarán las medidas necesarias para apoyar a las personas cuidadoras de personas dependientes, especialmente en materia de accesibilidad a los servicios y prestaciones complementarias del Sistema Sanitario Público de Andalucía, y se proporcionará formación adecuada para mejorar el cuidado de las personas dependientes a su cargo.",
  "Artículo 41. Políticas de salud. 6. Se impulsarán las medidas necesarias para evitar los embarazos no deseados, con especial atención a las mujeres adolescentes, a través de políticas de promoción y acceso a los servicios de salud sexual y reproductiva.",
  "Artículo 41. Políticas de salud. 7. Se impulsará el enfoque de género tanto en las medidas para la prevención y tratamiento de las enfermedades en general como en aquellas que afectan especialmente a las mujeres.",
  "Artículo 41. Políticas de salud. 8. La Administración sanitaria de la Junta de Andalucía incorporará, en los planes y programas de formación de su personal, la perspectiva de género y la incidencia de los condicionantes de género sobre la salud, con especial atención al desarrollo de las capacidades para detectar y atender situaciones de violencia de género, maltrato hacia las mujeres, agresión y abuso sexual, mutilación genital femenina, así como trata y explotación sexual de mujeres, y desarrollará un plan de salud laboral con una perspectiva de género.",
  "Artículo 41. Políticas de salud. 9. La Administración sanitaria impulsará estudios sobre el impacto en la salud física y psíquica de las mujeres como consecuencia de las desigualdades en el reparto de las responsabilidades domésticas, familiares y de cuidados o del exceso de carga que supone la monoparentalidad.",
  "Artículo 41. Políticas de salud. 10. Asimismo, el Sistema Sanitario Público de Andalucía garantizará el pleno derecho de las mujeres a las técnicas de reproducción humana asistida, independientemente de su estado civil, orientación sexual, procedencia o identidad.",
  "Artículo 41. Políticas de salud. 11. Se impulsará el enfoque integrado de género como instrumento para reducir las desigualdades sociales en salud.",
  "Artículo 41. Políticas de salud. 12. La Administración sanitaria andaluza, con el fin de garantizar de forma integral la salud sexual y reproductiva, deberá: a) Contribuir al empoderamiento de las mujeres en la toma de decisiones respecto a la anticoncepción, interrupción voluntaria del embarazo y las medidas de prevención de infecciones de transmisión sexual.",
  "Artículo 41. Políticas de salud. 12. La Administración sanitaria andaluza, con el fin de garantizar de forma integral la salud sexual y reproductiva, deberá: b) Potenciar la autonomía y el protagonismo de las mujeres en el embarazo, el parto y el puerperio.",
  "Artículo 41. Políticas de salud. 12. La Administración sanitaria andaluza, con el fin de garantizar de forma integral la salud sexual y reproductiva, deberá: c) Tener especialmente en cuenta a las mujeres en situaciones de mayor vulnerabilidad.",
  "Artículo 41. Políticas de salud. 12. La Administración sanitaria andaluza, con el fin de garantizar de forma integral la salud sexual y reproductiva, deberá: d) Asimismo, con la colaboración del Instituto Andaluz de la Mujer, aprobará la carta de los derechos de las mujeres, en relación con el embarazo, el parto y el puerperio, garantizando asimismo el respeto a los diversos modelos de familia.",
  "Artículo 41. Políticas de salud. 13. La Administración sanitaria de la Junta de Andalucía impulsará las medidas necesarias para favorecer la corresponsabilidad de los varones en la prevención de embarazos no deseados e infecciones de transmisión sexual.",
  "Artículo 42. Investigación biomédica.",
  "Artículo 42. Investigación biomédica. 1. La Administración de la Junta de Andalucía impulsará el enfoque de género en las diferentes líneas y proyectos de investigación biomédica, de forma que permita conocer los diferentes modos de enfermar y de respuesta terapéutica de las mujeres y los hombres.",
  "Artículo 42. Investigación biomédica. 2. La Administración sanitaria incorporará a los estudios de investigación y de opinión sobre los servicios sanitarios, así como en las encuestas de salud, indicadores que permitan conocer los datos relativos a mujeres y hombres, tanto de forma desagregada por sexos como en forma global.",
  "Artículo 43. Igualdad en las políticas sociales. 1. Los poderes públicos de Andalucía integrarán la perspectiva de género en el desarrollo y aplicación de las políticas sociales. En este sentido, se establecerán medidas o programas específicos para las mujeres mayores, las mujeres con discapacidad, las mujeres inmigrantes, las mujeres con hijos e hijas a cargo, mujeres al frente de familias monoparentales, mujeres con problemas de adicciones y mujeres en riesgo de exclusión social o con especial vulnerabilidad. También se incorporarán medidas o programas para aquellas mujeres que sean o hayan sido cuidadoras de personas en situación de dependencia.",
  "Artículo 43. Igualdad en las políticas sociales. 2. Igualmente, promoverán las acciones necesarias para eliminar la discriminación por razones de orientación sexual y de identidad de género en los términos establecidos por la Ley 2/2014, de 8 de julio, integral para la no discriminación por motivos de identidad de género y reconocimiento de los derechos de las personas transexuales de Andalucía, y la Ley 8/2017, de 28 de diciembre, para garantizar los derechos, la igualdad de trato y no discriminación de las personas LGTBI y sus familiares en Andalucía.",
  "Artículo 43. Igualdad en las políticas sociales. 3. Los poderes públicos de Andalucía integrarán la perspectiva de género en los planes o programas de lucha contra la pobreza y la exclusión social, incorporando medidas que incidan en las causas de la feminización de la pobreza, con especial incidencia en familias monoparentales y en víctimas de violencia de género.",
  "Artículo 43. Igualdad en las políticas sociales. 4. Igualmente, los poderes públicos de Andalucía adoptarán las acciones necesarias para la atención social a las mujeres prostituidas y víctimas de trata y explotación sexual.",
  "Artículo 43. Igualdad en las políticas sociales. 5. Los poderes públicos de Andalucía garantizarán la integración de las políticas de género en las políticas migratorias.",
  "Artículo 43. Igualdad en las políticas sociales. 6. Las Administraciones públicas adoptarán las medidas necesarias para erradicar las barreras que impidan o dificulten el acceso de las mujeres a los distintos recursos de información y comunicación, con el fin de actuar contra las múltiples formas de discriminación.",
  "Artículo 43. Igualdad en las políticas sociales. 7. Las Administraciones públicas promoverán la incorporación, en los planes y programas de formación especializada y continua del personal que desarrolle actuaciones en el ámbito de las políticas sociales, de materias específicas de igualdad entre mujeres y hombres y la incidencia de los condicionantes de género sobre la exclusión social y otras situaciones de desigualdad y discriminación.",
  "Artículo 44. Mujeres mayores. Los poderes públicos de Andalucía, en el contexto de la programación de acciones destinadas a las personas mayores, establecerán programas específicos dirigidos a las mujeres mayores, incidiendo en los aspectos afectivos, emocionales, atendiendo a las necesidades de las que se encuentran en situación de soledad, así como promoviendo su participación en actividades socioculturales y asociativas.",
  "Artículo 45. Cuidadoras y cuidadores de personas dependientes. 1. En desarrollo de políticas de atención a las personas dependientes en Andalucía, la Administración de la Junta de Andalucía establecerá medidas para la corresponsabilidad y programas de apoyo a cuidadores y cuidadoras, conforme se determine reglamentariamente.",
  "Artículo 45. Cuidadoras y cuidadores de personas dependientes. 2. Asimismo, se proporcionará a las personas cuidadoras un acceso permanente a la información, la formación y el asesoramiento adecuado que les ayude a mejorar su calidad de vida.",
  "Artículo 45. Cuidadoras y cuidadores de personas dependientes. 3. Los poderes públicos de Andalucía diseñarán políticas de atención a las personas dependientes en Andalucía integrando la perspectiva de género en los planes o programas que promuevan y favorezcan la eliminación de las desigualdades de género que se producen en el cuidado de las personas.",
  "Artículo 46. Inclusión social. 1. Los poderes públicos de Andalucía, en el marco de garantías para la inclusión social, desarrollarán acciones dirigidas a quienes se encuentren en situación de especial vulnerabilidad, estableciendo estrategias que contemplen el enfoque de género en las políticas de intervención, especialmente en las relativas al acceso al empleo y a la formación.",
  "Artículo 46. Inclusión social. 2. Se tendrán en cuenta las singulares dificultades en que se encuentran las mujeres de colectivos de especial vulnerabilidad, tales como las que pertenezcan a minorías, mujeres de etnia gitana, mujeres inmigrantes, niñas, mujeres con discapacidad y mujeres prostituídas.",
  "Artículo 46. Inclusión social. 3. Los poderes públicos de Andalucía promoverán medidas para mejorar las condiciones de las mujeres que se encuentren en una situación de precariedad económica, derivada del impago de pensiones compensatorias y alimentarias fijadas en convenio judicialmente aprobado o resolución judicial en casos de nulidad matrimonial, separación legal, divorcio, extinción de pareja de hecho por ruptura o proceso de filiación o de alimentos.",
  "Artículo 47. Tráfico y explotación sexual de las mujeres. Los poderes públicos de Andalucía adoptarán las acciones necesarias para la atención social a las mujeres prostituidas y víctimas de trata y explotación sexual, para lo que elaborarán un Plan integral para la erradicación de la trata y la explotación sexual de mujeres y niñas, que incorporará campañas de información y sensibilización.",
  "Artículo 48. Personas con discapacidad. Los poderes públicos de Andalucía, en el contexto general de garantías de los derechos de las personas con discapacidad, desarrollarán acciones para las mujeres con discapacidad, teniendo como eje transversal de las políticas públicas sus necesidades específicas, con el fin de asegurar su inclusión y acceso en igualdad a todos los ámbitos de la sociedad.",
  "Artículo 48 bis. Mujeres gitanas. 1. Los poderes públicos de Andalucía promoverán el desarrollo de actuaciones específicas dirigidas a las mujeres pertenecientes a la etnia gitana, desde una perspectiva de género y un enfoque interseccional, con el fin de neutralizar los factores de riesgo de exclusión y vulnerabilidad social, y para fomentar su plena participación en el ámbito educativo y laboral, promoción de la salud y protección contra la violencia de género, así como su activa participación en la vida política, económica, social y cultural.",
  "Artículo 48 bis. Mujeres gitanas. 2. Los poderes públicos de Andalucía realizarán actuaciones que eviten el antigitanismo y promoverán, a través de la transversalidad en el diseño y desarrollo de políticas públicas, referentes alejados de una imagen estigmatizada y que propicien la visibilidad de las mujeres y niñas en un contexto igualitario, respetando la propia identidad y sus manifestaciones como un activo en positivo para ellas mismas y para toda la sociedad.",
  "Artículo 49. Mujeres migrantes. Los poderes públicos de Andalucía promoverán la integración, participación y promoción de las mujeres migrantes, realizarán actuaciones para promover la interculturalidad y el valor de la diversidad dentro de un marco de derechos y de igualdad plena de las mujeres, velarán por el acceso al empleo y a los servicios de las mujeres migrantes y concederán protección en situaciones de violencia de género.",
  "Artículo 50. Planeamiento urbanístico, vivienda y transporte. 1. Los poderes públicos de Andalucía integrarán la perspectiva de género en el diseño de las políticas y los planes en materia de vivienda, desarrollando programas y actuaciones específicas para distintos grupos sociales y modelos de familia.",
  "Artículo 50. Planeamiento urbanístico, vivienda y transporte. 2. Asimismo, los poderes públicos de Andalucía, en coordinación y colaboración con las entidades locales en el territorio andaluz, tendrán en cuenta la perspectiva de género en el diseño de las ciudades, en las políticas urbanas, y en la definición y ejecución de los planeamientos urbanísticos.",
  "Artículo 50. Planeamiento urbanístico, vivienda y transporte. 3. Asimismo, facilitarán el acceso y garantizarán el derecho preferente en la adjudicación de viviendas protegidas, en régimen de alquiler o propiedad, de las mujeres víctimas de violencia de género, mujeres que sufran discriminaciones múltiples, mujeres solas con cargas familiares y de aquellas que se encuentren en situación de riesgo de exclusión social, en función de las condiciones especialmente gravosas que pudieran concurrir, en las condiciones que se determinen.",
  "Artículo 50. Planeamiento urbanístico, vivienda y transporte. 4. Las políticas públicas de movilidad y transporte darán prioridad a la reducción de los tiempos de desplazamiento, deberán facilitar la proximidad y los itinerarios relacionados con la organización de la vida familiar y darán respuesta a las necesidades del mundo rural o de zonas de menor densidad de población.",
  "Artículo 50. Planeamiento urbanístico, vivienda y transporte. 5. La Administración de la Junta de Andalucía promoverá la investigación, con perspectiva de género, de la movilidad y el transporte con el fin de que sus políticas públicas en esta materia favorezcan de manera equilibrada a mujeres y hombres. De los resultados de la investigación se dará traslado al Consejo Andaluz de Participación de las Mujeres.",
  "Artículo 50 bis. Deporte y actividad deportiva. 1. Todos los programas públicos de desarrollo y apoyo al deporte y la actividad deportiva incorporarán el principio de igualdad entre mujeres y hombres en su diseño y ejecución.",
  "Artículo 50 bis. Deporte y actividad deportiva. 2. Las Administraciones públicas de Andalucía, en su ámbito de competencias, promoverán y llevarán a cabo las acciones positivas necesarias para conseguir la igualdad de oportunidades de mujeres y hombres en el ámbito del fomento de la actividad física y deportiva.",
  "Artículo 50 bis. Deporte y actividad deportiva. 3. La Administración de la Junta de Andalucía promoverá la actividad física y el deporte femenino y favorecerá la efectiva apertura de las disciplinas deportivas a las mujeres, mediante el desarrollo de programas específicos en todas las etapas de la vida y en todos los niveles, incluidos los de responsabilidad y decisión.",
  "Artículo 50 bis. Deporte y actividad deportiva. 4. Las Administraciones públicas de Andalucía, a través de sus respectivas convocatorias y bases reguladoras, fomentarán actividades o proyectos, en el marco de la actividad física y el deporte, que incorporen el principio de igualdad entre mujeres y hombres en su diseño y ejecución.",
  "Artículo 50 bis. Deporte y actividad deportiva. 5. La Administración de la Junta de Andalucía, así como las federaciones, asociaciones y entidades deportivas de la Comunidad Autónoma de Andalucía, velarán por el respeto al principio de igualdad de oportunidades entre mujeres y hombres en la celebración de pruebas deportivas y convocatorias de premios deportivos y elaborarán protocolos de prevención y actuación ante las actitudes machistas en la práctica deportiva y en el deporte en general.",
  "Artículo 50 bis. Deporte y actividad deportiva. 6. Las Administraciones públicas promoverán, a través de los medios de comunicación, una imagen positiva de las mujeres en el deporte, diversificada y exenta de estereotipos o prejuicios discriminatorios por razón de género, así como potenciarán el pluralismo deportivo y los eventos donde participen mujeres.",
  "Artículo 50 bis. Deporte y actividad deportiva. 7. La Administración de la Junta de Andalucía y las federaciones, asociaciones y entidades deportivas de la Comunidad Autónoma de Andalucía velarán por la participación paritaria en los órganos de gobierno de los organismos y entidades deportivas, por eliminar cualquier cláusula de contratación o patrocinio que de manera directa o indirecta suponga discriminación por razón de género, así como por fomentar el mecenazgo y patrocinio deportivo femenino.",
  "Artículo 50 bis. Deporte y actividad deportiva. 8. Los poderes públicos de Andalucía velarán por la no discriminación y acoso de las mujeres deportistas durante el embarazo o permiso por maternidad.",
  "Artículo 50 bis. Deporte y actividad deportiva. 9. La Administración de la Junta de Andalucía fomentará la presencia de las asociaciones de mujeres en los órganos de participación de la actividad deportiva.",
  "Artículo 50 ter. Cultura. 1. Las Administraciones públicas de Andalucía, en su ámbito de competencias, velarán por hacer efectivo el principio de igualdad de trato y de oportunidades entre mujeres y hombres en lo relativo a la creación y producción artística y cultural y a la difusión de la misma.",
  "Artículo 50 ter. Cultura. 2. Las Administraciones públicas de Andalucía, a través de sus respectivas convocatorias y bases reguladoras, fomentarán actividades o proyectos culturales que incorporen el principio de igualdad entre mujeres y hombres en su diseño y ejecución.",
  "Artículo 50 ter. Cultura. 3. Las Administraciones públicas de Andalucía, agencias y demás entidades instrumentales de la Administración de la Junta de Andalucía desarrollarán las siguientes actuaciones: a) Adoptar iniciativas destinadas a favorecer la promoción específica de las mujeres en la cultura y a combatir su discriminación.",
  "Artículo 50 ter. Cultura. 3. Las Administraciones públicas de Andalucía, agencias y demás entidades instrumentales de la Administración de la Junta de Andalucía desarrollarán las siguientes actuaciones: b) Impulsar políticas activas de ayuda a la creación, producción, emprendimiento y difusión artística y cultural de autoría femenina, con el objetivo de crear las condiciones para que se produzca una efectiva igualdad de oportunidades entre mujeres y hombres.",
  "Artículo 50 ter. Cultura. 3. Las Administraciones públicas de Andalucía, agencias y demás entidades instrumentales de la Administración de la Junta de Andalucía desarrollarán las siguientes actuaciones: c) Promover la presencia equilibrada de mujeres y hombres en la oferta artística y cultural pública.",
  "Artículo 50 ter. Cultura. 3. Las Administraciones públicas de Andalucía, agencias y demás entidades instrumentales de la Administración de la Junta de Andalucía desarrollarán las siguientes actuaciones: d) Garantizar la representación equilibrada entre mujeres y hombres en los distintos órganos consultivos, científicos y de decisión existentes en el organigrama artístico y cultural, así como en los jurados de premios promovidos o subvencionados por la Junta de Andalucía.",
  "Artículo 50 ter. Cultura. 3. Las Administraciones públicas de Andalucía, agencias y demás entidades instrumentales de la Administración de la Junta de Andalucía desarrollarán las siguientes actuaciones: e) Adoptar medidas de acción positiva para favorecer la creación y producción artística de las mujeres, propiciando el intercambio cultural, intelectual y artístico a nivel regional, nacional e internacional y la suscripción de convenios con los organismos competentes.",
  "Artículo 50 ter. Cultura. 3. Las Administraciones públicas de Andalucía, agencias y demás entidades instrumentales de la Administración de la Junta de Andalucía desarrollarán las siguientes actuaciones: f) Velar por que las manifestaciones artísticas y culturales, ya sean sostenidas tanto por fondos públicos como privados, no fomenten, enaltezcan o reproduzcan estereotipos y valores sexistas.",
  "Artículo 50 quáter. Políticas de cooperación para el desarrollo. 1. Todas las políticas, planes y documentos de planificación estratégica, tanto sectorial como territorial, incluirán el principio de igualdad entre mujeres y hombres.",
  "Artículo 50 quáter. Políticas de cooperación para el desarrollo. 2. La igualdad entre mujeres y hombres será una prioridad transversal y específica en los contenidos del Plan Andaluz de Cooperación para el Desarrollo, contemplando medidas concretas para su seguimiento y evaluación.",
  "Artículo 50 quáter. Políticas de cooperación para el desarrollo. 3. La programación operativa de la cooperación andaluza para el desarrollo incorporará como prioridad proyectos que garanticen la atención a mujeres y niñas y la incorporación de las mujeres como agentes activas del desarrollo.",
  "Artículo 51.  De la información y el conocimiento. 1. Los poderes públicos de Andalucía promoverán las acciones que favorezcan la implantación de las tecnologías de la información con base en criterios de igualdad de género y promoverán la participación de las mujeres en la construcción de la sociedad de la información y el conocimiento.",
  "Artículo 51.  De la información y el conocimiento. 2. La Administración de la Junta de Andalucía incorporará la perspectiva de género de manera transversal en la planificación y coordinación de las políticas de desarrollo tecnológico e innovación.",
  "Artículo 51.  De la información y el conocimiento. 3. Asimismo, y a través de los organismos competentes en estas materias, se garantizará la transversalidad de género en la investigación, formación y educación en el sector audiovisual de Andalucía.",
  "Artículo 51.  De la información y el conocimiento. 4. La Administración de la Junta de Andalucía, en los proyectos desarrollados en el ámbito de las tecnologías de la información y la comunicación financiados total o parcialmente por esta, garantizará que se integre la perspectiva de género y, especialmente, que sus valores, su lenguaje, imagen y contenidos no sean sexistas.",
  "Artículo 52. Mujeres del medio rural y pesquero. 1. Los poderes públicos de Andalucía integrarán la perspectiva de género en las actuaciones de desarrollo rural y pesquero, garantizando que estas intervenciones contemplen las necesidades de las mujeres, permitan su plena participación con equidad y contribuyan a una igualdad real de oportunidades entre mujeres y hombres.",
  "Artículo 52. Mujeres del medio rural y pesquero. 2. Los poderes públicos de Andalucía desarrollarán acciones dirigidas a eliminar la discriminación de las mujeres del medio rural y pesquero y favorecer su incorporación al ámbito laboral, a la formación y a las nuevas tecnologías, así como su plena participación en la vida social y económica.",
  "Artículo 52. Mujeres del medio rural y pesquero. 3. Los poderes públicos de Andalucía impulsarán el ejercicio de la titularidad compartida en explotaciones agrarias, adoptando las medidas necesarias para facilitar el acceso y mantenimiento de la titularidad o cotitularidad de las explotaciones, a través del Registro de Titularidad Compartida de las Explotaciones Agrarias de Andalucía, y realizarán campañas de información y asesoramiento con el fin de potenciar la incorporación de las mujeres a la actividad económica y empresarial de las explotaciones.",
  "Artículo 52. Mujeres del medio rural y pesquero. 4. Los poderes públicos de Andalucía crearán las medidas que faciliten el acceso de las mujeres a los sectores agrario, agroalimentario y pesquero, como opción de empleo o emprendimiento, y que mejoren sus condiciones laborales. Para ello impulsarán también su participación en cursos de formación y cualificación profesional, facilitándoles, además, el uso de las tecnologías de la información y comunicación.",
  "Artículo 52. Mujeres del medio rural y pesquero. 5. Los poderes públicos de Andalucía generarán las condiciones para que las mujeres se empoderen individual y colectivamente y garantizar así su presencia y participación en los órganos de toma de decisiones y en los espacios de interlocución en los sectores agrario, agroalimentario y pesquero.",
  "Artículo 52. Mujeres del medio rural y pesquero. 6. La Administración de la Junta de Andalucía, a través del Plan de igualdad de oportunidades entre mujeres y hombres en la actividad agroalimentaria y pesquera de Andalucía, impulsará el papel de las mujeres en la economía y en la sociedad rural y pesquera andaluza.",
  "Artículo 52 bis. Mujeres jóvenes. 1. Las Administraciones públicas de Andalucía incorporarán la perspectiva de género en las políticas de juventud, promoviendo programas destinados a conocer y analizar la realidad de las mujeres jóvenes de forma integral.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: a) Promover la integración laboral de las mujeres jóvenes, desde la educación y diversificación de opciones profesionales en igualdad, detectando y eliminando situaciones de discriminación en el acceso al empleo y adoptando las medidas necesarias para la promoción en el empleo y contra la precariedad laboral. Asimismo, se impulsará el emprendimiento y autoempleo de las mismas.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: b) Promover una educación sexual y afectiva adecuada, con principios de igualdad de género.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: c) Incorporar medidas de prevención y detección contra la violencia de género en la juventud.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: d) Difundir y promover las manifestaciones artísticas, culturales, científicas, técnicas y deportivas de las mujeres jóvenes.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: e) Promover una imagen no discriminatoria, plural e igualitaria de las mujeres jóvenes en los medios de comunicación, en la publicidad y en la industria del ocio, en particular en los videojuegos.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: f) Colaborar con los agentes económicos y sociales más representativos en la detección y eliminación de situaciones de discriminación en el acceso al empleo y la promoción en el empleo y en la lucha contra la precariedad laboral y por un empleo de calidad.",
  "Artículo 52 bis. Mujeres jóvenes. 2. La Administración de la Junta de Andalucía desarrollará las siguientes actuaciones: g) Promover la participación en el movimiento social y asociativo, especialmente en el movimiento asociativo de mujeres.",
  "Artículo 53. Participación política. Las candidaturas para las elecciones al Parlamento de Andalucía garantizarán la presencia equilibrada de mujeres y hombres.",
  "Artículo 54. Participación social. 1. Las Administraciones públicas de Andalucía impulsarán medidas para el fomento de la participación social de las mujeres.",
  "Artículo 54. Participación social. 2. Asimismo, cooperarán con la iniciativa social y las asociaciones para la promoción de la igualdad de género.",
  "Artículo 54. Participación social. 3. La Administración de la Junta de Andalucía, a través del Instituto Andaluz de la Mujer, promoverá la participación de las entidades y asociaciones de carácter privado en la implantación de la igualdad entre mujeres y hombres, favoreciendo la participación de las mujeres en estas organizaciones.",
  "Artículo 54. Participación social. 4. La Administración de la Junta de Andalucía podrá celebrar convenios de colaboración, para la consecución de la igualdad entre mujeres y hombres y la erradicación de la discriminación por razón de sexo, con los colegios profesionales y agentes económicos y sociales de Andalucía, siempre que cumplan las obligaciones en materia de igualdad establecidas en la presente ley.",
  "Artículo 55. Fomento de las asociaciones de mujeres. 1. Los poderes públicos de Andalucía impulsarán el movimiento asociativo de mujeres y establecerán acciones adecuadas para facilitar su participación en la sociedad.",
  "Artículo 55. Fomento de las asociaciones de mujeres. 2. Asimismo, potenciarán todas aquellas iniciativas que persigan la creación de redes de asociaciones de mujeres con el objetivo de incorporar a las mujeres en la actividad pública y facilitar su participación social.",
  "Artículo 55. Fomento de las asociaciones de mujeres. 3. Igualmente, fomentarán la creación de órganos locales de participación en relación con la igualdad de oportunidades entre mujeres y hombres, y su coordinación con otros órganos de ámbito territorial similares.",
  "Artículo 56. Participación en ámbitos sociales, políticos y económicos. 1. Las Administraciones públicas de Andalucía promoverán la igualdad en la participación de las mujeres y hombres en las actividades sociales, lúdicas y de ocio que se desarrollen en la Comunidad Autónoma.",
  "Artículo 56. Participación en ámbitos sociales, políticos y económicos. 2. Las Administraciones públicas fomentarán la igualdad de oportunidades en la participación de las mujeres y hombres en los ámbitos políticos y económicos.",
  "Artículo 56. Participación en ámbitos sociales, políticos y económicos. 3. Las Administraciones públicas competentes promoverán la presencia equilibrada de mujeres y hombres en los órganos de dirección y decisión en el ámbito social, político y económico. A tal fin, el otorgamiento de subvenciones podrá estar condicionado a la adopción de medidas que posibiliten un incremento de la presencia de mujeres en los órganos de dirección.",
  "Artículo 57. Imagen de la mujer y del hombre. 1. Los poderes públicos de Andalucía promoverán la transmisión de una imagen igualitaria, plural y no estereotipada de los hombres y de las mujeres en todos los medios de información y comunicación, conforme a los principios y valores de nuestro ordenamiento jurídico y las normas específicas que les sean de aplicación.",
  "Artículo 57. Imagen de la mujer y del hombre. 2. A tales efectos se considerará ilícita, de conformidad con lo previsto en la legislación vigente en esta materia, la publicidad que atente contra la dignidad de la persona o vulnere los valores y derechos reconocidos en la Constitución, especialmente los que refieren sus artículos 18 y 20, apartado 4. Se entenderán incluidos en la previsión anterior los anuncios que presenten a las mujeres de forma vejatoria, bien utilizando particular y directamente su cuerpo o partes del mismo como mero objeto desvinculado del producto que se pretende promocionar, su imagen asociada a comportamientos estereotipados que vulneren los fundamentos de nuestro ordenamiento, coadyuvando a genenar la violencia a que se refiere la Ley Orgánica 1/2004, de 28 de diciembre, de medidas de protección integral contra la violencia de género.",
  "Artículo 57. Imagen de la mujer y del hombre. 3. El Consejo Audiovisual de Andalucía, en el cumplimiento de las funciones que tiene asignadas, contribuirá a fomentar la igualdad de género y los comportamientos no sexistas en los contenidos de las programaciones ofrecidas por los medios de comunicación en Andalucía, así como en la publicidad que emitan.",
  "Artículo 57. Imagen de la mujer y del hombre. 4. Anualmente el Instituto Andaluz de la Mujer elaborará un informe sobre el tratamiento de la imagen de las mujeres en los medios de comunicación y la publicidad, que será remitido para su conocimiento al Consejo Andaluz de Participación de las Mujeres.",
  "Artículo 58. Medios de comunicación social. 1. Los medios de comunicación social públicos incorporarán la perspectiva de género de forma transversal, promoverán la aplicación de un uso no sexista del lenguaje, impulsarán la transmisión de una imagen de las mujeres y los hombres libre de estereotipos sexistas y velarán por que los contenidos de las programaciones cumplan con el principio de igualdad de género.Igualmente, los medios de comunicación social evitarán difundir cualquier contenido, emisión o publicidad sexista que justifique, banalice o incite a la violencia de género. A este respecto, usarán un lenguaje adecuado que visibilice la violencia sufrida por las víctimas de violencia de género, de una manera crítica hacia la conducta del agresor, y presentarán a las hijas e hijos menores de las mismas como víctimas directas de dicha violencia, preservando su protección y tratamiento de la información.",
  "Artículo 58. Medios de comunicación social. 2. Los medios de comunicación social adoptarán, mediante autorregulación, códigos de conducta con el fin de asumir y transmitir el principio de igualdad de género. Asimismo, colaborarán con las campañas institucionales dirigidas a fomentar la igualdad entre mujeres y hombres y la erradicación de la violencia de género, darán visibilidad a las mujeres en la programación y contenidos y promoverán una representación equilibrada de mujeres y hombres en los debates públicos con personas expertas.",
  "Artículo 58. Medios de comunicación social. 3. La Administración de la Junta de Andalucía, a través del órgano con competencias en la materia, garantizará la aplicación del principio de igualdad y transversalidad de género en todas las instancias, instituciones, entidades de cualquier naturaleza jurídica y acciones que se desarrollen en el sector audiovisual de Andalucía, aplicando de forma rigurosa y sistemática la normativa vigente sobre el fomento de la igualdad entre mujeres y hombres, y especialmente: a) Impulsará el desarrollo de un código de buenas prácticas que ayude a delimitar los conceptos de sexismo y estereotipos sexistas, y de lucha contra la violencia de género, para su aplicación en los ámbitos de la comunicación y la publicidad.",
  "Artículo 58. Medios de comunicación social. 3. La Administración de la Junta de Andalucía, a través del órgano con competencias en la materia, garantizará la aplicación del principio de igualdad y transversalidad de género en todas las instancias, instituciones, entidades de cualquier naturaleza jurídica y acciones que se desarrollen en el sector audiovisual de Andalucía, aplicando de forma rigurosa y sistemática la normativa vigente sobre el fomento de la igualdad entre mujeres y hombres, y especialmente: b) Establecerá indicadores que midan la igualdad de género en los medios de comunicación social y en la publicidad.",
  "Artículo 58. Medios de comunicación social. 3. La Administración de la Junta de Andalucía, a través del órgano con competencias en la materia, garantizará la aplicación del principio de igualdad y transversalidad de género en todas las instancias, instituciones, entidades de cualquier naturaleza jurídica y acciones que se desarrollen en el sector audiovisual de Andalucía, aplicando de forma rigurosa y sistemática la normativa vigente sobre el fomento de la igualdad entre mujeres y hombres, y especialmente: c) Promoverá la elaboración de programas, la emisión de mensajes y la alfabetización mediática coeducativa, que contribuyan a la educación en valores de igualdad de género y no violencia, especialmente dirigidos a adolescentes y jóvenes.",
  "Artículo 58. Medios de comunicación social. 3. La Administración de la Junta de Andalucía, a través del órgano con competencias en la materia, garantizará la aplicación del principio de igualdad y transversalidad de género en todas las instancias, instituciones, entidades de cualquier naturaleza jurídica y acciones que se desarrollen en el sector audiovisual de Andalucía, aplicando de forma rigurosa y sistemática la normativa vigente sobre el fomento de la igualdad entre mujeres y hombres, y especialmente: d) Impulsará la formación sobre igualdad y violencia de género, así como sobre integración de la perspectiva de género, en las facultades y profesiones relacionadas con los medios de comunicación.",
  "Artículo 58. Medios de comunicación social. 3. La Administración de la Junta de Andalucía, a través del órgano con competencias en la materia, garantizará la aplicación del principio de igualdad y transversalidad de género en todas las instancias, instituciones, entidades de cualquier naturaleza jurídica y acciones que se desarrollen en el sector audiovisual de Andalucía, aplicando de forma rigurosa y sistemática la normativa vigente sobre el fomento de la igualdad entre mujeres y hombres, y especialmente: e) Fomentará el establecimiento de acuerdos que coadyuven al cumplimiento de los objetivos en materia de igualdad de género y erradicación de la violencia de género establecidos en la ley.",
  "Artículo 58. Medios de comunicación social. 3. La Administración de la Junta de Andalucía, a través del órgano con competencias en la materia, garantizará la aplicación del principio de igualdad y transversalidad de género en todas las instancias, instituciones, entidades de cualquier naturaleza jurídica y acciones que se desarrollen en el sector audiovisual de Andalucía, aplicando de forma rigurosa y sistemática la normativa vigente sobre el fomento de la igualdad entre mujeres y hombres, y especialmente: f) Promoverá espacios en los medios de comunicación públicos de la Comunidad Autónoma, para que realicen la labor de divulgación, información y concienciación, para la consecución de la igualdad real entre mujeres y hombres.",
  "Artículo 58. Medios de comunicación social. 4. La Administración de la Junta de Andalucía promoverá una presencia equilibrada de mujeres y hombres en órganos de dirección y decisión de los medios de comunicación social.",
  "Artículo 59. Comisión Interdepartamental para la Igualdad de Mujeres y Hombres. 1. Se creará la Comisión Interdepartamental para la Igualdad de Mujeres y Hombres, en la que estarán representadas todas las Consejerías de la Junta de Andalucía, como órgano colegiado para el seguimiento de las acciones y actuaciones de la Administración de la Junta de Andalucía en materia de igualdad de género, que será presidida por la persona titular de la Consejería competente en materia de igualdad.",
  "Artículo 59. Comisión Interdepartamental para la Igualdad de Mujeres y Hombres. 2. Su composición, competencias y régimen de funcionamiento se determinarán reglamentariamente.",
  "Artículo 60. Unidades de igualdad de género. 1. Se crean Unidades de Igualdad de Género en todas las Consejerías de la Junta de Andalucía, con el fin de impulsar, coordinar e implementar la perspectiva de género en la planificación, gestión y evaluación en sus respectivas políticas.",
  "Artículo 60. Unidades de igualdad de género. 2. Cada Consejería encomendará a uno de sus órganos directivos las funciones de la Unidad de Igualdad de Género en los términos que se establezcan reglamentariamente.",
  "Artículo 60. Unidades de igualdad de género. 3. La Administración de la Junta de Andalucía garantizará el asesoramiento y la formación de su personal en materia de igualdad de género para implementar eficazmente las políticas públicas con perspectiva de género.",
  "Artículo 61. Observatorio Andaluz de la Igualdad de Género. 1. Se creará el Observatorio Andaluz de la Igualdad de Género como órgano asesor, adscrito a la Consejería competente en materia de igualdad, destinado a detectar, analizar y proponer estrategias para corregir situaciones de desigualdad de las mujeres en la Comunidad Autónoma de Andalucía, con participación administrativa y social y funciones asesoras y de evaluación de las políticas y medidas incluidas en la presente ley, procediendo a su análisis y difusión.",
  "Artículo 61. Observatorio Andaluz de la Igualdad de Género. 2. El Observatorio Andaluz de la Igualdad de Género, en colaboración con la Unidad Estadística y Cartográfica de la Consejería competente en materia de igualdad, definirá los indicadores necesarios para el análisis de la igualdad de género, así como las fuentes de información de referencia y la metodología de cálculo de los mismos. En la definición de estos indicadores se tendrá en cuenta la información estadística y cartográfica generada por el Sistema Estadístico y Cartográfico de Andalucía.",
  "Artículo 61. Observatorio Andaluz de la Igualdad de Género. 3. Sus funciones, composición y funcionamiento, así como las distintas áreas de intervención, serán determinadas reglamentariamente.",
  "Artículo 62. Consejo Andaluz de Participación de las Mujeres. 1. Se crea el Consejo Andaluz de Participación de las mujeres, adscrito a la Consejería competente en materia de igualdad, como órgano de participación de las organizaciones de mujeres en las políticas de igualdad de género de la Junta de Andalucía.",
  "Artículo 62. Consejo Andaluz de Participación de las Mujeres. 2. Sus funciones y composición se regularán mediante decreto del Consejo de Gobierno.",
  "Artículo 62 bis. Centros municipales de información a la mujer. 1. Los centros municipales de información a la mujer son las unidades de atención integral e información a las mujeres y en especial a las mujeres víctimas de violencia de género. Asimismo, llevarán a cabo actuaciones de sensibilización en políticas de igualdad y fomento de la participación de las mujeres.",
  "Artículo 62 bis. Centros municipales de información a la mujer. 2. En cofinanciación con las corporaciones locales, la Administración de la Junta de Andalucía promoverá la creación y mantenimiento de los centros municipales de información a la mujer. La cofinanciación será del cincuenta por ciento.",
  "Artículo 62 bis. Centros municipales de información a la mujer. 3. Se establecerán reglamentariamente sus funciones, composición y funcionamiento.",
  "Artículo 63. Coordinación de los poderes públicos de Andalucía para la igualdad de mujeres y hombres. Se creará la Comisión de coordinación de las políticas autonómicas y locales para la igualdad de género con el objeto de coordinar e impulsar la integración del enfoque de género en las políticas y programas, en materia de igualdad de mujeres y hombres, desarrollados por las distintas Administraciones públicas de la Comunidad Autónoma, y que dependerá de la Consejería competente en materia de igualdad; y estará compuesta por representantes de la Administración de la Junta de Andalucía, de las Diputaciones Provinciales y de los Ayuntamientos. Se establecerán reglamentariamente sus funciones, composición y funcionamiento.",
  "Artículo 64. Evaluación de la aplicación de la ley. En los términos en que reglamentariamente se determine, se elaborará un informe periódico sobre el conjunto de actuaciones en relación con la efectividad del principio de igualdad entre mujeres y hombres que estará coordinado por la Consejería que ostente las competencias en materia de igualdad y que establecerá los criterios correctores que correspondan con la finalidad objeto de esta ley.",
  "Artículo 65. Igualdad de trato en el acceso al uso de bienes y servicios y su suministro. 1. Los poderes públicos de Andalucía adoptarán las medidas necesarias para garantizar el respeto al principio de igualdad de trato entre mujeres y hombres, mediante la ausencia de discriminación directa e indirecta por razón de sexo, en lo relativo al acceso a bienes y servicios y su suministro.",
  "Artículo 65. Igualdad de trato en el acceso al uso de bienes y servicios y su suministro. 2. De acuerdo con el artículo 69 de la Ley Orgánica 3/2007, de 22 de marzo, para la igualdad efectiva de mujeres y hombres, la prestación de bienes y servicios de forma exclusiva o principal a las personas de uno de los sexos sólo será admisible cuando la diferencia de trato esté justificada por un propósito legítimo y los medios para lograr tal finalidad sean adecuados y necesarios.",
  "Artículo 66. Acción de cesación y rectificación de la publicidad ilícita. 1. De conformidad con lo dispuesto en el artículo 12 de la Ley Orgánica 1/2004, de 28 de diciembre, de Medidas de Protección Integral contra la Violencia de Género, el Instituto Andaluz de la Mujer y las asociaciones que tengan como objetivo único la defensa de los intereses de las mujeres estarán legitimados para ejercitar la acción de cesación de publicidad ilícita por utilizar en forma vejatoria la imagen de la mujer, en los términos establecidos en la legislación vigente.",
  "Artículo 66. Acción de cesación y rectificación de la publicidad ilícita. 2. El Consejo Audiovisual de Andalucía, de acuerdo con lo dispuesto en el artículo 4.15 de la Ley 1/2004, de 17 de diciembre, de su creación, podrá solicitar de los anunciantes y empresas audiovisuales, por iniciativa propia o a instancia de las partes interesadas, el cese o la rectificación de la publicidad ilícita o prohibida, y, cuando proceda, disponerlo, de conformidad con la legislación aplicable y en los supuestos que la misma establezca.",
  "Artículo 67. Dictámenes. El Instituto Andaluz de la Mujer es el órgano de la Administración de la Junta de Andalucía competente para emitir los dictámenes previstos en el apartado 3 del artículo 95 de la Ley 36/2011, de 10 de octubre, reguladora de la jurisdicción social, en su ámbito de competencias.",
  "Artículo 68. Competencia.Corresponde al Instituto Andaluz de la Mujer la defensa y asistencia a las mujeres ante situaciones de discriminación por razón de sexo, y la promoción del cumplimiento del principio de igualdad de trato de mujeres y hombres en la Comunidad Autónoma de Andalucía, a cuyos efectos, y sin perjuicio de las funciones que tiene encomendadas legalmente, ejercerá las funciones previstas en el artículo 69.",
  "Artículo 69. Funciones. El Instituto Andaluz de la Mujer, sin perjuicio de las funciones que tiene encomendadas legalmente, ejercerá las siguientes funciones: a) Practicar investigaciones, tanto de oficio como a instancia de parte, para el esclarecimiento de posibles situaciones de discriminación directa o indirecta por razón de sexo, a los efectos de incoar el correspondiente procedimiento sancionador, si se estimara que se han producido acciones u omisiones tipificadas como infracción en la ley.",
  "Artículo 69. Funciones. El Instituto Andaluz de la Mujer, sin perjuicio de las funciones que tiene encomendadas legalmente, ejercerá las siguientes funciones: b) Facilitar vías de negociación y dirigir recomendaciones a personas físicas y jurídicas con el fin de corregir situaciones o prácticas discriminatorias por razón de sexo y/o situación familiar y hacer un seguimiento del cumplimiento de las mencionadas recomendaciones.",
  "Artículo 69. Funciones. El Instituto Andaluz de la Mujer, sin perjuicio de las funciones que tiene encomendadas legalmente, ejercerá las siguientes funciones: c) Prestar asesoramiento a las mujeres ante posibles situaciones de discriminación por razón de sexo y/o situación familiar.",
  "Artículo 69. Funciones. El Instituto Andaluz de la Mujer, sin perjuicio de las funciones que tiene encomendadas legalmente, ejercerá las siguientes funciones: d) Difundir las actividades que realiza y sus investigaciones, así como elaborar informes y dictámenes de conformidad con lo dispuesto en esta ley.",
  "Artículo 69. Funciones. El Instituto Andaluz de la Mujer, sin perjuicio de las funciones que tiene encomendadas legalmente, ejercerá las siguientes funciones: e) Colaborar con la autoridad laboral y en especial con la Inspección de Trabajo en orden al seguimiento del cumplimiento de la normativa laboral antidiscriminatoria en materia de igualdad de mujeres y hombres.",
  "Artículo 70. Deber de colaboración. Todas las personas físicas y jurídicas sometidas a investigación tienen el deber de facilitar la labor del Instituto Andaluz de la Mujer aportando, en el plazo que se determine reglamentariamente, los datos, documentos, informes o aclaraciones que, siendo necesarios para el esclarecimiento de los hechos, les sean solicitados, y facilitando, previo aviso, el acceso a sus dependencias, salvo que estas coincidan con su domicilio particular, en cuyo caso deberá obtenerse su expreso consentimiento.",
  "Artículo 71. Procedimiento de investigación. Reglamentariamente se establecerá el procedimiento de investigación relativo a las competencias y funciones del Instituto Andaluz de la Mujer, establecidas en los artículos 68 y 69 de la presente ley.",
  "Artículo 72. Información y asesoramiento sobre discriminación por razón de sexo. La Consejería competente en materia de igualdad garantizará, a través del Instituto Andaluz de la Mujer, servicios de información y asesoramiento a las mujeres ante situaciones de discriminación por razón de sexo y/o situación familiar.",
  "Artículo 73. Infracciones. 1. Constituyen infracciones administrativas en las materias reguladas en esta ley las acciones u omisiones tipificadas y sancionadas en los artículos siguientes.",
  "Artículo 73. Infracciones. 2. Cuando a juicio de la Administración competente las infracciones pudieran ser constitutivas de delito o falta, el órgano administrativo dará traslado al Ministerio Fiscal y se abstendrá de proseguir el procedimiento sancionador mientras la autoridad judicial, en su caso, no se haya pronunciado. La sanción penal excluirá la imposición de sanción administrativa. Si no se hubiera estimado la existencia de delito o falta, la Administración podrá continuar el procedimiento sancionador con base, en su caso, en los hechos que el órgano judicial competente haya considerado probados.",
  "Artículo 73. Infracciones. 3. En los mismos términos, la instrucción de causa penal ante los tribunales de justicia suspenderá la tramitación del procedimiento administrativo sancionador que se hubiera incoado por los mismos hechos y, en su caso, la ejecución de los actos administrativos de imposición de sanción, si se estima que existe identidad de sujeto, hecho y fundamento entre la infracción administrativa y la infracción penal que pudiera corresponder. Las medidas administrativas que hubieran sido adoptadas para salvaguardar la salud y seguridad de las personas se mantendrán hasta tanto la autoridad judicial se pronuncie sobre las mismas en el procedimiento correspondiente.",
  "Artículo 74. Clasificación de las infracciones. Las infracciones se clasifican en leves, graves y muy graves.",
  "Artículo 75. Infracciones leves. Son infracciones leves: a) La insuficiente o parcial colaboración con el Instituto Andaluz de la Mujer durante la acción investigadora e inspectora de este.",
  "Artículo 75. Infracciones leves. Son infracciones leves: b) La insuficiente o parcial colaboración con la actuación inspectora de la Administración de la Junta de Andalucía.",
  "Artículo 75. Infracciones leves. Son infracciones leves: c) Las acciones u omisiones tipificadas como graves o muy graves, cuando se hayan cometido por imprudencia o simple negligencia.",
  "Artículo 76. Infracciones graves. Son infracciones graves: a) La negativa a colaborar con la acción investigadora del Instituto Andaluz de la Mujer o las acciones u omisiones dirigidas a impedir su ejercicio.",
  "Artículo 76. Infracciones graves. Son infracciones graves: b) La negativa a colaborar con la actuación inspectora de la Administración de la Junta de Andalucía o las acciones u omisiones dirigidas a impedir su ejercicio.",
  "Artículo 76. Infracciones graves. Son infracciones graves: c) La realización de actos o comportamientos que dificulten o impidan el ejercicio de los derechos reconocidos en esta ley y que constituyan o causen discriminación por razón de sexo.",
  "Artículo 76. Infracciones graves. Son infracciones graves: d) La elaboración, utilización o difusión, en centros docentes de la Comunidad Autónoma de Andalucía, de libros de texto y materiales curriculares que presenten a las personas como superiores o inferiores en dignidad humana en función de su sexo, o que utilicen la imagen de las mujeres asociada a comportamientos que justifiquen o inciten a la prostitución o a la violencia.",
  "Artículo 76. Infracciones graves. Son infracciones graves: e) La realización de campañas de publicidad o anuncios que utilicen el cuerpo de las mujeres o partes del mismo como reclamo publicitario, desvinculado del producto anunciado, o que utilicen la imagen de las mujeres asociada a comportamientos que justifiquen o inciten a la prostitución o a la violencia contra ellas.",
  "Artículo 76. Infracciones graves. Son infracciones graves: f) Realizar actos o comportamientos que atenten contra la dignidad de los participantes o que constituyan discriminación, en la celebración de pruebas deportivas y convocatorias de premios deportivos, por razón de sexo.",
  "Artículo 76. Infracciones graves. Son infracciones graves: g) Organizar o desarrollar actos culturales, artísticos o lúdicos que, por su carácter sexista o discriminatorio por razón de sexo, vulneren los derechos previstos en esta ley o justifiquen o inciten a la prostitución o a la violencia de género.",
  "Artículo 76. Infracciones graves. Son infracciones graves: h) La imposición de cláusulas en los negocios jurídicos que constituyan o causen discriminación por razón de sexo.",
  "Artículo 76. Infracciones graves. Son infracciones graves: i) La reincidencia en falta leve de la misma naturaleza que la nueva infracción por la que se hubiese sancionado por resolución firme en vía administrativa en el plazo de los dos años anteriores a la comisión de la misma.",
  "Artículo 77. Infracciones muy graves. Son infracciones muy graves: a) El comportamiento contrario a los derechos previstos en esta ley, de naturaleza sexual o no, realizado en función del sexo de una persona, que produzca el efecto de atentar contra su dignidad, creando un entorno intimidatorio, degradante u ofensivo para la misma.",
  "Artículo 77. Infracciones muy graves. Son infracciones muy graves: b) La represalia o trato vejatorio que reciba una persona como consecuencia de haber presentado una queja, reclamación o denuncia destinada a impedir su discriminación y a exigir el cumplimiento efectivo del deber de igualdad de trato entre mujeres y hombres.",
  "Artículo 77. Infracciones muy graves. Son infracciones muy graves: c) El empleo de un lenguaje discriminatorio o la transmisión de mensajes o imágenes estereotipadas de subordinación de las mujeres a los hombres o de desigualdad entre ambos sexos en los medios de comunicación social de Andalucía.",
  "Artículo 77. Infracciones muy graves. Son infracciones muy graves: d) La reincidencia en falta grave de la misma naturaleza que la nueva infracción por la que se hubiese sancionado por resolución firme en vía administrativa en el plazo de los dos años anteriores a la comisión de la misma.",
  "Artículo 78. Responsabilidad. 1. Sin perjuicio de las responsabilidades civiles, penales o de otro orden y de las atribuciones inspectoras y sancionadoras que en el ámbito laboral pueda ejercer la Inspección de Trabajo y Seguridad Social, serán responsables de las infracciones tipificadas en esta ley, atendiendo a cada caso, las personas físicas o jurídicas que realicen las acciones y omisiones tipificadas como infracción.",
  "Artículo 78. Responsabilidad. 2. Serán consideradas responsables las personas físicas o jurídicas que cooperen en su ejecución, mediante una acción u omisión sin la cual la infracción no se hubiese producido.",
  "Artículo 78. Responsabilidad. 3. Cuando el cumplimiento de la obligación prevista en esta ley corresponda a varias personas físicas o jurídicas conjuntamente, estas responderán de manera solidaria de las infracciones que cometan y de las sanciones que se impongan.",
  "Artículo 79. Prescripción de las infracciones. Sin perjuicio de lo previsto en el artículo 30 de la Ley 40/2015, de 1 de octubre, de Régimen Jurídico del Sector Público, las infracciones muy graves prescribirán a los cuatro años, las graves a los tres años y las leves al año.",
  "Artículo 80. Sanciones. 1. Las infracciones leves serán sancionadas con apercibimiento o con multa de hasta 6.000 euros.",
  "Artículo 80. Sanciones. 2. Las infracciones graves serán sancionadas con multa de 6.001 hasta 60.000 euros. Además, podrán imponerse como sanciones accesorias alguna o algunas de las siguientes: a) Prohibición de acceder a cualquier tipo de ayuda pública concedida por la Administración de la Junta de Andalucía y sus agencias por un periodo de hasta tres años o la pérdida de forma automática de cualquier tipo de ayuda pública concedida por la Administración de la Junta de Andalucía y sus agencias.",
  "Artículo 80. Sanciones. 2. Las infracciones graves serán sancionadas con multa de 6.001 hasta 60.000 euros. Además, podrán imponerse como sanciones accesorias alguna o algunas de las siguientes: b) Inhabilitación temporal, por un periodo de hasta tres años, para ser titular, la persona física o jurídica, de centros o servicios dedicados a la prestación de servicios públicos.",
  "Artículo 80. Sanciones. 2. Las infracciones graves serán sancionadas con multa de 6.001 hasta 60.000 euros. Además, podrán imponerse como sanciones accesorias alguna o algunas de las siguientes: c) Cierre o suspensión temporal del servicio, actividad o instalación por un periodo de hasta tres años.",
  "Artículo 80. Sanciones. 3. Las infracciones muy graves serán sancionadas con multa de 60.001 hasta 120.000 euros, y además podrá imponerse alguna o algunas de las sanciones accesorias siguientes: a) Prohibición de acceder a cualquier tipo de ayuda pública concedida por la Administración de la Junta de Andalucía y sus agencias por un periodo de tres a cinco años o la pérdida de forma automática de cualquier tipo de ayuda pública concedida por la Administración de la Junta de Andalucía y sus agencias.",
  "Artículo 80. Sanciones. 3. Las infracciones muy graves serán sancionadas con multa de 60.001 hasta 120.000 euros, y además podrá imponerse alguna o algunas de las sanciones accesorias siguientes: b) Inhabilitación temporal, por un periodo de tres a cinco años, para ser titular, la persona física o jurídica, de centros o servicios dedicados a la prestación de servicios públicos.",
  "Artículo 80. Sanciones. 3. Las infracciones muy graves serán sancionadas con multa de 60.001 hasta 120.000 euros, y además podrá imponerse alguna o algunas de las sanciones accesorias siguientes: c) Cierre o suspensión temporal del servicio, actividad o instalación por un periodo de hasta cinco años.",
  "Artículo 80. Sanciones. 4. Los responsables de las infracciones graves y muy graves perderán, automáticamente, con el correspondiente reintegro, las ayudas, bonificaciones y subvenciones concedidas por la Administración de la Junta de Andalucía y sus agencias, con efectos desde la fecha en que se cometió la infracción.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:a) La naturaleza y gravedad de los perjuicios causados.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:b) La intencionalidad.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:c) La trascendencia social de los hechos o su relevancia.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:d) El beneficio que haya obtenido la persona o entidad infractora.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:e) El incumplimiento de las advertencias o requerimientos que previamente haya realizado la Administración de la Comunidad Autónoma de Andalucía.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:f) La reparación voluntaria de los daños causados o la subsanación de los efectos consecuencia de la infracción, siempre que ello tenga lugar antes de que recaiga resolución definitiva en el procedimiento sancionador.",
  "Artículo 81. Graduación de las sanciones. 1. La graduación de las sanciones será proporcional a la infracción cometida y se establecerá ponderándose según los siguientes criterios:g) La reincidencia en los hechos sancionados.",
  "Artículo 81. Graduación de las sanciones. 2. Para la imposición de las sanciones pecuniarias y para la determinación de su cuantía deberá tenerse en cuenta que la comisión de las infracciones no resulte más beneficiosa para la persona o entidad infractora que el cumplimiento de las normas infringidas.",
  "Artículo 82. Prescripción de las sanciones. Sin perjuicio de lo previsto en el artículo 30 de la Ley 40/2015, de 1 de octubre, las sanciones impuestas por infracciones muy graves prescribirán a los tres años, las graves a los dos años y las leves al año.",
  "Artículo 83. Publicidad y comunicación de las sanciones. 1. Los órganos competentes para resolver los procedimientos sancionadores por la comisión de infracciones graves y muy graves acordarán la publicación en el Boletín Oficial de la Junta de Andalucía de las sanciones impuestas una vez hayan adquirido firmeza. En el supuesto de infracciones muy graves, esta publicación podrá hacerse extensiva a los medios de comunicación social. El coste de la publicación correrá a cargo de la persona o entidad sancionada.",
  "Artículo 83. Publicidad y comunicación de las sanciones. 2. En dicha publicación se hará referencia a los nombres, apellidos, denominación o razón social de las personas responsables, así como al tipo de infracción.",
  "Artículo 83. Publicidad y comunicación de las sanciones. 3. La prohibición de acceder a cualquier tipo de ayuda pública que conceda la Administración de la Junta de Andalucía y sus agencias, como sanción accesoria prevista en la letra a) de los apartados 2 y 3 del artículo 80, también será comunicada tanto a la base de datos de subvenciones de la Junta de Andalucía como a la base de datos nacional de subvenciones, en los términos y condiciones establecidos en sus respectivas normas reguladoras.",
  "Artículo 84. Reducción de la sanción. 1. Las multas se reducirán en un treinta por ciento de la cuantía cuando la persona presunta infractora abone la multa en cualquier momento anterior a la resolución.",
  "Artículo 84. Reducción de la sanción. 2. La reducción prevista en el apartado 1 no se aplicará cuando la sanción se imponga por una infracción muy grave y cuando la persona infractora haya cometido una o varias infracciones de la misma naturaleza en los cinco años anteriores, con imposición de sanción que sea firme.",
  "Artículo 85. Órganos competentes. 1. La imposición de las sanciones previstas en este título exigirá la previa incoación del correspondiente procedimiento administrativo sancionador. El órgano competente para acordar la iniciación del procedimiento será la persona titular del centro directivo de la Consejería competente por razón de la materia en cuyo ámbito se produzcan los hechos o conductas tipificadas como infracciones en esta ley.",
  "Artículo 85. Órganos competentes. 2. En el acuerdo de inicio del procedimiento sancionador se establecerá el órgano que deba instruir el procedimiento.",
  "Artículo 85. Órganos competentes. 3. Los órganos competentes para la imposición de las sanciones por la comisión de infracciones previstas en esta ley serán: a) La persona titular del Instituto Andaluz de la Mujer, para la imposición de sanciones por infracciones leves.",
  "Artículo 85. Órganos competentes. 3. Los órganos competentes para la imposición de las sanciones por la comisión de infracciones previstas en esta ley serán: b) La persona titular de la Consejería competente en materia de igualdad, para la imposición de sanciones por infracciones graves.",
  "Artículo 85. Órganos competentes. 3. Los órganos competentes para la imposición de las sanciones por la comisión de infracciones previstas en esta ley serán: c) El Consejo de Gobierno de la Junta de Andalucía, para la imposición de sanciones por infracciones muy graves.",
  "Artículo 85. Órganos competentes. 4. Sin perjuicio de las atribuciones a las que se refieren los apartados 1, 2 y 3, la autoridad competente para incoar y resolver los correspondientes procedimientos sancionadores por la comisión de las infracciones contempladas en los artículos 76.e) y 77.c) de la presente ley será el Consejo Audiovisual de Andalucía, de acuerdo con las atribuciones conferidas al mismo por el artículo 4.16 de la Ley 1/2004, de 17 de diciembre, de creación del Consejo Audiovisual de Andalucía.",
  "Artículo 85. Órganos competentes. 5. En el supuesto contemplado en el artículo 76.d) la instrucción del procedimiento, propuesta e imposición de sanciones será la Consejería competente en materia de educación, con la debida separación entre la fase instructora y la sancionadora.",
  "Artículo 86. Procedimiento sancionador. Los órganos competentes para iniciar, instruir y resolver los procedimientos sancionadores deben aplicar la normativa de procedimiento sancionador a los ámbitos de competencia de la Administración de la Junta de Andalucía, de acuerdo con los principios de legalidad, competencia, irretroactividad, tipicidad, responsabilidad, proporcionalidad, prescripción, prohibición de doble sanción, presunción de inocencia y prohibición de analogía.",
  ];